export default function filterValueSorting(hierarchyName: string, values: string[]): string[] {
  /**
   * Sort the filter values based on two criteria:
   * 1. If it's normal hierarchies, sort them by their alphabetical order
   * 2. If it's "bands":
   * ex: >$100m and <=$150m
   * Extract the first number from the string and sort them based on that
   * If the first number is the same, use the second number (if available)
   */

  if (hierarchyName.split(' ').pop()?.includes('Band')) {
    // Sort by the first and second number in the string
    const sortedTestCases = [...values].sort((a, b) => {
      const [numA1, numA2] = extractTwoNumbers(a);
      const [numB1, numB2] = extractTwoNumbers(b);

      // Check if either condition contains "<=" at the start
      const hasLEA = a.trim().startsWith('<=');
      const hasLEB = b.trim().startsWith('<=');

      // Prioritize "<=" conditions first
      if (hasLEA && !hasLEB) return -1;
      if (!hasLEA && hasLEB) return 1;

      // Sort based on the first extracted number
      if (numA1 !== numB1) {
        return numA1 - numB1;
      }

      // If the first number is the same, sort by the second number (if available)
      return (numA2 || 0) - (numB2 || 0);
    });

    return sortedTestCases;
  } else {
    // Sort by alphabetical order
    return values.sort();
  }
}

function extractTwoNumbers(input: string): [number, number | null] {
  /**
   * Extracts up to two numbers from the input string
   */
  const matches = input.match(/(\d+(\.\d+)?)\s*([kKmMbB]?)/g);

  if (!matches) return [Number.MAX_SAFE_INTEGER, null]; // Return default if no match is found

  const numbers = matches.map((match) => {
    let value = parseFloat(match);
    const unit = match.match(/[kKmMbB]/)?.[0]?.toLowerCase() || '';

    if (unit === 'k') value *= 1000;
    if (unit === 'm') value *= 1000000;
    if (unit === 'b') value *= 1000000000;

    return value;
  });

  return [numbers[0] || 0, numbers[1] || null]; // Return first and second number (if available)
}

import './assets/main.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import ElementPlus from 'element-plus';
import PrimeVue from 'primevue/config';
import '@/assets/css/tailwind-sybil/theme.css';
import '@/assets/css/tailwind.css';
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import '@/assets/css/css_dist/index.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// import * as am4core from "@amcharts/amcharts4/core";
import * as am5 from '@amcharts/amcharts5';
import ToastService from 'primevue/toastservice';
import App from '@/App.vue';
import router from './router';
// import CGrid from "vue-cheetah-grid";
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';
// Required for grid setup, even if not directly used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as cheetahGrid from 'cheetah-grid';
import * as Sentry from '@sentry/vue';
import CGrid from 'vue-cheetah-grid';

const app = createApp(App);

// const { EVENT_TYPE } = cheetahGrid.ListGrid
// if(import.meta.env.VITE_AMCHARTS_4_LICENSE_KEY) {
//     am4core.addLicense(import.meta.env.VITE_AMCHARTS_4_LICENSE_KEY);
// }

if (import.meta.env.VITE_ENV && import.meta.env.VITE_ENV != 'dev') {
  Sentry.init({
    app,
    dsn: 'https://7f885e384be32fd31b71be09144337d9@o4508355011018752.ingest.de.sentry.io/4508355016917072',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    environment: import.meta.env.VITE_ENV,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^http:\/\/127.0.0.1:5000\/api/,
      /^https:\/\/api2.sybil.cloud\/api/,
      /^https:\/\/staging.ap2.sybil.cloud\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (import.meta.env.VITE_AMCHARTS_5_LICENSE_KEY) {
  am5.addLicense(import.meta.env.VITE_AMCHARTS_5_LICENSE_KEY);
}

app.use(CGrid);
app.use(createPinia());
app.use(router);
app.use(PrimeVue);
app.use(ElementPlus);
app.use(ToastService);

app.mount('#app');

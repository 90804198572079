<script setup lang="ts">
import { ref, onBeforeMount, onBeforeUnmount, onMounted, computed, watch } from 'vue';
import { EditPen } from '@element-plus/icons-vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import InputText from 'primevue/inputtext';
import ColumnGroup from 'primevue/columngroup';
import { usePortfolioStore } from '@/stores/portfolio';
import { useUnderwritingStore } from '@/stores/underwriting';
import { useGlobalStore } from '@/stores/global';
import moment from 'moment';
import { Unlock, Lock, CaretBottom, Filter } from '@element-plus/icons-vue';
import Skeleton from 'primevue/skeleton';
import type { DropdownInstance } from 'element-plus';
import { ElTree } from 'element-plus';
import { api } from '@/services/api';
import { Download, Plus } from '@element-plus/icons-vue';
import axios from 'axios';
import VueSimpleContextMenu from 'vue-simple-context-menu';

const treeRef = ref<InstanceType<typeof ElTree>>();

const portfolio_store = usePortfolioStore();
const underwriting_store = useUnderwritingStore();
const global_store = useGlobalStore();

const col_show = ref(999);

const row_col = ref(0);
const currentMonth = computed(() => moment(portfolio_store.selectedBounceDate, 'YYYYMM').format('MMM-YYYY'));
const cuurentMonthIndex = ref(portfolio_store.all_uw_dates['month'].indexOf(currentMonth.value));
underwriting_store.setHierarchyOrder();

const tableData = computed(() => underwriting_store.tableData);
const tableDataColumns = computed(() => underwriting_store.tableDataColumns);
const totalTableData = computed(() => underwriting_store.totalTableData);
const valueArrayColumns = computed(() => underwriting_store.valueArrayColumns);
const filterTree = ref(
  underwriting_store.hierarchyOrder.slice(0, underwriting_store.currentLevel).map((value) => [
    {
      id: 'main',
      label: '',
      children: Object.keys(portfolio_store.dictionary[portfolio_store.parameters.hierarchies[value]]).map((x) => ({
        id: x,
        label: portfolio_store.dictionary[portfolio_store.parameters.hierarchies[value]][x],
      })),
    },
  ])
);

const allFilterTreeKeys = ref(
  underwriting_store.hierarchyOrder
    .slice(0, underwriting_store.currentLevel)
    .map((value) => Object.keys(portfolio_store.dictionary[portfolio_store.parameters.hierarchies[value]]))
);
const checkedFilterTreeKeys = ref(underwriting_store.checkedFilterTreeKeys || structuredClone(allFilterTreeKeys.value));

async function resetFilterTree(index: number) {
  checkedFilterTreeKeys.value[index] = structuredClone(allFilterTreeKeys.value[index]);
  hdropdowns.value[index].handleClose();
  await underwriting_store.setCheckedFilterTreeKeys(checkedFilterTreeKeys.value);
  underwriting_store.setIsFiltered(
    valueFiltersLength.value.reduce((s, a) => s + a, 0) +
      allFilterTreeKeys.value.map((x) => x.length).reduce((x, a) => x + a, 0) -
      checkedFilterTreeKeys.value.map((x) => x.length).reduce((x, a) => x + a, 0)
  );
}

async function applyFilterTree(index: number) {
  checkedFilterTreeKeys.value[index] = treeRef.value[index]!.getCheckedKeys(true);
  hdropdowns.value[index].handleClose();
  await underwriting_store.setCheckedFilterTreeKeys(checkedFilterTreeKeys.value);
  underwriting_store.setIsFiltered(
    valueFiltersLength.value.reduce((s, a) => s + a, 0) +
      allFilterTreeKeys.value.map((x) => x.length).reduce((x, a) => x + a, 0) -
      checkedFilterTreeKeys.value.map((x) => x.length).reduce((x, a) => x + a, 0)
  );
}

const selectedMonthOperation = ref('<=');

const amountType = computed(() => [
  ...[underwriting_store.accident_underwriting == 0 ? 'GEP' : 'GWP'],
  ...['Commission'],
  ...portfolio_store.parameters.line_size.slice(1).map((e: any) => {
    return e;
  }),
  ...['CCR A-priori', 'NLR A-priori', 'GLR A-priori', 'Attritional A-priori'],
  ...portfolio_store.parameters.claims_nature.slice(1).map((e: any) => {
    return toTitleCase(e) + ' Load';
  }),
  ...['Rate Change', 'Indexation', 'Elasticity', 'Manual Perturbation', 'Algorithmic Perturbation'],
]);
const currentAmountType = computed(() => underwriting_store.currentAmountType);
const currentFilterAmountType = ref(underwriting_store.currentAmountType);
const filterNumber = ref('');
const dropDownRender = ref(portfolio_store.all_uw_dates['month'].map((x) => false));
const valueFilters = ref(underwriting_store.valueFilters || portfolio_store.all_uw_dates['month'].map((x) => []));
const valueFiltersLength = ref(valueFilters.value.map((x) => x.reduce((s, a) => s + a, 0)));
const valueVisibleFilters = ref([]);
const isFiltered = computed(() => underwriting_store.isFiltered);
const defaultProps = {
  children: 'children',
  label: 'label',
};

const isExportingTable = ref(false);

async function openDownloadDialog() {
  if (isExportingTable.value == false) {
    isExportingTable.value = true;

    let hierarchies_order: any = [];
    for (const i of underwriting_store.hierarchyOrder) {
      hierarchies_order.push(portfolio_store.parameters['hierarchies'][i]);
    }

    await api
      .post(import.meta.env.VITE_API_URL + 'underwriting/export-table', {
        bounce_id: portfolio_store.selectedBounceID,
        accident_underwriting: underwriting_store.accident_underwriting,
        selected_hierarchies: hierarchies_order.slice(0, underwriting_store.currentLevel),
        currency:
          'Amounts in ' + global_store.currency.currencyFormat.currency + GWPUnits.value[selectedGWPUnit.value][1],
        precision: global_store.currency.currencyFormat.precision,
        unit: GWPUnits.value[selectedGWPUnit.value][0],
        checkedFilterTreeKeys: checkedFilterTreeKeys.value,
        all_months: portfolio_store.all_uw_dates['month'],
        valueFilters: valueFilters.value || portfolio_store.all_uw_dates['month'].map((x) => []),
      })
      .then(async (res) => {
        return axios({
          url: res.data.data.signed_blob,
          method: 'GET',
          responseType: 'blob',
        });
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'filename.xlsx'); // Set a filename for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        isExportingTable.value = false;
      })
      .catch(() => {
        console.log('error');
        isExportingTable.value = false;
      });
  }
}

function calculateAmount(row: any) {
  if (currentAmountType.value == 'CCR A-priori') {
    let ccr = row[valueArrayColumns.value['Attritional A-priori']];
    for (let i of portfolio_store.parameters.claims_nature.slice(1)) {
      ccr += row[valueArrayColumns.value[toTitleCase(i) + ' Load']];
    }
    ccr += row[valueArrayColumns.value['Commission']];
    return formatNumber(ccr, false);
  } else if (currentAmountType.value == 'NLR A-priori') {
    let nlr = row[valueArrayColumns.value['Attritional A-priori']];
    for (let i of portfolio_store.parameters.claims_nature.slice(1)) {
      nlr += row[valueArrayColumns.value[toTitleCase(i) + ' Load']];
    }
    nlr = nlr / (1 - row[valueArrayColumns.value['Commission']]);
    return formatNumber(nlr, false);
  } else if (currentAmountType.value == 'GLR A-priori') {
    let glr = row[valueArrayColumns.value['Attritional A-priori']];
    for (let i of portfolio_store.parameters.claims_nature.slice(1)) {
      glr += row[valueArrayColumns.value[toTitleCase(i) + ' Load']];
    }
    return formatNumber(glr, false);
  }

  return formatNumber(row[valueArrayColumns.value[currentAmountType.value]], false);
}

function selectAmount(amount: string) {
  underwriting_store.setCurrentAmountType(amount);
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
const skeletonData = Array.from({ length: 50 }, (_, index) => ({
  index: index + 1,
  value: '',
}));

const columnLocks: any = ref(
  Object.values(underwriting_store.hierarchyOrder.slice(0, underwriting_store.currentLevel)).reduce((acc, key) => {
    acc[key] = '';
    return acc;
  }, {})
);

const columnLocksFooter = ref('');
function toggleLock(key: number) {
  if (columnLocks.value[key] == '') {
    columnLocks.value[key] = 'sticky';
  } else {
    columnLocks.value[key] = '';
  }

  let currOffset = 0;
  for (const i of underwriting_store.hierarchyOrder.slice(0, underwriting_store.currentLevel)) {
    if (columnLocks.value[i] != '') {
      columnLocksOffset.value[i] = currOffset + 56 + 'px';
      currOffset += 160;
    } else {
      columnLocksOffset.value[i] = null;
    }
  }

  columnLocksFooter.value = Object.values(columnLocks.value).join('').length > 0 ? 'sticky' : '';
}

const columnLocksOffset: any = ref(
  Object.values(underwriting_store.hierarchyOrder.slice(0, underwriting_store.currentLevel)).reduce((acc, key) => {
    acc[key] = null;
    return acc;
  }, {})
);
const selectedGWPUnit = computed<string>(() => global_store.currency.currencyFormat.selectedGWPUnit);
const GWPUnits: any = computed(() => global_store.currency.GWPUnits);

const windowHeight = ref(0);

function changePage(e: any) {
  underwriting_store.updatePageUnderwritingTable(e.page + 1);
}
const dropdowns = ref<DropdownInstance>();
const hdropdowns = ref<DropdownInstance>();

function onUnderwritingControllerModal(date: any, row: any, dateIdx: any) {
  let filters: any = null;
  if (row) {
    filters = Object.values(row)
      .slice(0, underwriting_store.currentLevel)
      .map((x, i) => ({
        [portfolio_store.parameters.hierarchies[
          underwriting_store.hierarchyOrder.slice(0, underwriting_store.currentLevel)[i]
        ]]: [x],
      }));
  } else {
    filters = portfolio_store.parameters.hierarchies
      .slice(0, underwriting_store.currentLevel)
      .map((x) => ({ [x]: Object.keys(portfolio_store.dictionary[x]) }));
  }

  const a = {
    filters: filters,
    underwriting_month: moment(date, 'MMM-YYYY').format('YYYY-MM-01'),
    index: dateIdx,
  };
  underwriting_store.setUnderwritingControllerDate(a);
  underwriting_store.updateIsUnderwritingControllerModal(true);
}

function onUnderwritingMultipleChangeModal(date: any, row: any, dateIdx: any) {
  // let filters: any = null;
  // if (row) {
  //   filters = Object.values(row)
  //     .slice(0, underwriting_store.currentLevel)
  //     .map((x, i) => ({
  //       [portfolio_store.parameters.hierarchies[
  //         underwriting_store.hierarchyOrder.slice(0, underwriting_store.currentLevel)[i]
  //       ]]: [x],
  //     }));
  // } else {
  //   filters = portfolio_store.parameters.hierarchies
  //     .slice(0, underwriting_store.currentLevel)
  //     .map((x) => ({ [x]: Object.keys(portfolio_store.dictionary[x]) }));
  // }

  // const a = {
  //   filters: filters,
  //   underwriting_month: moment(date, 'MMM-YYYY').format('YYYY-MM-01'),
  //   index: dateIdx,
  // };
  // underwriting_store.setUnderwritingControllerDate(a);
  underwriting_store.updateIsUnderwritingMultipleChangeModal(true);
}

onBeforeMount(() => {
  windowHeight.value = window.innerHeight - 40;
});

function formatNumber(x: number) {
  if (['GWP', 'GEP'].includes(currentAmountType.value)) {
    return numberWithCommas(x, false);
  } else if (currentAmountType.value == 'Elasticity') {
    return x.toFixed(1);
  }
  return (x * 100).toFixed(1) + '%';
}

function numberWithCommas(x: number, isValue: boolean) {
  if (!isValue) {
    return (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0]))
      .toFixed(global_store.currency.currencyFormat.precision)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return (
      parseFloat(
        (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0])).toFixed(
          global_store.currency.currencyFormat.precision
        )
      ) || ''
    );
  }
}

const searchText = ref('');

function addAmountToFilter() {
  valueVisibleFilters.value.push([currentFilterAmountType.value, selectedMonthOperation.value, filterNumber.value]);
  filterNumber.value = '';
}

function initializeFilter(index: number) {
  dropDownRender.value[index] = true;
  filterNumber.value = '';
  currentFilterAmountType.value = underwriting_store.accident_underwriting == 0 ? 'GEP' : 'GWP';
  valueVisibleFilters.value = structuredClone(valueFilters.value[index]);
}

async function applyValueFilter(index: number) {
  valueFilters.value[index] = structuredClone(valueVisibleFilters.value);
  valueFiltersLength.value[index] = valueFilters.value[index].reduce((x, a) => x + a, 0);
  dropdowns.value[index].handleClose();
  await underwriting_store.setValueFilters(valueFilters.value);
  underwriting_store.setIsFiltered(
    valueFiltersLength.value.reduce((s, a) => s + a, 0) +
      allFilterTreeKeys.value.map((x) => x.length).reduce((x, a) => x + a, 0) -
      checkedFilterTreeKeys.value.map((x) => x.length).reduce((x, a) => x + a, 0)
  );
}

async function resetValueFilter(index: number) {
  valueFilters.value[index] = [];
  valueFiltersLength.value[index] = 0;
  dropdowns.value[index].handleClose();
  await underwriting_store.setValueFilters(valueFilters.value);
  underwriting_store.setIsFiltered(
    valueFiltersLength.value.reduce((s, a) => s + a, 0) +
      allFilterTreeKeys.value.map((x) => x.length).reduce((x, a) => x + a, 0) -
      checkedFilterTreeKeys.value.map((x) => x.length).reduce((x, a) => x + a, 0)
  );
}

async function resetAllFilters() {
  for (let index in valueFilters.value) {
    valueFilters.value[index] = [];
    valueFiltersLength.value[index] = 0;
  }
  for (let index in checkedFilterTreeKeys.value) {
    checkedFilterTreeKeys.value[index] = structuredClone(allFilterTreeKeys.value[index]);
  }
  await underwriting_store.setValueFilters(valueFilters.value);
  await underwriting_store.setCheckedFilterTreeKeys(checkedFilterTreeKeys.value);
  underwriting_store.setIsFiltered(
    valueFiltersLength.value.reduce((s, a) => s + a, 0) +
      allFilterTreeKeys.value.map((x) => x.length).reduce((x, a) => x + a, 0) -
      checkedFilterTreeKeys.value.map((x) => x.length).reduce((x, a) => x + a, 0)
  );
}
function amountFormat(value: any) {
  return ['GWP', 'GEP'].includes(value[0])
    ? value[0] + ' ' + value[1] + ' ' + value[2].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : value[0] +
        ' ' +
        value[1] +
        ' ' +
        parseFloat(value[2]).toFixed(1) +
        (currentFilterAmountType.value == 'Elasticity' ? '' : '%');
}

onBeforeUnmount(() => {
  // let item = document.getElementsByClassName('p-datatable-wrapper');
  // item[0].removeEventListener('scroll', addRestOfTable);
});

onMounted(async () => {
  col_show.value = 999; //Math.ceil(window.innerWidth/100)*2
  // let item = document.getElementsByClassName('p-datatable-wrapper');
  // item[0].addEventListener('scroll', addRestOfTable);
  // filterInitialState();
  // valueFiltersLength.value[index] = valueFilters.value[index].reduce((x, a) => x + a, 0);
  await underwriting_store.fetchUnderwritingTable();
});

function filterInitialState() {
  allFilterTreeKeys.value = underwriting_store.hierarchyOrder
    .slice(0, underwriting_store.currentLevel)
    .map((value) => Object.keys(portfolio_store.dictionary[portfolio_store.parameters.hierarchies[value]]));
  checkedFilterTreeKeys.value = structuredClone(allFilterTreeKeys.value);
  columnLocks.value = Object.values(underwriting_store.hierarchyOrder.slice(0, underwriting_store.currentLevel)).reduce(
    (acc, key) => {
      acc[key] = '';
      return acc;
    },
    {}
  );

  filterTree.value = underwriting_store.hierarchyOrder.slice(0, underwriting_store.currentLevel).map((value) => [
    {
      id: 'main',
      label: '',
      children: Object.keys(portfolio_store.dictionary[portfolio_store.parameters.hierarchies[value]]).map((x) => ({
        id: x,
        label: portfolio_store.dictionary[portfolio_store.parameters.hierarchies[value]][x],
      })),
    },
  ]);
  underwriting_store.setCheckedFilterTreeKeys(checkedFilterTreeKeys.value);
}

watch(
  () => underwriting_store.hierarchyOrder,
  async () => {
    filterInitialState();
  }
);

watch(
  () => portfolio_store.selectedLineSize,
  async () => {
    await underwriting_store.fetchUnderwritingTable();
  }
);

function removeAmountFromFilter(index: number) {
  valueVisibleFilters.value.splice(index, 1);
}

const contextMenuTable = ref();

function handleRightClickContextMenu(event, type, node) {
  row_col.value = type;
  contextMenuTable.value.showMenu(event, node);
}

function onEditDraftOptionsTable(e) {
  let desc = '';
  if (e.option.id == 'row') {
    desc = 'Row number : ' + e.item[tableDataColumns.value['Index']];
  } else {
    desc = 'Month : ' + e.item[0];
  }

  underwriting_store.updateUnderwritingMultipleChangeDesc(desc);
  underwriting_store.updateIsUnderwritingMultipleChangeModal(true);
}

const contextMenuOptions = [
  [
    {
      name: 'Apply multiple changes to row',
      id: 'row',
    },
  ],
  [
    {
      name: 'Apply multiple changes to column',
      id: 'column',
    },
  ],
];
</script>

<template>
  <vue-simple-context-menu
    ref="contextMenuTable"
    element-id="multiple-changes"
    :options="contextMenuOptions[row_col]"
    @option-clicked="onEditDraftOptionsTable"
  />
  <div>
    <div class="flex justify-between flex-row flex-wrap px-5 py-3 bg-white -mb-3">
      <div class="mr-3 mt-1 relative">
        <el-dropdown class="hover:bg-gray-100 rounded-lg px-5 -ml-3 -mt-2 py-1" trigger="click">
          <span class="text-lg">
            {{ currentAmountType }} by
            <span :class="'text-sybil-teal hover:text-green-700 '"
              ><b>{{ underwriting_store.accident_underwriting == 1 ? 'Underwriting' : 'Accident' }}</b></span
            >
            Month
          </span>
          <template #dropdown>
            <div class="overflow-scroll h-96 p-5 custom-scroll">
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="amount in amountType"
                  :key="amount"
                  :label="amount"
                  :value="amount"
                  @click="selectAmount(amount)"
                  >{{ amount }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </div>
          </template>
        </el-dropdown>
      </div>
      <div v-if="isExportingTable" class="flex flex-row">
        Exporting table
        <div class="flex flex-row relative w-40 ml-5 h-8">
          <el-progress :percentage="100" status="warning" :indeterminate="true" :duration="1" class="w-full" />
        </div>
        <div></div>
      </div>
    </div>
  </div>
  <DataTable
    v-if="tableData && tableDataColumns"
    :key="underwriting_store.underwritingTableRenderKey"
    :value="tableData"
    paginator
    :rows="underwriting_store.underwriting_metadata['per_page']"
    paginator-template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
    current-page-report-template="Showing {first} to {last} of {totalRecords}"
    :total-records="underwriting_store.underwriting_metadata['total_items']"
    :scrollable="true"
    :scroll-height="windowHeight - 180 + 'px'"
    @page="changePage"
    show-gridlines
    underline
    scroll-direction="both"
    class="mt-1 bg-white p-datatable-sm"
    :lazy="true"
    row-group-mode="subheader"
    style="text-align: center; font-size: 12px"
    ref="tableRef"
  >
    <template #paginatorstart>
      <el-button
        v-if="['GWP', 'GEP'].includes(currentAmountType)"
        class="left-0 text-gray-500 cursor-pointer -mt-1"
        type="info"
        link
        @click="global_store.setCurrencyModal(true)"
        ><i>
          Amounts in {{ global_store.currency.currencyFormat.currency + GWPUnits[selectedGWPUnit][1] }}</i
        ></el-button
      >
    </template>
    <template #paginatorend>
      <el-tooltip content="Clear table filters" placement="bottom" :show-after="1000">
        <el-button
          key="primary"
          :disabled="isFiltered == 0"
          :type="isFiltered == 0 ? 'info' : 'warning'"
          class="text-center w-5"
          data-testid="clear-table-filters"
          @click="resetAllFilters()"
        >
          <i class="pi pi-filter-slash cursor-pointer"></i>
        </el-button>
      </el-tooltip>
      <el-tooltip content="Add new business" placement="bottom" :show-after="1000">
        <el-button
          key="primary"
          type="primary"
          class="text-center w-5"
          @click="underwriting_store.isNewBusinessModal = true"
        >
          <el-icon><Plus /></el-icon>
        </el-button>
      </el-tooltip>
      <el-tooltip content="Export table to CSV" placement="bottom" :show-after="1000">
        <el-button key="primary" type="primary" class="text-center w-5" @click="openDownloadDialog()">
          <el-icon>
            <Download />
          </el-icon>
        </el-button>
      </el-tooltip>
      <el-tooltip content="Signed Off Underwriting" placement="bottom" :show-after="1000">
        <el-button
          key="primary"
          :type="portfolio_store.signedOff['underwriting_module'] ? 'primary' : 'danger'"
          class="text-center w-5"
          @click="portfolio_store.signedOffUnderwriting"
        >
          <el-icon><EditPen /></el-icon>
        </el-button>
      </el-tooltip>
    </template>
    <Column
      field=""
      style="width: 32px; text-align: center; background-color: #eef8f4"
      header-style="background-color:#bbe2d3 ;text-align:center"
      frozen
    >
      <template #header>
        <div style="width: 32px" class="w-full text-center font-bold whitespace-nowrap">
          <el-tooltip content="Change hierarchy" placement="bottom" :show-after="1000">
            <img
              src="@/views/Underwriting/assets/Hierarchy.svg"
              data-testid="change-hierarchy"
              width="20"
              class="png-teal cursor-pointer ml-1"
              @click="underwriting_store.updateChangeHierarchyOrderModal(true)"
            />
          </el-tooltip>
        </div>
      </template>
      <template #body="slotProps">
        <div
          style="width: 32px"
          class="cursor-context-menu"
          data-testid="index"
          @contextmenu.prevent.stop="handleRightClickContextMenu($event, 0, slotProps.data)"
        >
          {{ slotProps.data[tableDataColumns['Index']] }}
        </div>
      </template>
    </Column>
    <Column
      v-for="(value, index) in underwriting_store.hierarchyOrder.slice(0, underwriting_store.currentLevel)"
      :key="portfolio_store.parameters.hierarchies[value]"
      :style="{ left: columnLocksOffset[value] }"
      style="width: 160px; background-color: #eef8f4"
      :header-style="'background-color:#bbe2d3;z-index:100;'"
      class="left-14"
      :class="columnLocks[value]"
    >
      <template #header>
        <div class="font-bold text-sm text-center w-full relative whitespace-nowrap" style="height: 20px; width: 160px">
          <p data-testid="hierarchy-order">
            {{ portfolio_store.parameters.hierarchies[value]
            }}<span
              class="cursor-pointer hover:text-sybil-teal text-lg absolute top-0 -left-2"
              @click="toggleLock(value)"
              ><el-icon v-if="columnLocks[value] == ''"><Unlock /></el-icon><el-icon v-else><Lock /></el-icon
            ></span>

            <el-dropdown ref="hdropdowns" trigger="click">
              <span data-testid="dropdown-filter" class="cursor-pointer hover:text-sybil-teal mt-1 ml-1">
                <el-icon v-if="allFilterTreeKeys[index].length > checkedFilterTreeKeys[index].length"
                  ><Filter
                /></el-icon>
                <el-icon v-if="allFilterTreeKeys[index].length == checkedFilterTreeKeys[index].length"
                  ><CaretBottom
                /></el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <div class="px-5 mt-1 text-sybil-charcoal w-full text-center text-lg">
                    {{ portfolio_store.parameters.hierarchies[value] }}
                  </div>
                  <div class="dropdown-container px-5 py-2 w-74" style="height: 388px">
                    <div
                      class="custom-scroll h-full border-2 mt-4 border-gray-100 rounded-sm overflow-scroll bg-white"
                      data-testid="hierarchy-filter"
                      style="height: 265px"
                    >
                      <el-tree
                        ref="treeRef"
                        :data="filterTree[index]"
                        show-checkbox
                        node-key="id"
                        default-expand-all
                        :props="defaultProps"
                        class="pr-3"
                        style="min-height: 261px"
                        :default-checked-keys="checkedFilterTreeKeys[index]"
                      />
                    </div>
                    <div class="py-5">
                      <el-button data-testid="apply-underwriting-filter" type="primary" @click="applyFilterTree(index)"
                        >Apply</el-button
                      >
                      <el-button type="info" @click="resetFilterTree(index)">Reset</el-button>
                    </div>
                  </div>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </p>
        </div>
      </template>
      <template #body="slotProps">
        <div
          style="width: 160px; height: 20px"
          data-testid="hierarchy-value"
          class="cursor-default whitespace-nowrap overflow-hidden overflow-x-scroll panel-custom-scrollbar"
        >
          {{
            portfolio_store.dictionary[portfolio_store.parameters.hierarchies[value]][
              slotProps.data[tableDataColumns[portfolio_store.parameters.hierarchies[value]]]
            ]
          }}
        </div>
      </template>
    </Column>
    <Column
      v-for="(value, index) in portfolio_store.all_uw_dates['month'].slice(0, col_show)"
      :key="value"
      class="overflow-hidden"
      style="width: 100px; background-color: #ffffff"
      :header-style="'background-color:#bbe2d3;'"
    >
      <template #header>
        <div
          class="ml-4 w-full whitespace-nowrap cursor-context-menu"
          :class="index > cuurentMonthIndex ? 'text-blue-500' : ''"
          style="height: 20px; font-size: 0.8rem; padding-top: 2px"
          @contextmenu.prevent.stop="handleRightClickContextMenu($event, 1, [value, index])"
        >
          <p>
            <span data-testid="underwriting-month">
              {{ value }}
            </span>
            <el-dropdown ref="dropdowns" trigger="click">
              <span
                style="margin-top: 2px"
                class="cursor-pointer hover:text-sybil-teal ml-1"
                data-testid="dropdown-filter-date"
                @click="initializeFilter(index)"
              >
                <el-icon v-if="valueFiltersLength[index] == 0"><CaretBottom /></el-icon>
                <el-icon v-if="valueFiltersLength[index]"><Filter /></el-icon>
              </span>
              <template v-if="dropDownRender[index]" #dropdown>
                <el-dropdown-menu data-testid="underwriting-amount-filter-menu">
                  <div class="px-5 mt-1 text-sybil-charcoal w-full text-center text-lg">
                    {{ value }}
                  </div>
                  <div class="dropdown-container px-5 py-2 w-74">
                    <div class="mr-3 w-full">
                      <el-select
                        v-model="currentFilterAmountType"
                        :placeholder="currentFilterAmountType"
                        class="text-center w-full"
                        data-testid="underwriting-amount-filter"
                        @visible-change="dropdowns[index].handleOpen()"
                      >
                        <el-option
                          v-for="amount in amountType"
                          :key="amount"
                          :data-testid="'underwriting-amount-filter-option-' + value"
                          :label="amount"
                          :value="amount"
                        />
                      </el-select>
                    </div>
                    <div class="mr-3 w-full mt-3">
                      <el-select
                        v-model="selectedMonthOperation"
                        :placeholder="selectedMonthOperation"
                        class="text-center w-full"
                        @visible-change="dropdowns[index].handleOpen()"
                      >
                        <el-option
                          :data-testid="'less-than-equal-selection-' + value"
                          label="Less than or equal to"
                          value="<="
                        />
                        <el-option :data-testid="'less-than-selection-' + value" label="Less than" value="<" />
                        <el-option label="Greater than or equal to" value=">=" />
                        <el-option label="Greater than" value=">" />
                        <el-option label="Equal to" value="=" />
                      </el-select>
                    </div>
                    <div>
                      <div class="p-inputgroup h-10 my-3">
                        <InputText
                          v-model="filterNumber"
                          data-testid="underwriting-amount-filter-input"
                          :class="{
                            'p-invalid': false,
                          }"
                          class="radius-0"
                        />
                        <span class="p-inputgroup-addon">{{
                          ['GEP', 'GWP'].includes(currentFilterAmountType)
                            ? global_store.currency.currencyFormat.currency
                            : '%'
                        }}</span>
                      </div>
                      <span v-if="false" class="text-red-500"
                        >Please enter a valid {{ currentFilterAmountType }} value.</span
                      >
                    </div>
                    <div>
                      <el-button
                        :disabled="filterNumber == ''"
                        type="primary"
                        data-testid="add-amount-to-filter"
                        @click="addAmountToFilter()"
                        >Add</el-button
                      >
                    </div>
                    <div class="p-3 text-sybil-charcoal h-56 border-2 border-gray-100 shadow-internal my-3">
                      <div>Filters to apply...</div>
                      <div
                        v-for="(value, index) in valueVisibleFilters"
                        :key="index + '-filters'"
                        class="ml-2 w-full flex justify-start items-center pr-3"
                      >
                        {{ amountFormat(value) }}
                        <span class="text-red-500 cursor-pointer ml-auto" @click="removeAmountFromFilter(index)"
                          >remove</span
                        >
                      </div>
                    </div>
                    <div>
                      <el-button
                        :disabled="valueFilters[index] == valueVisibleFilters"
                        type="primary"
                        data-testid="apply-month-filter"
                        @click="applyValueFilter(index)"
                        >Apply</el-button
                      >
                      <el-button type="info" @click="resetValueFilter(index)">Reset</el-button>
                    </div>
                  </div>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </p>
        </div>
      </template>
      <template #body="slotProps">
        <div
          v-if="slotProps.data[tableDataColumns[value]][valueArrayColumns['Exits']]"
          style="width: 125px; height: 40px"
          class="-mx-3 -my-5 pt-3 h-24 w-20 cursor-pointer text-center w-full overflow-hidden overflow-x-scroll panel-custom-scrollbar"
          :class="
            currentMonth == value ? 'border-step-shadow' : '' + ' ' + (index > cuurentMonthIndex ? 'text-blue-500' : '')
          "
          :data-testid="'underwriting-amount-' + value"
          @click="onUnderwritingControllerModal(value, slotProps.data, index)"
        >
          {{ calculateAmount(slotProps.data[tableDataColumns[value]]) }}
        </div>
        <div
          v-else
          style="width: 125px; height: 40px"
          class="-mx-3 -my-5 h-24 w-20 bg-gray-100"
          :class="currentMonth == value ? 'border-step-shadow' : ''"
        ></div>

        <el-tooltip
          v-if="
            currentAmountType == 'Rate Change' &&
            slotProps.data[tableDataColumns[value]][valueArrayColumns['Rate Change']] != 0
          "
          :content="
            'Credit ' +
            (tableData[slotProps.index][tableDataColumns[value]][valueArrayColumns['Credit']] * 100).toString() +
            '%'
          "
          placement="bottom"
          class="h-10"
        >
          <div
            :style="{
              width:
                (tableData[slotProps.index][tableDataColumns[value]][valueArrayColumns['Credit']] * 125).toFixed(0) +
                'px',
              height: '14px',
            }"
            class="-mx-3 -mb-5 bg-gradient-to-b from-white from-65% via-sybil-teal via-66% to-sybil-teal to-100%"
          ></div>
        </el-tooltip>
      </template>
    </Column>

    <ColumnGroup v-if="totalTableData.value_array.length > 0" type="footer">
      <Row>
        <Column
          field=""
          style="width: 32px; text-align: center"
          :style="{ 'background-color': isFiltered == 0 ? '#bbe2d3' : '#f3d19e' }"
          frozen
        >
          <template #footer>
            <div
              style="width: 32px"
              class="w-full text-center font-bold cursor-context-menu"
              data-testid="total-amount-label"
              :class="isFiltered == 0 ? '-ml-1' : '-ml-2'"
              @contextmenu.prevent.stop="handleRightClickContextMenu($event, 0, totalTableData.value_array)"
            >
              {{ isFiltered == 0 ? 'Overall' : 'Subtotal' }}
            </div>
          </template>
        </Column>
        <Column
          style="width: 160px; z-index: 100"
          :style="{ 'background-color': isFiltered == 0 ? '#bbe2d3' : '#f3d19e' }"
          class="left-14"
          :class="columnLocksFooter"
        >
          <template #footer>
            <div
              style="width: 160px; height: 20px"
              class="cursor-default whitespace-nowrap overflow-hidden overflow-x-scroll panel-custom-scrollbar"
            ></div>
          </template>
        </Column>
        <Column
          v-for="(value, index) in underwriting_store.hierarchyOrder.slice(1, underwriting_store.currentLevel)"
          :key="portfolio_store.parameters.hierarchies[value]"
          style="width: 160px"
          :style="{ 'background-color': isFiltered == 0 ? '#bbe2d3' : '#f3d19e' }"
        >
          <template #footer>
            <div
              style="width: 160px; height: 20px"
              class="cursor-default whitespace-nowrap overflow-hidden overflow-x-scroll panel-custom-scrollbar"
            ></div>
          </template>
        </Column>
        <Column
          v-for="(value, index) in portfolio_store.all_uw_dates['month'].slice(0, col_show)"
          :key="value"
          class="overflow-hidden"
          style="width: 100px"
          :style="{ 'background-color': isFiltered == 0 ? '#bbe2d3' : '#f3d19e' }"
        >
          <template #footer>
            <div
              :class="
                currentMonth == value
                  ? 'border-step-shadow'
                  : '' + ' ' + (index > cuurentMonthIndex ? 'text-blue-500' : '')
              "
              style="width: 125px; height: 40px"
              data-testid="total-amount"
              class="-mx-3 -my-5 pt-3 h-24 cursor-pointer hover:font-bold text-center w-full overflow-hidden overflow-x-scroll panel-custom-scrollbar"
              @click="onUnderwritingControllerModal(value, null, index)"
            >
              {{ calculateAmount(totalTableData.value_array[index]) }}
            </div>

            <el-tooltip
              v-if="
                currentAmountType == 'Rate change and credit' &&
                slotProps.data[value] != 0 &&
                underwriting_store.creditsData.length != 0
              "
              :content="underwriting_store.creditsData[slotProps.index][value].toString()"
              placement="bottom"
            >
              <div v-if="currentAmountType == 'Rate change and credit'" class="h-1 w-full bg-sybil-teal -mb-1"></div>
            </el-tooltip>
          </template>
        </Column>
      </Row>
    </ColumnGroup>
  </DataTable>
  <DataTable
    v-if="!tableData"
    :value="skeletonData"
    :total-records="underwriting_store.underwriting_metadata['total_items']"
    ref="tableRef"
    :scrollable="true"
    :scroll-height="windowHeight - 110 + 'px'"
    show-gridlines
    underline
    scroll-direction="both"
    class="mt-1 bg-white p-datatable-sm"
    :lazy="true"
    row-group-mode="subheader"
    style="text-align: center; font-size: 12px"
  >
    <Column
      field=""
      style="width: 32px; text-align: center; background-color: #eef8f4"
      header-style="background-color:#bbe2d3 ;text-align:center"
      frozen
    >
      <template #header>
        <div class="font-bold">
          <el-tooltip content="Change hierarchy" placement="bottom" :show-after="1000">
            <img src="@/views/Underwriting/assets/Hierarchy.svg" width="20" class="png-teal cursor-pointer mx-1" />
          </el-tooltip>
        </div>
      </template>
      <template #body="slotProps">
        <div>
          {{ slotProps.data.index }}
        </div>
      </template>
    </Column>
    <Column
      v-for="(value, index) in skeletonData"
      :key="index"
      style="width: 100px; min-width: 100px; background-color: #ffffff"
      header-style="background-color:#bbe2d3"
    >
      <template #header>
        <div class="font-bold text-center w-full" style="height: 20px">......</div>
      </template>
      <template #body="slotProps">
        <div
          style="width: 100px; min-width: 100px; height: 20px"
          class="cursor-pointer hover:font-bold text-center w-full overflow-hidden overflow-x-scroll panel-custom-scrollbar"
          :class="slotProps.data[value] == 0 ? 'border-l-2 border-gray-500' : ''"
        >
          <Skeleton></Skeleton>
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<style scoped>
.panel-custom-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.panel-custom-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.internal-color {
  box-shadow: inset 0 0 10px #dddddd;
  background-color: rgb(250, 255, 255);
}

.shadow-internal {
  /* box-shadow: inset 0 0 10px #dddddd ; */
  /* background-color: rgb(250, 255, 255);  */
  /* border-color: #cfc */
}
.el-tree {
  /* box-shadow: inset 0 0 10px #dddddd ; */
  /* background-color: rgb(250, 255, 255);  */
}

.custom-scroll::-webkit-scrollbar {
  width: 7px;
  height: 0px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #eeeeee;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.p-inputgroup-addon:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.png-teal:hover {
  filter: invert(63%) sepia(42%) saturate(423%) hue-rotate(105deg) brightness(92%) contrast(93%);
}

.border-step-shadow {
  box-shadow: inset -10px 0 10px -5px #bbbbbb;
}
</style>

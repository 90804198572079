<script setup lang="ts">
import '@/assets/css/sidepanel.css';
import { useDashboardStore } from '@/stores/dashboard';
import { usePortfolioStore } from '@/stores/portfolio';
import { CaretRight, CaretBottom } from '@element-plus/icons-vue';

const props = defineProps<{ filtersWidth: string }>();
const emit = defineEmits(['getEpi']);
const dashboardStore = useDashboardStore();
const portfolioStore = usePortfolioStore();

function getEpi() {
  emit('getEpi');
}

const beforeEnter = (el) => {
  el.style.height = '0';
};

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const leave = (el) => {
  el.style.height = '0';
};

function isResetCheckboxChecked(hierarchy: string) {
  return dashboardStore.epiFiltersSelected[hierarchy].some((filter) => filter);
}

function resetCheckbox(hierarchy: string) {
  dashboardStore.epiFiltersSelected[hierarchy].fill(false);
  getEpi();
}

function resetIfAllBoxesAreChecked(hierarchy: string) {
  if (dashboardStore.epiFiltersSelected[hierarchy].every((filter) => filter)) {
    dashboardStore.epiFiltersSelected[hierarchy].fill(false);
  }
  getEpi();
}
</script>
<template>
  <div class="sidenav main-sidebars shadow">
    <div class="flex flex-row place-items-center sticky top-0 bg-white py-2 z-20"></div>
    <template v-if="props.filtersWidth === '300px'">
      <template v-for="(hierarchy, idx) in dashboardStore.epiFiltersHierarchy" :key="idx + '-risk-profile'">
        <div class="w-full side-panel-title flex flex-row pl-2">
          <div
            class="h-8 w-8 cursor-pointer mt-1"
            @click="dashboardStore.epiFiltersExpand[idx] = !dashboardStore.epiFiltersExpand[idx]"
          >
            <el-icon>
              <CaretRight v-if="!dashboardStore.epiFiltersExpand[idx]" class="text-gray-400 text-sm mt-3" />
              <CaretBottom v-if="dashboardStore.epiFiltersExpand[idx]" class="text-gray-400 text-sm mt-3" />
            </el-icon>
          </div>
          <el-checkbox
            :model-value="isResetCheckboxChecked(hierarchy)"
            class="mt-1"
            @change="resetCheckbox(hierarchy)"
          />
          <div
            class="w-full pt-1 el-checkbox__label cursor-pointer overflow-hidden text-ellipsis hover:overflow-visible hover:z-10"
            :value="hierarchy"
          >
            <label
              class="el-checkbox w-full"
              @click="dashboardStore.epiFiltersExpand[idx] = !dashboardStore.epiFiltersExpand[idx]"
            >
              <span class="el-checkbox__label w-full">
                {{ hierarchy }}
              </span>
            </label>
          </div>
        </div>
        <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
          <div v-if="dashboardStore.epiFiltersExpand[idx]" class="chunk-group-elements mt-6">
            <div>
              <el-checkbox
                v-for="(f, epiIdx) in dashboardStore.epiFilters[hierarchy]"
                :key="f['name'] + '-custom-bands'"
                v-model="dashboardStore.epiFiltersSelected[hierarchy][epiIdx]"
                class="hover:bg-gray-200 px-3 w-full"
                @change="resetIfAllBoxesAreChecked(hierarchy)"
                >{{ portfolioStore.dictionary[hierarchy][f] }}
              </el-checkbox>
            </div>
          </div>
        </transition>
      </template>
    </template>
  </div>
</template>

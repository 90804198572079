<script setup lang="ts">
import { ref } from 'vue';
import RiskProfileFilter from './RiskProfile/RiskProfileFilter.vue';
import RiskProfileTable from './RiskProfile/RiskProfileTable.vue';
import { getNumberFormat, setCellFill, tableToArray } from '@/utilities/exceljsHelper';
import { Workbook, type Fill } from 'exceljs';
import * as logo from '@/assets/images/sybil';
import * as fs from 'file-saver';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import { useDashboardStore } from '@/stores/dashboard';
import DialogComponent from '@/components/Dialog/DialogComponent.vue';
import AddingNewBandsModal from './RiskProfile/AddingNewBandsModal.vue';
const filtersWidth = ref('300px');

function resizeFilter() {
  const numericValue = parseInt(filtersWidth.value, 10); // Extract numeric value from the string
  filtersWidth.value = `${350 - numericValue}px`; // Calculate and append 'px' back
}

const globalStore = useGlobalStore();
const dashboardStore = useDashboardStore();
const portfolioStore = usePortfolioStore();

const isAddNewBandModal = ref(false);
const selectedBand = ref('');

function onDownloadExcel() {
  let workbook = new Workbook();
  for (const key of Object.keys(dashboardStore.riskProfileFilterData)) {
    const tableData = tableToArray(document.getElementById('exposure-table-' + key));
    let worksheet = workbook.addWorksheet(key + ' Table');

    let myLogoImage = workbook.addImage({
      base64: logo.imgBase64,
      extension: 'png',
    });
    worksheet.addImage(myLogoImage, 'A1:C5');
    worksheet.addRow([]);

    worksheet.getCell('B7').value = portfolioStore.selectedPortfolioName;
    worksheet.getCell('B7').alignment = {
      horizontal: 'left',
      vertical: 'middle',
    };

    worksheet.getCell('B8').value = portfolioStore.selectedBounceDate;
    worksheet.getCell('B8').alignment = {
      horizontal: 'left',
      vertical: 'middle',
    };

    worksheet.getCell('B9').value = portfolioStore.selectedBounceFullName;
    worksheet.getCell('B9').alignment = {
      horizontal: 'left',
      vertical: 'middle',
    };

    worksheet.getCell('B11').value = 'Selection: Trade';
    worksheet.getCell('B11').alignment = {
      horizontal: 'left',
      vertical: 'middle',
    };

    let rowIndex = 13;
    let colAddition = 2;
    let row = worksheet.getRow(rowIndex++);
    for (let colIndexStr in tableData[0]) {
      const colIndex = parseInt(colIndexStr);
      const cell = row.getCell(colIndex + colAddition);
      cell.value = tableData[0][colIndex].value;
      cell.alignment = {
        wrapText: true,
        horizontal: 'center',
        vertical: 'middle',
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'bbe2d3' },
      };
    }

    let rowAddition = rowIndex - 1;

    tableData.forEach((rowData, rowIndex) => {
      if (rowIndex === 0) return;
      let row = worksheet.getRow(rowIndex + rowAddition);
      rowData.forEach((cellData, colIndex) => {
        const cell = row.getCell(colIndex + 2);
        cell.value = cellData.value;
        if (colIndex !== 0) {
          switch (cellData.type) {
            case 'number':
              const precision = globalStore.currency.currencyFormat.precision;
              cell.numFmt = getNumberFormat(precision);
              break;
            case 'percentage':
              cell.numFmt = '0.0%';
              break;
            // Other formats as needed
          }
        } else {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'eef8f4' },
          };
        }

        if (rowData[0].value === 'Total') {
          cell.fill = <Fill>setCellFill('bbe2d3');
        }
      });
    });
  }

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    fs.saveAs(
      blob,
      portfolioStore.selectedPortfolioName + '-' + portfolioStore.selectedBounceFullName + '-risk-profile.xlsx'
    );
  });
}

function openNewBandModal(hierarchy) {
  selectedBand.value = hierarchy;
  isAddNewBandModal.value = true;
}
</script>
<template>
  <div>
    <DialogComponent v-model="isAddNewBandModal" :title="'Adding new bands (' + selectedBand + ')'">
      <AddingNewBandsModal :selected-band="selectedBand" />
    </DialogComponent>
    <div class="w-full" :style="{ width: `calc(100% - ${filtersWidth})` }">
      <div class="flex flex-row overflow-y-scroll">
        <div v-for="key of dashboardStore.riskProfileNumberOfTables" :key="key + '-table'" style="width: min-content">
          <RiskProfileTable :idx="key" />
        </div>
      </div>
    </div>
    <div class="absolute bg-white top-20 right-0 z-50" style="margin-top: -15px">
      <div
        :style="{
          width: filtersWidth,
          transition: '0.5s ease-out all',
        }"
      >
        <RiskProfileFilter
          style="width: inherit"
          :filters-width="filtersWidth"
          @open-modal="openNewBandModal"
          @resize-filter="resizeFilter()"
          @on-download-excel="onDownloadExcel"
        />
      </div>
    </div>
  </div>
</template>

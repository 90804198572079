import axios from 'axios';

const apiUrl = import.meta.env.VITE_API_URL;
import { useAuthenticationStore } from '@/stores/auth';

export const api = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  timeout: 600000,
});

// api.interceptors.request.use(async (config) => {
//     const user = localStorage.getItem('user')
//     if (user) {
//         const userParse = JSON.parse(user);
//         const accessToken = userParse.access_token
//         config.headers.Authorization = `Bearer ${accessToken}`;
//     }
//     return config;
// }, function (err) {
//     return Promise.reject(err)
// })

api.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const auth_store = useAuthenticationStore();
    if (err.response && err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      const user = localStorage.getItem('user');
      if (user) {
        // const userParse = JSON.parse(user);
        // const refreshToken = userParse.refresh_token
        try {
          await axios.post(apiUrl + 'users/refresh_token', {}, { withCredentials: true });
          return api(originalConfig);
        } catch (_error) {
          auth_store.reset();
          return Promise.reject(_error);
        }
      }
      auth_store.reset();
      return Promise.reject();
    }
    return Promise.reject(err);
  }
);

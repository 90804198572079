<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { ref } from 'vue';
import DashboardHeader from './components/headers/DashboardHeader.vue';
import UltimatesHeader from './components/headers/UltimatesHeader.vue';
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardStore } from '@/stores/dashboard';
import { useGlobalStore } from '@/stores/global';
import type { BooleanDictionary, NumericDictionary } from '@/types/common';
import { Download, Check, Close, Top, Bottom, Edit } from '@element-plus/icons-vue';
import '@/views/Dashboard/ClaimsDashboard/DashboardTable/dashboardTable.css';
import ValueRow from './components/rows/ValueRow.vue';
import type { DashboardData } from '@/types/dashboard';
import Skeleton from 'primevue/skeleton';
import { Workbook, type Fill } from 'exceljs';
import * as fs from 'file-saver';
import * as logo from '@/assets/images/sybil';
import toTitleCase from '@/utilities/toTitleCase';
import ForwardLookingHorizontal from '@/components/ForwardLookingHorizontal.vue';

const portfolioStore = usePortfolioStore();
const dashboardStore = useDashboardStore();
const globalStore = useGlobalStore();

const dataFilters = computed(() => dashboardStore.selectedData);
const allDataFilters = computed(() => dashboardStore.dataFilters);
const dateFilters = computed(() => dashboardStore.dateFilters);
const dictionary = computed(() => portfolioStore.dictionary);

const dashboardForwardlooking = computed(() =>
  dashboardStore.dashboards.ccr_nlr == 'CCR'
    ? dashboardStore.dashboardForwardLookings?.map((x: [number, number]) => x[0] + x[1])
    : dashboardStore.dashboardForwardLookings?.map((x: [number, number]) => x[0] / (1 - x[1]))
);

const emit = defineEmits(['binderChange']);

function getNumberFormat(precision) {
  let format = '#,##0'; // Base format for zero precision

  if (precision > 0) {
    // Add decimal places based on precision
    format += '.' + '0'.repeat(precision);
  }

  return format;
}

function toExcelCellRef(row, col) {
  // Convert column index to letter(s)
  let colRef = '';
  let dividend = col;
  let modulo;

  while (dividend > 0) {
    modulo = (dividend - 1) % 26;
    colRef = String.fromCharCode(65 + modulo) + colRef;
    dividend = Math.floor((dividend - modulo) / 26);
  }

  // Combine with row index
  return colRef + row;
}

const props = defineProps<{
  overflow: string;
}>();
const getIBNRSignedOff = computed<boolean>(() => {
  return Object.keys(portfolioStore.signedOff['actuarial_module']).every((e) => {
    return portfolioStore.signedOff['actuarial_module'][e] == true;
  });
});
const selectedGWPUnit = computed<string>(() => globalStore.currency.currencyFormat.selectedGWPUnit);
const gwpUnits = computed(() => globalStore.currency.GWPUnits);
const currency = computed(() => globalStore.currency.currencyFormat.currency);
const mqy = computed(() => dashboardStore.dashboards.mqy);

const binderDashboardData = computed(() => dashboardStore.binder_dashboard_data);
const quarterDashboardData = computed(() => dashboardStore.quarterly_dashboard_data);
const yearDashboardData = computed(() => dashboardStore.yearly_dashboard_data);

const isYearSubTotal = computed(() => dashboardStore.isYearSubTotal);
const isYearSubTotalUp = computed(() => dashboardStore.isYearSubTotalUp);
const isQuarterSubTotal = computed(() => dashboardStore.isQuarterSubTotal);
const isQuarterSubTotalUp = computed(() => dashboardStore.isQuarterSubTotalUp);
const isBinderSubTotal = computed(() => dashboardStore.isBinderSubTotal);
const isBinderSubTotalUp = computed(() => dashboardStore.isBinderSubTotalUp);
const claimsType = computed<string[]>(() => portfolioStore.parameters.claims_nature);
const showColumnTotal = ref(false);
const totalMargin = ref(0);

const showColumn = ref<BooleanDictionary>(
  claimsType.value.reduce((acc: BooleanDictionary, curr: string) => ((acc[curr] = false), acc), {} as BooleanDictionary)
);

const leftColumnSize = computed(
  () => 3 + (dashboardStore.isShowingExposure ? portfolioStore.getExposureLength() + 1 : 0)
);

const margin = ref<NumericDictionary>(
  claimsType.value.reduce((acc: NumericDictionary, curr: string) => {
    acc[curr] = 0;
    return acc;
  }, {} as NumericDictionary)
);
const totalMarginCCR = ref(0);
const dashboardData = computed<DashboardData>(() => dashboardStore.dashboard_data);

const totalDashboardData = computed<DashboardData>(() => {
  const obj = {};
  obj[0] = dashboardStore.totalData;
  return obj;
});

onMounted(() => {
  console.log(totalDashboardData.value);
});

function getType(data, columnIndex) {
  if (columnIndex === 0) {
    return 'string'; // Treat the first column as text
  }
  // Remove commas and check if it's a valid number
  const unformattedNumber = data.replace(/,/g, '');
  if (!isNaN(unformattedNumber) && unformattedNumber.trim() !== '') {
    return 'number';
  }
  if (data.endsWith('%')) {
    return 'percentage';
  }
  return 'string';
}

function parseData(data, type) {
  switch (type) {
    case 'number':
      return parseFloat(data.replace(/,/g, ''));
    case 'percentage':
      return parseFloat(data.replace('%', '')) / 100;
    default:
      return data;
  }
}

function tableToArray(table) {
  const rows = table.querySelectorAll('tr');
  return Array.from(rows).map((tr) => {
    return Array.from((tr as HTMLElement).querySelectorAll('td, th')).map((td: Element, columnIndex) => {
      const type = getType((td as HTMLElement).innerText, columnIndex);
      const value = parseData((td as HTMLElement).innerText, type);
      return { value, type };
    });
  });
}

function cleanString(str) {
  return str.replace(/\n+/g, ' ').trim();
}

function onDownloadExcel() {
  // Assuming you have a reference to your HTML table
  const tableData = tableToArray(document.getElementById('performance_table'));

  console.log(tableData);

  let workbook = new Workbook();

  let worksheet = workbook.addWorksheet('Performance Table');
  worksheet.mergeCells('A1:C5');
  let myLogoImage = workbook.addImage({
    base64: logo.imgBase64,
    extension: 'png',
  });
  worksheet.addImage(myLogoImage, 'A1:C5');
  worksheet.addRow([]);

  worksheet.getCell('B7').value = portfolioStore.selectedPortfolioName;
  worksheet.getCell('B7').alignment = {
    horizontal: 'left',
    vertical: 'middle',
  };

  worksheet.getCell('B8').value = portfolioStore.selectedBounceDate;
  worksheet.getCell('B8').alignment = {
    horizontal: 'left',
    vertical: 'middle',
  };

  worksheet.getCell('B9').value = portfolioStore.selectedBounceFullName;
  worksheet.getCell('B9').alignment = {
    horizontal: 'left',
    vertical: 'middle',
  };

  worksheet.getCell('B11').value = 'Filter Selections:';
  worksheet.getCell('B11').alignment = {
    horizontal: 'left',
    vertical: 'middle',
  };

  let rowIndex = 12;
  for (let item in dataFilters.value) {
    let row = worksheet.getRow(rowIndex++);
    let cell = row.getCell(2);
    cell.value = item;
    if (allDataFilters.value[item].length == dataFilters.value[item].length) {
      let cell = row.getCell(3);
      cell.value = 'All';
    } else {
      let objCounter = 3;
      for (let obj of Object.values(dataFilters.value[item])) {
        let cell = row.getCell(objCounter++);
        cell.value = obj;
      }
    }
  }

  let row = worksheet.getRow(rowIndex++);
  let cell = row.getCell(2);
  cell.value = 'Underwriting Month';
  if (
    dateFilters.value['underwriting']['month'].filter((x) => x != 0).length == 0 ||
    dateFilters.value['underwriting']['month'].filter((x) => x != 0).length ==
      dateFilters.value['underwriting']['month'].length
  ) {
    let cell = row.getCell(3);
    cell.value = 'All';
  } else {
    let objCounter = 3;
    for (let item in dateFilters.value['underwriting']['month']) {
      if (dateFilters.value['underwriting']['month'][item] != 0) {
        cell = row.getCell(objCounter++);
        cell.value = dateFilters.value['underwriting']['month'][item];
      }
    }
  }

  row = worksheet.getRow(rowIndex++);
  cell = row.getCell(2);
  cell.value = 'Accident Month';
  if (
    dateFilters.value['accident']['month'].filter((x) => x != 0).length == 0 ||
    dateFilters.value['accident']['month'].filter((x) => x != 0).length == dateFilters.value['accident']['month'].length
  ) {
    let cell = row.getCell(3);
    cell.value = 'All';
  } else {
    let objCounter = 3;
    for (let item in dateFilters.value['accident']['month']) {
      if (dateFilters.value['accident']['month'][item] != 0) {
        cell = row.getCell(objCounter++);
        cell.value = dateFilters.value['accident']['month'][item];
      }
    }
  }

  let iLength = claimsType.value.length + 1;
  const exposureAddition = dashboardStore.isShowingExposure ? portfolioStore.getExposureLength() + 1 : 0;
  let jLength =
    dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw' ? 6 : 5;
  rowIndex++;
  let r = worksheet.getRow(rowIndex++);
  let c = r.getCell(2);
  c.value = 'Amounts are in ' + currency.value + gwpUnits.value[selectedGWPUnit.value][1];
  let colAddition = 2;
  for (let colIndexStr in tableData[0]) {
    const colIndex = parseInt(colIndexStr);
    const row = worksheet.getRow(rowIndex);
    const cell = row.getCell(colIndex + colAddition);
    cell.value = cleanString(tableData[0][colIndex].value);
    if (colIndex >= 1 && colIndex <= 2 + exposureAddition) {
      cell.font = { color: { argb: '55B691' } };
    }
    if (colIndex === 3 + exposureAddition) {
      cell.font = { color: { argb: 'EF4444' } };
    }
    if (colIndex === 4 + exposureAddition) {
      cell.font = { color: { argb: '60a5fa' } };
    }
    if (colIndex === 6 + exposureAddition) {
      cell.font = { color: { argb: '7c2d12' } };
    }

    cell.border = {
      top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
      left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
      bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
      right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
    };

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'bbe2d3' },
    };
    cell.alignment = {
      wrapText: true,
      horizontal: 'center',
      vertical: 'middle',
    };
    if (colIndex != 3 + exposureAddition) {
      console.log(
        toExcelCellRef(rowIndex, colIndex + colAddition) + ':' + toExcelCellRef(rowIndex + 2, colIndex + colAddition)
      );
      worksheet.mergeCells(
        toExcelCellRef(rowIndex, colIndex + colAddition) + ':' + toExcelCellRef(rowIndex + 2, colIndex + colAddition)
      );
    } else {
      const level3Header = tableData[1]
        .map((item) => item.value) // Extract the 'value' from each object
        .filter((value, index, self) => self.indexOf(value) === index);
      worksheet.mergeCells(
        toExcelCellRef(rowIndex, colIndex + colAddition) +
          ':' +
          toExcelCellRef(rowIndex, colIndex + colAddition + iLength * jLength - 1)
      );
      for (let j of claimsType.value) {
        const row2 = worksheet.getRow(rowIndex + 1);
        const cell2 = row2.getCell(colIndex + colAddition);
        cell2.value = toTitleCase(j);

        cell2.font = { color: { argb: 'EF4444' } };

        cell2.border = {
          top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
          left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
          bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
          right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
        };

        cell2.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'bbe2d3' },
        };
        cell2.alignment = {
          wrapText: true,
          horizontal: 'center',
          vertical: 'middle',
        };

        worksheet.mergeCells(
          toExcelCellRef(rowIndex + 1, colIndex + colAddition) +
            ':' +
            toExcelCellRef(rowIndex + 1, colIndex + colAddition + jLength - 1)
        );
        let level3ColIndex = 0;
        for (let k of level3Header) {
          const row3 = worksheet.getRow(rowIndex + 2);
          const cell3 = row3.getCell(colIndex + colAddition + level3ColIndex++);
          cell3.value = k;

          cell3.font = { color: { argb: 'EF4444' } };

          cell3.border = {
            top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
            left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
            bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
            right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
          };

          cell3.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'bbe2d3' },
          };
          cell3.alignment = {
            wrapText: true,
            horizontal: 'center',
            vertical: 'middle',
          };
        }
        colAddition = colAddition + jLength;
      }
      const row2 = worksheet.getRow(rowIndex + 1);
      const cell2 = row2.getCell(colIndex + colAddition);
      cell2.value = 'Total';

      cell2.font = { color: { argb: 'EF4444' } };

      cell2.border = {
        top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
        left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
        bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
        right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
      };

      cell2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'bbe2d3' },
      };
      cell2.alignment = {
        wrapText: true,
        horizontal: 'center',
        vertical: 'middle',
      };

      worksheet.mergeCells(
        toExcelCellRef(rowIndex + 1, colIndex + colAddition) +
          ':' +
          toExcelCellRef(rowIndex + 1, colIndex + colAddition + jLength - 1)
      );
      let level3ColIndex = 0;
      for (let k of level3Header) {
        const row3 = worksheet.getRow(rowIndex + 2);
        const cell3 = row3.getCell(colIndex + colAddition + level3ColIndex++);
        cell3.value = k;

        cell3.font = { color: { argb: 'EF4444' } };

        cell3.border = {
          top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
          left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
          bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
          right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
        };

        cell3.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'bbe2d3' },
        };
        cell3.alignment = {
          wrapText: true,
          horizontal: 'center',
          vertical: 'middle',
        };
      }
      colAddition = colAddition + jLength - 1;
    }
  }

  let rowAddition = rowIndex + 1;

  tableData.forEach((rowData, rowIndex) => {
    const row = worksheet.getRow(rowIndex + rowAddition);
    if (rowIndex > 1) {
      rowData.forEach((cellData, colIndex) => {
        const cell = row.getCell(colIndex + 2);
        cell.value = cellData.value;

        if (colIndex >= 1 && colIndex <= 2 + exposureAddition) {
          cell.font = { color: { argb: '55B691' } };
        }
        if (colIndex > 2 + exposureAddition && colIndex < 3 + exposureAddition + iLength * jLength) {
          cell.font = { color: { argb: 'EF4444' } };
          if (
            (colIndex +
              exposureAddition +
              (dashboardStore.underwriting_loss_ratios == 'Written' && dashboardStore.dashboards.uw_acc == 'uw'
                ? 4
                : 3)) %
              jLength !=
            0
          ) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'f4f5f6' },
            };
          }
          if (
            (colIndex + 3 + exposureAddition) % jLength == 4 &&
            dashboardStore.underwriting_loss_ratios == 'Written' &&
            dashboardStore.dashboards.uw_acc == 'uw'
          ) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fef2f2' },
            };
          }
        }
        if (colIndex === 3 + exposureAddition + iLength * jLength) {
          cell.font = { color: { argb: '60a5fa' } };
        }
        if (colIndex === 5 + exposureAddition + iLength * jLength) {
          cell.font = { color: { argb: '7c2d12' } };
        }

        cell.border = {
          top: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey top border
          left: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey left border
          bottom: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey bottom border
          right: { style: 'thin', color: { argb: 'FFEEEEEE' } }, // Grey right border
        };

        cell.alignment = {
          wrapText: true,
          horizontal: 'center',
          vertical: 'middle',
        };

        // Apply number formatting only to other columns
        if (colIndex !== 0) {
          switch (cellData.type) {
            case 'number':
              const precision = globalStore.currency.currencyFormat.precision;
              cell.numFmt = getNumberFormat(precision);
              break;
            case 'percentage':
              cell.numFmt = '0.0%';
              break;
            // Other formats as needed
          }
        } else {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'eef8f4' },
          };
        }

        const firstChar = rowData[0].value.slice(0, 1);

        // Common function to set cell fill
        function setCellFill(argbColor) {
          return {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: argbColor },
          };
        }

        if (rowData[0].value === 'Total') {
          cell.fill = <Fill>setCellFill('bbe2d3');
        } else if (mqy.value === 'month') {
          if (firstChar === 'Q') {
            cell.fill = <Fill>setCellFill('dfe9e5');
          } else if (firstChar === '2') {
            cell.fill = <Fill>setCellFill('d0dad6');
          } else if (firstChar === 'B') {
            cell.fill = <Fill>setCellFill('dcebf5');
          }
        } else if (mqy.value === 'quarter' && firstChar === '2') {
          cell.fill = <Fill>setCellFill('d0dad6');
        } else if (mqy.value === 'quarter' && firstChar === 'B') {
          cell.fill = <Fill>setCellFill('dcebf5');
        }

        // Set text color and cell color as before
      });
    }

    row.commit();
  });

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    fs.saveAs(blob, portfolioStore.selectedPortfolioName + '-' + portfolioStore.selectedBounceFullName + '.xlsx');
  });
}

function binderChange() {
  emit('binderChange');
}

function onChangeCcrMargin(e: number) {
  totalMarginCCR.value = e;
}
function onChangeTotalMargin(e: number) {
  totalMargin.value = e;
}

function onChangeShowColumnTotal(e: boolean) {
  showColumnTotal.value = e;
}

function onChangeShowColumn(e: { val: boolean; item: string }) {
  showColumn.value[e.item] = e.val;
}

function onChangeMargin(e: { val: number; item: string }) {
  margin.value[e.item] = e.val;
  console.log(margin.value);
}

function toggleIsQuarterSubTotal() {
  dashboardStore.toggleQuarterSubTotal();
}
function toggleIsQuarterSubTotalUp() {
  dashboardStore.toggleQuarterSubTotalUp();
}
function toggleIsBinderSubTotal() {
  dashboardStore.toggleBinderSubTotal();
}
function toggleIsBinderSubTotalUp() {
  dashboardStore.toggleBinderSubTotalUp();
}
function toggleIsYearSubTotal() {
  dashboardStore.toggleYearSubTotal();
}
function toggleIsYearSubTotalUp() {
  dashboardStore.toggleYearSubTotalUp();
}

function toggleIsExposure() {
  dashboardStore.isShowingExposure = !dashboardStore.isShowingExposure;
}
</script>
<template>
  <div>
    <div class="table-panel relative shadow-md" :class="'overflow-' + props.overflow">
      <table data-testid="performance-table" id="performance_table" class="shadow bg-white" style="border-spacing: 0">
        <thead class="sticky top-0 z-30 header-teal">
          <tr>
            <DashboardHeader
              :total-margin-ccr="totalMarginCCR"
              :total-margin="totalMargin"
              :margin="margin"
              :left-column-size="leftColumnSize"
              @on-change-ccr-margin="onChangeCcrMargin"
            />
          </tr>
          <tr>
            <UltimatesHeader
              :margin="margin"
              :total-margin="totalMargin"
              :show-column-total="showColumnTotal"
              :show-column="showColumn"
              :left-column-size="leftColumnSize"
              @on-change-total-margin="onChangeTotalMargin"
              @on-change-margin="onChangeMargin"
              @on-change-show-column="onChangeShowColumn"
              @on-change-show-column-total="onChangeShowColumnTotal"
            />
          </tr>
        </thead>
        <tbody v-if="dashboardData && dashboardStore.totalData">
          <template v-for="(n, idx) in dashboardData" :key="idx">
            <tr>
              <ValueRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column="showColumn"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :left-column-size="leftColumnSize"
                :total-margin-ccr="totalMarginCCR"
                :dashboard-data="dashboardData"
                :is-total="false"
                :is-total-row="false"
                row-class=""
              />
            </tr>
            <tr
              v-if="
                binderDashboardData[idx] &&
                isBinderSubTotal &&
                isBinderSubTotalUp &&
                mqy == 'month' &&
                dashboardStore.dashboards.uw_acc == 'uw'
              "
            >
              <ValueRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column="showColumn"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :left-column-size="leftColumnSize"
                :total-margin-ccr="totalMarginCCR"
                :dashboard-data="binderDashboardData"
                :is-total="true"
                :is-total-row="false"
                row-class="total-teal"
              />
            </tr>
            <tr v-if="quarterDashboardData[idx] && isQuarterSubTotal && isQuarterSubTotalUp && mqy == 'month'">
              <ValueRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column="showColumn"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :left-column-size="leftColumnSize"
                :total-margin-ccr="totalMarginCCR"
                :dashboard-data="quarterDashboardData"
                :is-total="true"
                :is-total-row="false"
                row-class="total-teal-quarter"
              />
            </tr>
            <tr v-if="yearDashboardData[idx] && isYearSubTotal && isYearSubTotalUp && mqy != 'year'">
              <ValueRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column="showColumn"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :left-column-size="leftColumnSize"
                :total-margin-ccr="totalMarginCCR"
                :dashboard-data="yearDashboardData"
                :is-total="true"
                :is-total-row="false"
                row-class="total-teal"
              />
            </tr>
          </template>
          <template v-if="isQuarterSubTotal && !isQuarterSubTotalUp && mqy == 'month'">
            <tr v-for="(n, idx) in quarterDashboardData" :key="idx">
              <ValueRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column="showColumn"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :left-column-size="leftColumnSize"
                :total-margin-ccr="totalMarginCCR"
                :dashboard-data="quarterDashboardData"
                :is-total="true"
                :is-total-row="false"
                row-class="total-teal-quarter"
              />
            </tr>
          </template>
          <template
            v-if="isBinderSubTotal && !isBinderSubTotalUp && mqy == 'month' && dashboardStore.dashboards.uw_acc == 'uw'"
          >
            <tr v-for="(n, idx) in binderDashboardData" :key="idx">
              <ValueRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column="showColumn"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :left-column-size="leftColumnSize"
                :total-margin-ccr="totalMarginCCR"
                :dashboard-data="binderDashboardData"
                :is-total="true"
                :is-total-row="false"
                row-class="total-teal"
              />
            </tr>
          </template>
          <template v-if="isYearSubTotal && !isYearSubTotalUp && mqy != 'year'">
            <tr v-for="(n, idx) in yearDashboardData" :key="idx">
              <ValueRow
                :row-index="parseInt(idx.toString())"
                :margin="margin"
                :show-column="showColumn"
                :show-column-total="showColumnTotal"
                :total-margin="totalMargin"
                :left-column-size="leftColumnSize"
                :total-margin-ccr="totalMarginCCR"
                :dashboard-data="yearDashboardData"
                :is-total="true"
                :is-total-row="false"
                row-class="total-teal"
              />
            </tr>
          </template>
          <tr class="sticky z-30 bg-gray-300 bottom-0 total-row">
            <ValueRow
              :row-index="0"
              :margin="margin"
              :show-column="showColumn"
              :show-column-total="showColumnTotal"
              :total-margin="totalMargin"
              :left-column-size="leftColumnSize"
              :total-margin-ccr="totalMarginCCR"
              :dashboard-data="totalDashboardData"
              :is-total="true"
              :is-total-row="true"
              row-class="header-teal"
            />
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="i in Array(50).fill('')" :key="i">
            <td v-for="j in Array(20).fill('')" :key="i * j">
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="shadow-md bg-white flex flex-row justify-between pt-3" style="height: 60px">
      <div>
        <el-button
          class="pl-5 left-0 text-gray-500 cursor-pointer item mx-5 mt-2"
          type="info"
          link
          @click="globalStore.setCurrencyModal(true)"
          ><i>Amounts are in {{ currency + gwpUnits[selectedGWPUnit][1] }}</i></el-button
        >
      </div>
      <div>
        <ForwardLookingHorizontal
          v-if="dashboardForwardlooking"
          :forwardlooking="dashboardForwardlooking"
          :ccr-nlr="dashboardStore.dashboards.ccr_nlr"
          class="hover:scale-125 ease-in-out duration-1000"
        />
      </div>
      <div
        class="text-gray-500 text-sm z-5 mt-1 flex flex-row overflow-scroll panel-custom-scrollbar"
        style="height: 30px; width: 600px"
      >
        <el-button
          v-if="portfolioStore.getExposureLength() > 0"
          :type="dashboardStore.isShowingExposure ? 'primary' : 'info'"
          class="-mr-3"
          @click="toggleIsExposure"
          >E</el-button
        >
        <el-button
          v-if="portfolioStore.getExposureLength() > 0"
          type="primary"
          class="-ml-3 w-1"
          data-testid="toggle-binder-modal"
          @click="dashboardStore.isCreateNewBandsOnDashboardModal = true"
        >
          <el-icon><Edit /></el-icon>
        </el-button>
        <el-button
          :color="
            isBinderSubTotal && mqy == 'month' && dashboardStore.dashboards.uw_acc == 'uw' ? '#6082B6' : '#555555'
          "
          :disabled="mqy != 'month' || dashboardStore.dashboards.uw_acc == 'acc'"
          class="-mr-3"
          @click="toggleIsBinderSubTotal"
          >B</el-button
        >
        <el-button
          :color="
            isBinderSubTotal && mqy == 'month' && dashboardStore.dashboards.uw_acc == 'uw' ? '#6082B6' : '#555555'
          "
          class="ml-0 -mr-3 w-1"
          :disabled="!isBinderSubTotal || mqy != 'month' || dashboardStore.dashboards.uw_acc == 'acc'"
          @click="toggleIsBinderSubTotalUp"
        >
          <el-icon v-if="!isBinderSubTotalUp"><Top /></el-icon>
          <el-icon v-if="isBinderSubTotalUp"><Bottom /></el-icon>
        </el-button>
        <el-button color="#6082B6" class="ml-0 w-1" data-testid="toggle-binder-modal" @click="binderChange()">
          <el-icon><Edit /></el-icon>
        </el-button>
        <el-button
          :type="isQuarterSubTotal && mqy == 'month' ? 'primary' : 'info'"
          :disabled="mqy != 'month'"
          class="-mr-3"
          @click="toggleIsQuarterSubTotal"
          >Q</el-button
        >
        <el-button
          :type="isQuarterSubTotal && mqy == 'month' ? 'primary' : 'info'"
          class="ml-0 w-1"
          :disabled="!isQuarterSubTotal || mqy != 'month'"
          @click="toggleIsQuarterSubTotalUp"
        >
          <el-icon v-if="!isQuarterSubTotalUp"><Top /></el-icon>
          <el-icon v-if="isQuarterSubTotalUp"><Bottom /></el-icon>
        </el-button>
        <el-button
          :type="isYearSubTotal && mqy != 'year' ? 'primary' : 'info'"
          :disabled="mqy == 'year'"
          class="-mr-3"
          @click="toggleIsYearSubTotal"
          >Y</el-button
        >
        <el-button
          :type="isYearSubTotal && mqy != 'year' ? 'primary' : 'info'"
          class="ml-0 w-1"
          :disabled="!isYearSubTotal || mqy == 'year'"
          @click="toggleIsYearSubTotalUp"
        >
          <el-icon v-if="!isYearSubTotalUp"><Top /></el-icon>
          <el-icon v-if="isYearSubTotalUp"><Bottom /></el-icon>
        </el-button>

        <el-button key="primary" type="primary" class="text-center w-5" @click="onDownloadExcel()">
          <el-icon><Download /></el-icon>
        </el-button>
        <div class="flex flex-row ml-4 -mt-2">
          <div v-if="true" class="flex flex-col mr-4">
            <h1 class="mt-1 overflow-hidden">IBNR Signed-off</h1>
            <h1 class="overflow-hidden">A-priori Signed-off</h1>
          </div>
          <div v-if="true" class="flex flex-col mr-4">
            <div v-if="getIBNRSignedOff">
              <el-icon color="green" :size="20" class="mt-1"><Check /></el-icon>
            </div>
            <div v-else>
              <el-icon color="red" :size="20" class="mt-1"><Close /></el-icon>
            </div>

            <div v-if="portfolioStore.signedOff['underwriting_module']" class="-mt-1">
              <el-icon color="green" :size="20" class="-mt-2"><Check /></el-icon>
            </div>
            <div v-else class="-mt-1">
              <el-icon color="red" :size="20" class="-mt-2"><Close /></el-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

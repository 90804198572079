<script setup lang="ts">
import { useDashboardStore } from '@/stores/dashboard';
import { onMounted, computed } from 'vue';
const props = defineProps(['forwardlookings', 'ccr_nlr', 'tootltipDisabled']);

const forwardlooking = computed(() =>
  dashboardStore.dashboards.ccr_nlr == 'CCR'
    ? ccrCalculation()
    : props.forwardlookings?.map((x: [number, number]) => x[0] / (1 - x[1]))
);

const ccr = computed(() => ccrCalculation());

function ccrCalculation() {
  return props.forwardlookings?.map((x: [number, number]) => x[0] + x[1]);
}

function calcMargin(ccr, gwp) {
  return (1 - ccr) * gwp;
}

function convertToMillionFormat(num) {
  if (typeof num !== 'number' || isNaN(num)) {
    throw new Error('Input must be a valid number');
  }
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else {
    return (num / 1000).toFixed(1) + 'K';
  }
}
const dashboardStore = useDashboardStore();

const tootltipDisabled = computed(() => props.tootltipDisabled);

onMounted(() => console.log(props.forwardlookings));
</script>

<template>
  <div
    v-if="forwardlooking"
    class="h-14 -mt-2 bg-sybil-charcoal text-white p-1 rounded-lg w-72 hover:scale-150 hover:z-80 hover:mt-2 hover:mr-5 ease-in-out duration-1000 flex flex-row"
  >
    <div class="relative w-24">
      <div class="absolute left-0">
        <h1 class="text-xs -rotate-90 -ml-4 mt-4 scale-x-90">FWD {{ props.ccr_nlr }}</h1>
      </div>
      <div class="grid grid-rows-3 gap-1 z-20 -mt-1 watermark absolute pl-2 w-full">
        <el-tooltip
          :disabled="tootltipDisabled"
          placement="bottom"
          :offset="30"
          content="Current month forward looking"
        >
          <p class="mr-1 text-sm cursor-help text-center text-sybil-teal brightness-200 hover:text-red-500">
            {{ (forwardlooking[0] * 100).toFixed(1) }}%
          </p>
        </el-tooltip>
        <el-tooltip
          :disabled="tootltipDisabled"
          placement="bottom"
          :offset="30"
          content="Average next 12 months forward looking"
        >
          <b class="font-bold mr-1 -mt-2 cursor-help text-center text-sybil-teal brightness-125 hover:text-red-500"
            >{{ (forwardlooking[1] * 100).toFixed(1) }}%
          </b>
        </el-tooltip>
        <el-tooltip
          :disabled="tootltipDisabled"
          placement="bottom"
          :offset="30"
          content="After 12 months forward looking"
        >
          <p class="mr-1 text-sm -mt-3 cursor-help text-center text-sybil-teal brightness-200 hover:text-red-500">
            {{ (forwardlooking[2] * 100).toFixed(1) }}%
          </p>
        </el-tooltip>
      </div>
    </div>
    <div class="relative w-24">
      <div class="absolute left-0">
        <h1 class="text-xs -rotate-90 -ml-4 mt-4 scale-x-90">GWP</h1>
      </div>
      <div class="grid grid-rows-3 gap-1 z-20 -mt-1 watermark absolute pl-2 w-full">
        <el-tooltip :disabled="tootltipDisabled" placement="bottom" :offset="30" content="Current month GWP">
          <p class="mr-1 text-sm cursor-help text-center text-sybil-teal brightness-200 hover:text-red-500">
            {{ convertToMillionFormat(props.forwardlookings[0][2]) }}
          </p>
        </el-tooltip>
        <el-tooltip :disabled="tootltipDisabled" placement="bottom" :offset="30" content="Total next 12 months GWP">
          <b class="font-bold mr-1 -mt-2 cursor-help text-center text-sybil-teal brightness-125 hover:text-red-500"
            >{{ convertToMillionFormat(props.forwardlookings[1][2]) }}
          </b>
        </el-tooltip>
        <el-tooltip :disabled="tootltipDisabled" placement="bottom" :offset="30" content="After 12 months GWP">
          <p class="mr-1 text-sm -mt-3 cursor-help text-center text-sybil-teal brightness-200 hover:text-red-500">
            {{ convertToMillionFormat(props.forwardlookings[2][2]) }}
          </p>
        </el-tooltip>
      </div>
    </div>
    <div class="relative w-24">
      <div class="absolute left-0">
        <h1 class="text-xs -rotate-90 -ml-4 mt-4 scale-x-90">Margin</h1>
      </div>
      <div class="grid grid-rows-3 gap-1 z-20 -mt-1 watermark absolute pl-2 w-full">
        <el-tooltip :disabled="tootltipDisabled" placement="bottom" :offset="30" content="Current month margin">
          <p class="mr-1 text-sm cursor-help text-center text-sybil-teal brightness-200 hover:text-red-500">
            {{ convertToMillionFormat(calcMargin(ccr[0], props.forwardlookings[0][2])) }}
          </p>
        </el-tooltip>
        <el-tooltip :disabled="tootltipDisabled" placement="bottom" :offset="30" content="Total next 12 months margin">
          <b class="font-bold mr-1 -mt-2 cursor-help text-center text-sybil-teal brightness-125 hover:text-red-500"
            >{{ convertToMillionFormat(calcMargin(ccr[1], props.forwardlookings[1][2])) }}
          </b>
        </el-tooltip>
        <el-tooltip :disabled="tootltipDisabled" placement="bottom" :offset="30" content="After 12 months margin">
          <p class="mr-1 text-sm -mt-3 cursor-help text-center text-sybil-teal brightness-200 hover:text-red-500">
            {{ convertToMillionFormat(calcMargin(ccr[2], props.forwardlookings[2][2])) }}
          </p>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DialogComponent from '@/components/Dialog/DialogComponent.vue';
import { useGlobalStore } from '@/stores/global';
import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { api } from '@/services/api';
import { usePortfolioStore } from '@/stores/portfolio';
import axios, { type AxiosResponse } from 'axios';
import errorHandler from '@/utilities/errorhandler';
import { openSuccessMsg } from '@/utilities/notificationMessage';

const globalStore = useGlobalStore();
const portfolioStore = usePortfolioStore();

const epiDataFile = ref();

const config = {
  headers: {
    'Content-Type': 'application/octet-stream',
  },
};

function uploadEPIFile(event) {
  epiDataFile.value = event.target.files[0];
}

async function uploadFile() {
  const epiCacheID = uuidv4();
  globalStore.isLoading = true;
  const requests = [
    api.post('storage/upload-cache', {
      bounce_id: portfolioStore.selectedBounceID,
      cache_path: epiCacheID + '.xlsx',
    }),
  ];

  await axios
    .all(requests)
    .then(
      axios.spread((epiRes: AxiosResponse) => {
        axios.put(epiRes.data.data.signed_url, epiDataFile.value, config);
      })
    )
    .then(() => {
      return api.post('portfolio/upload-epi', {
        bounce_id: portfolioStore.selectedBounceID,
        epi_data_cache_id: epiCacheID,
      });
    })
    .then(() => {
      globalStore.isLoading = false;
      portfolioStore.checkIfEPIExists();
      openSuccessMsg('EPI data uploaded successfully');
    })
    .catch((error) => {
      globalStore.isLoading = false;
      errorHandler(error);
    });
}
</script>
<template>
  <DialogComponent v-model="globalStore.isEPIUploadModal" title="Upload EPI Data">
    <div>
      <p class="text-red-500">Make sure the values in hierarchy and the hierarchy name matches with the data</p>
      <br />
      <p>Upload your EPI data in the following format:</p>
      <br />
      <div class="grid grid-flow-col gap-3 divide-x-4 text-center">
        <p>Month</p>
        <p>EPI</p>
        <p>Hierarchies n</p>
        <p>Hierarchies n+1</p>
      </div>

      <div class="mt-10 mb-6">
        <p>Data Upload:</p>
        <input
          type="file"
          class="mt-2"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="uploadEPIFile"
        />
      </div>
      <el-button class="absolute right-4 bottom-4" type="primary" :disabled="!epiDataFile" @click="uploadFile"
        >Upload Data</el-button
      >
    </div>
  </DialogComponent>
</template>

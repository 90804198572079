<script setup lang="ts">
import { ref } from 'vue';
import { usePortfolioStore } from '@/stores/portfolio';
import { openErrorMsg } from '@/utilities/notificationMessage';
import { useDashboardStore } from '@/stores/dashboard';

const props = defineProps({
  selectedBand: {
    type: String,
    required: true,
  },
});

const portfolioStore = usePortfolioStore();
const dashboardStore = useDashboardStore();

const currentUnit = ref('m');

function onChangeUnit() {
  if (currentUnit.value == 'm') {
    currentUnit.value = 'k';
  } else if (currentUnit.value == 'k') {
    currentUnit.value = 'b';
  } else {
    currentUnit.value = 'm';
  }
}

const bands = ref<number[]>([0]);

function addLimit() {
  // If limitinput is not number, return
  if (isNaN(parseFloat(limitInput.value)) || !isFinite(parseFloat(limitInput.value))) {
    return;
  }

  const lastBand = bands.value[bands.value.length - 1];
  // If limitInput is less than or equal to the last band, return
  if (lastBand !== null && parseFloat(limitInput.value) <= lastBand) {
    return;
  }

  bands.value.push(parseFloat(limitInput.value));
  limitInput.value = '';
}

const selectedLowerBoundComparison = ref('>');

function getUpperBounceComparison() {
  if (selectedLowerBoundComparison.value == '>=') {
    return '<';
  } else {
    return '<=';
  }
}

function removeLastLimit() {
  bands.value.pop();
}
const limitInput = ref('');

function changeComparison() {
  if (selectedLowerBoundComparison.value == '>') {
    selectedLowerBoundComparison.value = '>=';
  } else {
    selectedLowerBoundComparison.value = '>';
  }
}

function addingBands() {
  const oldParameters = structuredClone(portfolioStore.parameters);
  if (portfolioStore.parameters['bands'] == null) {
    portfolioStore.parameters['bands'] = {};
  }

  if (portfolioStore.parameters['bands'][props.selectedBand] == null) {
    portfolioStore.parameters['bands'][props.selectedBand] = [];
  }

  if (name.value == '') {
    openErrorMsg('Please enter a name');
    return;
  }
  // check if bands is empty
  if (bands.value.length == 1) {
    openErrorMsg('Please add at least one band');
    return;
  }

  // check if name exists in selectedBand
  if (portfolioStore.parameters['bands'][props.selectedBand].find((band) => band.name == name.value)) {
    openErrorMsg('Name already exists');
    return;
  }

  portfolioStore.parameters['bands'][props.selectedBand].push({
    name: name.value,
    selected_lower_bound_comparison: selectedLowerBoundComparison.value,
    band_range: bands.value,
    current_unit: currentUnit.value,
  });

  if (dashboardStore.riskProfileCustomBandsFilter[props.selectedBand] == null) {
    dashboardStore.riskProfileCustomBandsFilter[props.selectedBand] = [];
  }
  dashboardStore.riskProfileCustomBandsFilter[props.selectedBand].push(false);
  // dashboardStore.riskProfileCustomBandExpand[props.selectedBand].push(false);
  // dashboardStore.riskProfileCustomBandFilterSelection[props.selectedBand].push(false);

  console.log(dashboardStore.riskProfileCustomBandsFilter);
  dashboardStore.riskProfileFilterRenderKey += 1;

  portfolioStore.saveParameters(oldParameters, 'Bands Group Added');
}

const name = ref('');
</script>
<template>
  <div style="width: 680px">
    <div class="flex flex-row w-full items-center mb-5">
      <p>Name:</p>
      <el-input v-model="name" class="ml-3" style="width: 100px" />
    </div>
    <div class="mb-4 grid grid-cols-2 gap-x-3">
      <p class="border-r-4 border-sybil-teal">Lower Bound:</p>
      <p>Upper Bound:</p>
      <template v-for="(band, idx) in bands.slice(1)" :key="'band-' + idx">
        <p class="self-center border-r-4 border-sybil-teal">
          {{ selectedLowerBoundComparison }} {{ bands[idx] }} {{ currentUnit }} &nbsp;
        </p>
        <p class="self-center">{{ getUpperBounceComparison() }} {{ band }} {{ currentUnit }}</p>
      </template>
      <p class="self-center border-r-4 border-sybil-teal">
        {{ selectedLowerBoundComparison }} {{ bands[bands.length - 1] }} {{ currentUnit }} &nbsp;
      </p>
      <p class="self-center">
        {{ getUpperBounceComparison() }} <input v-model="limitInput" class="h-3 w-14" /> {{ currentUnit }}
      </p>
      <p class="self-center border-r-4 border-sybil-teal">
        {{ selectedLowerBoundComparison }} {{ limitInput }} {{ currentUnit }} &nbsp;
      </p>
    </div>
    <div class="mt-6">
      <el-button @click="onChangeUnit">Selected Unit: {{ currentUnit }}</el-button>
      <el-button @click="addLimit">Add Limit</el-button>
      <el-button :disabled="bands.length == 1" @click="removeLastLimit">Remove Last Limit</el-button>
      <el-button @click="changeComparison">{{
        selectedLowerBoundComparison == '>=' ? 'Greater Than Equal' : 'Smaller Than Equal'
      }}</el-button>
      <el-button type="primary" @click="addingBands">Confirm</el-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import moment from 'moment';
import { api } from '@/services/api';
import { useGlobalStore } from '@/stores/global';
import { usePortfolioStore } from '@/stores/portfolio';
import { useActuarialStore } from '@/stores/actuarial';

// defineProps(["visible"])
const portfolio_store = usePortfolioStore();
const global_store = useGlobalStore();

// const isShow = ref(true)
const visible = ref(true);
// const emit = defineEmits(['update:visible', 'change', 'terminateIBNR'])
// const store = useStore();
const MODE_UNDO = 'undo';
const MODE_REDO = 'redo';

const pageNumber = ref(1);
const PER_PAGE = 5;
const canUndo = ref(false);
const canRedo = ref(false);

const WORD_LIMIT = 100;
const WORD_PATTERN = /^[a-zA-Z0-9_?.!' ]*$/;

const isEdit = ref(-1);
const tempCommentInput = ref('');

async function onComment() {
  //     tempCommentInput.value = tempCommentInput.value.trim()
  //     if (tempCommentInput.value.length > 0) {
  //         if (checkWordValid()) {
  //             uploadComment()
  //         } else {
  //             openErrorMsg("Input invalid!")
  //         }
  //     } else {
  //         isEdit.value = -1
  //     }
}

function checkWordValid() {
  // if (tempCommentInput.value.length > WORD_LIMIT || !WORD_PATTERN.test(tempCommentInput.value)) {
  //     return false
  // } else {
  //     return true
  // }
}

async function uploadComment() {
  // loading.value = true
  // console.log(tempCommentInput.value)
  // await api
  //     .post(process.env.VUE_APP_API_BASE_URL + "audit_log/upload_comment", {
  //         message: tempCommentInput.value,
  //         page: pageNumber.value,
  //         per_page: PER_PAGE,
  //         index: isEdit.value
  //     })
  //     .then(() => {
  //         loading.value = false
  //         onReloadData()
  //         isEdit.value = -1
  //     })
  //     .catch(() => {
  //         openErrorMsg("Error! Please try again.")
  //         loading.value = false
  //         onReloadData()
  //         isEdit.value = -1
  //     });
}

function onEdit(scope) {
  // isEdit.value = scope.$index
  // tempCommentInput.value = ""
}

onMounted(() => {
  getData();
});

async function getData() {
  global_store.setLoading(true);
  await api
    .get(import.meta.env.VITE_API_URL + 'portfolio/audit-log', {
      params: {
        page: pageNumber.value,
        per_page: PER_PAGE,
        bounce_id: portfolio_store.selectedBounceID,
      },
    })
    .then((e) => {
      metadata.value = e.data.data.metadata;
      console.log(e.data);
      data.value = e.data.data.audit_data;
      canRedo.value = metadata.value.total_items - 1 != metadata.value.current_audit_log_version - 1;
      canUndo.value = metadata.value.current_audit_log_version - 1 > 0;
      // canUndo.value = false;
      sessionVersionNumber = 1;
      if (metadata.value.total_page > 0 && metadata.value.total_page < pageNumber.value) {
        pageNumber.value = metadata.value.total_page;
      } else {
        pageNumber.value = 1;
      }
      global_store.setLoading(false);
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        openErrorMsg(err.response.data.message);
      } else {
        openErrorMsg('Error');
      }
    });
}

// defineExpose({
//     onReloadData
// })

// const loading = ref(false)
async function onPageChange(e) {
  pageNumber.value = e;
  await getData();
}

async function onReloadData() {
  // loading.value = true
  // setTimeout(async () => {
  //     await getData()
  //     loading.value = false
  // })
}

const data = ref(null);
const metadata = ref({ total_page: 0, total_items: 0, current_audit_log_version: 0, page: 0, per_page: 0 });
// const isShow = ref(true)
let sessionVersionNumber = -1;

function openErrorMsg(msg) {
  //     ElMessage({
  //         message: msg,
  //         type: "error",
  //     });
}

// function getData() {
//     return new Promise((resolve, reject) => {
//         api
//             .get(process.env.VUE_APP_API_BASE_URL + "audit_log/actions_list", {
//                 params: {
//                     page: pageNumber.value,
//                     per_page: PER_PAGE
//                 }
//             })
//             .then((e) => {
//                 if(e.data['not_available']) {
//                     isShow.value = false
//                     resolve()
//                 } else {
//                     isShow.value = true
//                     metadata.value = e.data.metadata
//                     console.log(metadata.value)
//                     data.value = e.data.data
//                     canRedo.value = (metadata.value.total_items - 1 != metadata.value.current_data_version)
//                     canUndo.value = (metadata.value.current_data_version > 0)
//                     sessionVersionNumber = metadata.value.session_version_number
//                     if (metadata.value.total_page < pageNumber.value) {
//                         pageNumber.value = metadata.value.total_page
//                     }
//                     resolve()
//                 }
//             })
//             .catch((err) => {
//                 reject()
//                 openErrorMsg(err.response.data.message)
//             });
//     })
// }

const actuarial_store = useActuarialStore();

async function undo() {
  if (canUndo.value) {
    global_store.setLoading(true);
    await api
      .post(import.meta.env.VITE_API_URL + 'audit-log/undo', {
        bounce_id: portfolio_store.selectedBounceID,
      })
      .then(() => {
        getData();
        actuarial_store.setActuarialCacheResetRequired(true);
        global_store.resetAllViews().then(() => {
          global_store.setLoading(false);
        });
      })
      .catch((error) => {
        openErrorMsg('Error!');
        console.log(error);
        global_store.setLoading(false);
        throw 'Error!';
      });
  }
}

async function redo() {
  if (canRedo.value) {
    global_store.setLoading(true);
    await api
      .post(import.meta.env.VITE_API_URL + 'audit-log/redo', {
        bounce_id: portfolio_store.selectedBounceID,
      })
      .then(() => {
        getData();
        actuarial_store.setActuarialCacheResetRequired(true);
        global_store.resetAllViews().then(() => {
          global_store.setLoading(false);
        });
      })
      .catch((error) => {
        openErrorMsg('Error!');
        console.log(error);
        global_store.setLoading(false);
        throw 'Error!';
      });
  }
}

function onCloseModal() {
  //     emit('update:visible', false)
}

function handleClose() {
  global_store.setAuditLogModal(false);
}

function tableRowStyleName({ rowIndex }) {
  const offset = metadata.value.total_items - 1 - metadata.value.current_audit_log_version + 1;
  const curr = rowIndex + (metadata.value.page - 1) * metadata.value.per_page;
  if (offset == curr) {
    return { background: '#c6ffc4' };
  }
  return '';
}
</script>
<template>
  <div>
    <el-dialog
      v-if="data"
      title="Audit Log"
      :before-close="handleClose"
      :model-value="global_store.isAuditLogModal"
      width="700px"
    >
      <div>
        <div class="flex float-right mb-2">
          <el-tooltip content="Undo" placement="top" :show-after="1000">
            <img
              src="@/assets/images/undo.svg"
              width="25"
              class="mr-2"
              @click="undo"
              :class="canUndo ? 'cursor-pointer png-teal' : 'cursor-not-allowed png-gray'"
            />
          </el-tooltip>
          <el-tooltip content="Redo" placement="top" :show-after="1000">
            <img
              src="@/assets/images/undo.svg"
              width="25"
              class="-scale-x-100 mr-2"
              @click="redo"
              :class="canRedo ? 'cursor-pointer png-teal' : 'cursor-not-allowed png-gray'"
            />
          </el-tooltip>
        </div>
        <el-table :data="data" :border="true" style="width: 100%" :row-style="tableRowStyleName">
          <el-table-column prop="name" label="Name" width="180" />
          <el-table-column prop="description" label="Description" width="400" />
          <el-table-column prop="datetime" label="Date and Time" width="150">
            <template #default="scope">
              <div>
                {{ moment(scope.row.datetime).format('DD-MM-YYYY') }}
                <br />
                {{ moment(scope.row.datetime).format('hh:mm:ss A') }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="data"
          class="pagination"
          :page-size="1"
          :pager-count="PER_PAGE"
          @prev-click="onReloadData()"
          @next-click="onReloadData()"
          @current-change="onPageChange"
          layout="prev, pager, next"
          :total="metadata.total_page"
        />
        <el-pagination
          v-else
          disabled
          class="pagination"
          :page-size="1"
          :pager-count="PER_PAGE"
          layout="prev, pager, next"
          :total="1"
        />
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
}

.commentInput {
  width: 100%;
  padding: 0;
  height: 80%;
  line-height: 100%;
  margin: auto;
}
</style>

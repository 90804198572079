<script setup lang="ts">
import { RouterView } from 'vue-router';
import { useAuthenticationStore } from '@/stores/auth';
import { useGlobalStore } from '@/stores/global';
import SybilTopMenu from '@/components/SybilTopMenu.vue';
import SybilLeftMenu from '@/components/SybilLeftMenu.vue';
import LoaderComponent from '@/components/LoaderComponent.vue';
import CurrencyModal from './components/CurrencyModal.vue';
import { onUnmounted, ref, onMounted, onBeforeUnmount, watch } from 'vue';
import ActualVsExpected from '@/components/ActualVsExpected.vue';
import { usePortfolioStore } from '@/stores/portfolio';
import AuditLogModal from './components/AuditLogModal.vue';
import LargeThresholdMethodModal from '@/views/Parameters/LargeThresholdMethodModal.vue';
import SeasonalityModal from '@/views/Parameters/SeasonalityModal.vue';
import BounceModal from '@/views/Bounce/BounceModal.vue';
import TargetModal from '@/views/Target/TargetModal.vue';
import { io } from 'socket.io-client';
import { clientUpdateData, clientDoneUploadNewData, joinRoom } from '@/constants/socket';
import DataUploadModal from './views/DataUpload/DataUploadModal.vue';
import DialogComponent from './components/Dialog/DialogComponent.vue';
import EPIDataUploadModal from './views/DataUpload/EPIDataUploadModal.vue';

const portfolio_store = usePortfolioStore();
const auth_store = useAuthenticationStore();
const global_store = useGlobalStore();

// const socket = io(import.meta.env.VITE_API_SOCKET_URL);

const isDisconnected = ref(false);

// async function createSessionSocket() {
//   socket.on('connect', () => {
//     isDisconnected.value = !socket.connected;
//   });

//   socket.on('disconnect', () => {
//     reconnectSocket();
//   });

//   socket.on(clientUpdateData, async () => {
//     getSessionData();
//   });

//   socket.on(clientDoneUploadNewData, async (res) => {
//     console.log(res);
//     doneUploadNewData();
//   });
// }

// onUnmounted(() => {
//   socket.removeAllListeners();
//   socket.disconnect();
// });

function getSessionData() {
  console.log('Data updated');
}

function doneUploadNewData() {
  console.log('Data uploaded');
}

// async function reconnectSocket() {
//   // isDisconnected.value = false
//   socket.disconnect();
//   socket.connect();
//   establishSocketAndPolling();
//   isDisconnected.value = !socket.connected;
// }

// function establishSocketAndPolling() {
//   if (portfolio_store.selectedBounceID != '') {
//     socket.emit(joinRoom, portfolio_store.selectedBounceID);
//   }
// }

// watch(
//   () => portfolio_store.selectedBounceID,
//   () => {
//     establishSocketAndPolling();
//   }
// );

const preventBack = (event) => {
  history.go(1);
};

// createSessionSocket();

onMounted(() => {
  window.addEventListener('popstate', preventBack);
});

onBeforeUnmount(() => {
  window.removeEventListener('popstate', preventBack);
});
</script>

<template>
  <ActualVsExpected v-if="portfolio_store.isActualVsExpectedModal" />
  <SybilTopMenu v-if="auth_store.isAuthenticated" />
  <SybilLeftMenu v-if="auth_store.isAuthenticated" />
  <LoaderComponent v-if="global_store.isLoading" />
  <CurrencyModal v-if="global_store.isCurrencySelectionModal" />
  <AuditLogModal v-if="global_store.isAuditLogModal" />
  <LargeThresholdMethodModal v-if="global_store.isLargeThresholdMethodModal" />
  <SeasonalityModal v-if="global_store.isSeasonalityModal" />
  <BounceModal v-if="global_store.isBounceModal" />
  <TargetModal v-if="global_store.isTargetModal" />
  <DataUploadModal v-if="global_store.isDataUploadModal" />
  <EPIDataUploadModal v-if="global_store.isEPIUploadModal" />
  <div class="pl-16 pr-5 pb-5 pt-20 bg-gray-200 min-h-screen min-w-screen font-graphie">
    <RouterView :key="global_store.routerViewKey" />
  </div>
</template>

<script setup lang="ts">
import '@/assets/css/sidepanel.css';
import { useDashboardStore } from '@/stores/dashboard';
import { usePortfolioStore } from '@/stores/portfolio';
import { Plus, Minus, CaretRight, CaretBottom } from '@element-plus/icons-vue';
// import filterValueSorting from '@/utilities/filterValueSorting';
// import reverseSearch from '@/utilities/reverseSearch';
// import { CaretRight, CaretBottom } from '@element-plus/icons-vue';
import { onMounted, ref } from 'vue';

const emit = defineEmits(['resizeFilter', 'openModal', 'onDownloadExcel']);

function resizeFilter() {
  emit('resizeFilter');
}

const portfolioStore = usePortfolioStore();
const dashboardStore = useDashboardStore();

const beforeEnter = (el) => {
  el.style.height = '0';
};

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const leave = (el) => {
  el.style.height = '0';
};

// const isExpand = ref(portfolioStore.parameters['hierarchies'].map(() => false));
const props = defineProps<{ filtersWidth: string }>();
const riskProfileFilterExpand = ref<boolean[]>([]);

onMounted(() => {
  // RISK PROFILE DEFAULT EXPAND INDICATOR
  riskProfileFilterExpand.value = portfolioStore.parameters['hierarchies'].map(() => false);

  // RISK PROFILE DEFAULT FILTER
  for (const h of portfolioStore.parameters['hierarchies']) {
    dashboardStore.riskProfileFilterSelection[h] = [];
  }

  // RISK PROFILE CUSTOM BAND EXPAND INDICATOR
  // for (const h of portfolioStore.parameters['hierarchies']) {
  //   // eslint-disable-next-line
  //   for (const i of getCustomBands(h)) {
  //     if (dashboardStore.riskProfileCustomBandExpand[h] == null) {
  //       dashboardStore.riskProfileCustomBandExpand[h] = [];
  //       dashboardStore.riskProfileCustomBandFilterSelection[h] = [];
  //     }

  //     dashboardStore.riskProfileCustomBandExpand[h].push(false);
  //   }
  // }

  if (dashboardStore.riskProfileFilter.length === 0) {
    dashboardStore.riskProfileFilter = portfolioStore.parameters['hierarchies'].map(() => false);

    for (const h of portfolioStore.parameters['hierarchies']) {
      // eslint-disable-next-line
      for (const i of getCustomBands(h)) {
        if (dashboardStore.riskProfileCustomBandsFilter[h] == null) {
          dashboardStore.riskProfileCustomBandsFilter[h] = [];
        }

        dashboardStore.riskProfileCustomBandsFilter[h].push(false);
      }
    }

    console.log(dashboardStore.riskProfileCustomBandsFilter);
  }
});

// Key is the hierarchy, value is an array of the values {name: 'value', checked: false}
// const filters = ref<Record<string, Array<{ name: string; checked: boolean; key: string }>>>({});

async function changeCheckbox(val, hierarchy) {
  if (val == true) {
    dashboardStore.selectedRisk.add(hierarchy);
    dashboardStore.fetchAllRiskProfileTables();
  } else {
    dashboardStore.selectedRisk.delete(hierarchy);
    for (let i = 0; i < dashboardStore.riskProfileNumberOfTables; i++) {
      delete dashboardStore.riskProfileFilterData[i][hierarchy];
    }
  }
}

function isHierarchyBand(hierarchy) {
  if (hierarchy.split(' ').pop() === 'Band') {
    return true;
  }
  return false;
}

function onRecreateBand(hierarchy) {
  emit('openModal', hierarchy);
}

// function onDownloadExcel() {
//   emit('onDownloadExcel');
// }

function onAddingNewTables() {
  dashboardStore.riskProfileNumberOfTables += 1;
  dashboardStore.riskProfileDateSelection.push([null, null]);
  dashboardStore.riskProfileFilterData.push({});
  dashboardStore.fetchAllRiskProfileTables();
}

// function selectFilter(hierarchy, item) {
//   if (dashboardStore.riskProfileFilterSelection[hierarchy].includes(item)) {
//     dashboardStore.riskProfileFilterSelection[hierarchy] = dashboardStore.riskProfileFilterSelection[hierarchy].filter(
//       (x) => x !== item
//     );
//   } else {
//     dashboardStore.riskProfileFilterSelection[hierarchy].push(item);
//   }

//   dashboardStore.fetchAllRiskProfileTables();
// }
function onSelectCustomBands(val, hierarchy, f) {
  // portfolioStore.parameters['bands'][hierarchy][bandIdx];
  if (val == true) {
    dashboardStore.fetchAllRiskProfileTables();
  } else {
    for (let i = 0; i < dashboardStore.riskProfileNumberOfTables; i++) {
      console.log(hierarchy + ' - ' + f['name']);
      console.log(dashboardStore.riskProfileFilterData[i]);
      delete dashboardStore.riskProfileFilterData[i][hierarchy + '-' + f['name']];
    }
  }
}

function getCustomBands(selectedBand) {
  if (portfolioStore.parameters['bands'] == null || portfolioStore.parameters['bands'][selectedBand] == null) {
    return [];
  }

  return portfolioStore.parameters['bands'][selectedBand];
}

function deleteBands(hierarchy, name) {
  console.log(portfolioStore.parameters['bands']);
  console.log(hierarchy, name);
  const oldParameters = structuredClone(portfolioStore.parameters);

  const bandIndex = portfolioStore.parameters['bands'][hierarchy].findIndex((band) => band.name == name);

  for (let i = 0; i < dashboardStore.riskProfileNumberOfTables; i++) {
    delete dashboardStore.riskProfileFilterData[i][hierarchy + '-' + name];
  }
  portfolioStore.parameters['bands'][hierarchy].splice(bandIndex, 1);

  dashboardStore.riskProfileCustomBandsFilter[hierarchy].splice(bandIndex, 1);
  dashboardStore.riskProfileFilterRenderKey += 1;

  portfolioStore.saveParameters(oldParameters, 'Bands Group Removed');
}
</script>
<template>
  <div :key="dashboardStore.riskProfileFilterRenderKey" class="sidenav main-sidebars shadow">
    <div class="flex flex-row place-items-center sticky top-0 bg-white py-2 z-20">
      <!-- <el-button
        v-if="Object.keys(dashboardStore.riskProfileFilterData).length != 0"
        key="primary"
        class="w-5 ml-2"
        type="primary"
        @click="onDownloadExcel"
      >
        <el-icon><Download /></el-icon>
      </el-button> -->

      <el-button
        v-if="props.filtersWidth === '300px'"
        key="primary"
        class="w-5 ml-2"
        type="primary"
        @click="onAddingNewTables"
      >
        <el-icon><Plus /></el-icon>
      </el-button>
      <div v-if="props.filtersWidth === '300px'" class="h-8 mr-10 ml-2"></div>
      <i
        class="pi pi-arrows-h ml-2 cursor-pointer absolute top-4 right-2"
        style="font-size: 20px"
        @click="resizeFilter"
      ></i>
    </div>
    <template v-if="props.filtersWidth === '300px'">
      <template v-for="(hierarchy, idx) in portfolioStore.parameters['hierarchies']" :key="idx + '-risk-profile'">
        <div class="w-full side-panel-title flex flex-row pl-2">
          <div
            v-if="isHierarchyBand(hierarchy)"
            class="mt-1 h-8 w-8 cursor-pointer"
            @click="riskProfileFilterExpand[idx] = !riskProfileFilterExpand[idx]"
          >
            <el-icon>
              <CaretRight v-if="!riskProfileFilterExpand[idx]" class="text-gray-400 text-sm mt-3" />
              <CaretBottom v-if="riskProfileFilterExpand[idx]" class="text-gray-400 text-sm mt-3" />
            </el-icon>
          </div>

          <template v-if="isHierarchyBand(hierarchy)">
            <div
              class="w-full pt-1 el-checkbox__label cursor-pointer -ml-2 overflow-hidden text-ellipsis hover:overflow-visible hover:z-10"
              :value="hierarchy"
            >
              <label class="el-checkbox w-full" @click="riskProfileFilterExpand[idx] = !riskProfileFilterExpand[idx]">
                <span class="el-checkbox__label w-full">
                  {{ hierarchy }}
                </span>
              </label>
            </div>
          </template>
          <template v-else>
            <el-checkbox
              v-model="dashboardStore.riskProfileFilter[idx]"
              class="pt-2 cursor-pointer ml-1 w-64 overflow-hidden text-ellipsis hover:overflow-visible hover:bg-inherit hover:z-10"
              :value="hierarchy"
              @change="(e) => changeCheckbox(e, hierarchy)"
            >
              {{ hierarchy }}
            </el-checkbox>
          </template>
          <div v-if="isHierarchyBand(hierarchy)" class="flex items-center justify-center">
            <el-button
              v-if="props.filtersWidth === '300px' && portfolioStore.getExposureLength() > 0"
              key="primary"
              class="w-2 h-3 mr-2"
              plain
              size="small"
              @click="onRecreateBand(hierarchy)"
            >
              <el-icon><Plus /></el-icon>
            </el-button>
          </div>
        </div>
        <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
          <div v-if="riskProfileFilterExpand[idx]" class="chunk-group-elements">
            <el-radio-group class="chunk-group-elements">
              <div>
                <el-checkbox
                  v-model="dashboardStore.riskProfileFilter[idx]"
                  class="pt-2 cursor-pointer ml-4 w-64 overflow-hidden text-ellipsis hover:overflow-visible hover:bg-inherit hover:z-10"
                  @change="(e) => changeCheckbox(e, hierarchy)"
                  >Default</el-checkbox
                >
                <el-checkbox
                  v-for="(f, bandIdx) in getCustomBands(hierarchy)"
                  :key="f['name'] + '-custom-bands'"
                  v-model="dashboardStore.riskProfileCustomBandsFilter[hierarchy][bandIdx]"
                  class="py-2 cursor-pointer ml-4 w-64 overflow-hidden text-ellipsis hover:overflow-visible hover:bg-inherit hover:z-10 relative"
                  @change="(e) => onSelectCustomBands(e, hierarchy, f)"
                  >{{ f['name'] }}
                  <el-button
                    v-if="props.filtersWidth === '300px'"
                    key="primary"
                    class="w-2 h-3 -mt-1 mr-2 scale-75 absolute -right-2"
                    plain
                    type="warning"
                    size="small"
                    @click="deleteBands(hierarchy, f['name'])"
                  >
                    <el-icon><Minus /></el-icon>
                  </el-button>
                </el-checkbox>
              </div>
            </el-radio-group>
          </div>
        </transition>
        <!-- <template
          v-if="dashboardStore.riskProfileCustomBandsFilter && dashboardStore.riskProfileCustomBandsFilter[hierarchy]"
        >
          <div
            v-for="(f, bandIdx) in getCustomBands(hierarchy)"
            :key="f['name'] + '-custom-bands'"
            class="side-panel-title"
          >
            <el-icon
              class="pt-3 cursor-pointer ml-1"
              @click="
                dashboardStore.riskProfileCustomBandExpand[hierarchy][bandIdx] =
                  !dashboardStore.riskProfileCustomBandExpand[hierarchy][bandIdx]
              "
            >
              <CaretRight
                v-if="!dashboardStore.riskProfileCustomBandExpand[hierarchy][bandIdx]"
                class="text-gray-400 text-sm"
              />
              <CaretBottom
                v-if="dashboardStore.riskProfileCustomBandExpand[hierarchy][bandIdx]"
                class="text-gray-400 text-sm"
              />
            </el-icon>
            <el-checkbox
              v-model="dashboardStore.riskProfileCustomBandsFilter[hierarchy][bandIdx]"
              class="pt-2 cursor-pointer ml-1"
              :value="hierarchy"
              >{{ hierarchy }} - {{ f['name'] }}</el-checkbox
            >
            <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
              <div v-if="dashboardStore.riskProfileCustomBandExpand[hierarchy][bandIdx]" class="chunk-group-elements">
                <el-checkbox
                  v-for="(item, idx_dictionary) in filterValueSorting(
                    hierarchy,
                    Object.values(portfolioStore.dictionary[hierarchy])
                  )"
                  :key="item"
                  :model-value="dashboardStore.riskProfileCustomBandExpand[hierarchy][bandIdx]"
                  :value="idx_dictionary"
                  size="large"
                  class="hover:bg-gray-200 px-3 w-full"
                  @change="selectFilter(hierarchy, item)"
                >
                  {{ item }}
                </el-checkbox>
              </div>
            </transition>
          </div>
        </template> -->
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeMount, ref, computed, nextTick } from 'vue';
import { useUnderwritingStore } from '@/stores/underwriting';
import { usePortfolioStore } from '@/stores/portfolio';
import { useGlobalStore } from '@/stores/global';
import { useActuarialStore } from '@/stores/actuarial';
import { useDashboardStore } from '@/stores/dashboard';
import { api } from '@/services/api';
import moment from 'moment';
import { openSuccessMsg } from '@/utilities/notificationMessage';
import errorHandler from '@/utilities/errorhandler';
const underwriting_store = useUnderwritingStore();
const portfolio_store = usePortfolioStore();
const global_store = useGlobalStore();
const actuarial_store = useActuarialStore();

const controllerData: any = ref({});

function percentageFormat(val: number) {
  return (val * 100).toFixed(1) + '%';
}

const applyRate = ref(portfolio_store.parameters['claims_nature'].slice(1).map((x) => false));

async function submitUnderwritingController() {
  // console.log("jwij")
  underwriting_store.updateIsUnderwritingMultipleChangeModal(false);
  global_store.setLoading(true);
  // console.log(Object.keys(underwriting_store.valueArrayColumns).reduce((acc, key) => {
  //   acc[key] = null;
  //   return acc;
  // }, {}))

  let api_changes_body = {};
  const to_be_parsed = [
    'GWP',
    'Attritional_LR',
    'HIST_ACTIONS',
    'HIST_ACTIONS_CREDITS',
    'INDEXES',
    'EXITS',
    'ELASTICITY',
    'MANUAL_PERTURBATION',
    'ALGO_PERTURBATION',
  ];
  const changes_original_name = [
    'GWP',
    'Attritional A-priori',
    'Rate Change',
    'Credit',
    'Indexation',
    'Exits',
    'Elasticity',
    'Manual Perturbation',
    'Algorithmic Perturbation',
  ];
  // Convert changes to api body

  for (let i = 0; i < to_be_parsed.length; i++) {
    if (changes.value[changes_original_name[i]] != null) {
      api_changes_body[to_be_parsed[i]] = changes.value[changes_original_name[i]];
    }
  }

  for (const i of portfolio_store.parameters['line_size']) {
    if (changes.value[i] != null) {
      api_changes_body[i] = changes.value[i];
    }
  }

  for (const i of Object.keys(additionalChanges.value['Commission'])) {
    api_changes_body[i] = additionalChanges.value['Commission'][i];
  }

  for (const i of Object.keys(additionalChanges.value['Loads'])) {
    api_changes_body[i] = additionalChanges.value['Loads'][i];
  }

  const a = {
    bounce_id: portfolio_store.selectedBounceID,
    filters: underwriting_store.underwritingControllerDate['filters'],
    underwriting_month: underwriting_store.underwritingControllerDate['underwriting_month'],
    claims_nature: portfolio_store.parameters['claims_nature'],
    commission: portfolio_store.parameters['Commission'],
    changes: changes.value,
  };
  console.log(a);
  await api
    .post(import.meta.env.VITE_API_URL + 'underwriting/submit-table-controller', {
      bounce_id: portfolio_store.selectedBounceID,
      filters: underwriting_store.underwritingControllerDate['filters'],
      underwriting_month: underwriting_store.underwritingControllerDate['underwriting_month'],
      changes: api_changes_body,
      selectedLineSize: portfolio_store.selectedLineSize,
    })
    .then(async (res) => {
      global_store.setLoading(false);
      openSuccessMsg('Changes saved successfully!');
      actuarial_store.createDraft(res);
      actuarial_store.setActuarialCacheResetRequired(true);
      return Promise.all([
        underwriting_store.fetchUnderwritingTable(),
        portfolio_store.onResetForwardLooking(true),
        portfolio_store.getSignedOff(),
      ]);
    })
    .then(() => {
      global_store.setLoading(false);
    })
    .catch((error) => {
      // openErrorMsg("Error!")
      errorHandler(error);
      portfolio_store.getSignedOff();
      global_store.setLoading(false);
      global_store.onConnectionError();
    });
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const dashboard_store = useDashboardStore();

const changes = ref(
  Object.keys(underwriting_store.valueArrayColumns).reduce((acc, key) => {
    acc[key] = null;
    return acc;
  }, {})
);

const additionalChanges = ref({ Commission: {}, Loads: {}, CP: {} });

const anyChange = computed(() => {
  return !Object.values(changes.value).every((value) => value === null);
});

onBeforeMount(async () => {
  // const data = await fetchTableController();
  // console.log(data);
  // if (data != undefined) {
  //   originalControllerData.value = JSON.parse(JSON.stringify(data));
  //   controllerData.value = JSON.parse(JSON.stringify(data));
  // }
  // applyRate.value = portfolio_store.parameters['claims_nature'].slice(1).map((x) => false);
});

const dynamicDialogDataCache = ref({});

const FormattingIndex = ref({ GWP: 0 });
const totalModal = ref(true);

function keyupEnter(e: any) {
  e.target.blur();
}

function keyUpInput(e: any) {
  tempInput.value = e.target.value;
}

function onFocusController(e: any) {
  e.target.select();
}

const selectedGWPUnit = computed<string>(() => global_store.currency.currencyFormat.selectedGWPUnit);
const GWPUnits: any = computed(() => global_store.currency.GWPUnits);

function GWPFormat(x: number) {
  return (x / parseFloat(GWPUnits.value[selectedGWPUnit.value][0])).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const amountType = computed(
  () =>
    global_store.currency.currencyFormat['currency'] +
    global_store.currency.GWPUnits[global_store.currency.currencyFormat['selectedGWPUnit']][1]
);

const isActive = ref({ GWP: false });
const isChanged = ref({ GWP: false });
const isChangedPrev = ref({ GWP: false });
const changedClassPrev = ref({ GWP: false });
const changedClass = ref({ GWP: false });

const tempInput = ref('');

const isEdit = ref({
  RateCredit: {
    isEditing: false,
    value: 100,
  },
  Elasticity: {
    isEditing: false,
    value: 100,
  },
  LineSize: {
    isEditing: false,
    value: 100,
  },
  RateChange: {
    isEditing: false,
    value: 100,
  },
  CP: {
    isEditing: false,
    value: 100,
  },
  Index: {
    isEditing: false,
    value: 100,
  },
  PP: {
    isEditing: false,
    value: 100,
  },
  GWP: {
    isEditing: false,
    value: 100,
  },
  Att: {
    isEditing: false,
    value: 100,
  },
  Loads: {
    isEditing: false,
    value: 100,
  },
  CombineLoads: {
    isEditing: false,
    value: 100,
  },
  CCR: {
    isEditing: false,
    value: 100,
  },
});

function onUnderwritingMultipleChangeModal() {
  underwriting_store.updateIsUnderwritingMultipleChangeModal(false);
}

function onClickRecord(rec) {
  alert(JSON.stringify(rec));
}

const records = ref(null);

onMounted(() => {
  records.value = [
    {
      personid: 1,
      fname: 'Aaliyah',
      lname: 'Jackson',
      email: 'aaliyah_jackson@example.com',
      birthday: '1989-12-08T00:00:00.000Z',
    },
    {
      personid: 1,
      fname: 'Aaliyah',
      lname: 'Jackson',
      email: 'aaliyah_jackson@example.com',
      birthday: '1989-12-08T00:00:00.000Z',
    },
  ];
  nextTick(() => {
    cgridRender.value = true;
  });
});

const columnStyle = {
  textAlign: 'center',
  font: '12px graphie',
  bgColor: '#f9f9f9',
};

const headerStyle = {
  textAlign: 'center',
  font: '12px graphie',
  bgColor: '#f9f9f9',
};

const cgridRender = ref(false);
</script>
<template>
  <el-dialog
    width="950px"
    style="min-height: 500px"
    :model-value="underwriting_store.isUnderwritingMultipleChangeModal"
    :before-close="onUnderwritingMultipleChangeModal"
    :show-close="true"
  >
    <template #header>
      <span class="dialog-header"> Apply multiple changes to model </span>
    </template>
    <div>{{ underwriting_store.underwritingMultipleChangeDesc }}</div>

    <div style="height: 500px; border: solid 1px #ddd">
      <c-grid v-if="records && cgridRender" :data="records" :frozen-col-count="1">
        <c-grid-input-column field="personid" width="50%" :column-style="columnStyle" :header-style="headerStyle">
          Person ID
        </c-grid-input-column>
        <c-grid-input-column field="lname" width="50%" :column-style="columnStyle" :header-style="headerStyle">
          Last Name
        </c-grid-input-column>
      </c-grid>
    </div>

    <div class="grid-sample"></div>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="default" @click="underwriting_store.updateIsUnderwritingMultipleChangeModal(false)"
          >Cancel</el-button
        >
        <el-button type="primary" @click="submitUnderwritingController()">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<style scoped></style>

<script setup lang="ts">
import { usePortfolioStore } from '@/stores/portfolio';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import moment from 'moment';
import { onMounted, watch } from 'vue';

let root: am5.Root | null = null;

const props = defineProps<{ data: { [key: string]: never }[] }>();
const portfolioStore = usePortfolioStore();

watch(
  () => props.data,
  () => {
    createChart();
  }
);

onMounted(() => {
  createChart();
});

function createChart() {
  if (root) {
    root.dispose();
  }

  root = am5.Root.new('chartdiv');
  root.setThemes([am5themes_Animated.new(root)]);

  const momentCurrentMonth = moment(portfolioStore.current_month, 'YYYY-MM-DD');

  const data = props.data.map((item) => {
    const momentMonth = moment(item['UNDERWRITING_MONTH'], 'YYYY/MM/DD');

    if (momentMonth.isAfter(momentCurrentMonth)) {
      if (item['GWP_CUM']) {
        item['GWP_CUM'] = null;
      }
    }
    return item;
  });

  let chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      layout: root.verticalLayout,
    })
  );

  let xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      categoryField: 'UNDERWRITING_MONTH',
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 40,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
      }),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  xAxis.get('renderer').labels.template.setAll({
    rotation: -90, // Rotates the labels to 0 degrees (horizontal)
    centerY: am5.p50, // Aligns vertically to center
    centerX: am5.p50, // Aligns horizontally to center
  });

  xAxis.data.setAll(data);

  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      tooltip: am5.Tooltip.new(root, {}),
      numberFormat: '#.0a',
    })
  );

  chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'zoomX',
    })
  );

  let seriesCummulativeGWP = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      name: 'Cummulative GWP',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'GWP_CUM',
      categoryXField: 'UNDERWRITING_MONTH',
      fill: am5.color(0xb5e8d5),
      stroke: am5.color(0xb5e8d5),
    })
  );

  seriesCummulativeGWP.data.setAll(data);

  let seriesCummulativeEPI = chart.series.push(
    am5xy.LineSeries.new(root, {
      name: 'Cummulative EPI',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'EPI_CUM',
      categoryXField: 'UNDERWRITING_MONTH',
      legendValueText: "[bold #a00]{valueY.formatNumber('#.0p')}",
      stroke: am5.color(0xff0000),
      snapTooltip: true,
    })
  );

  seriesCummulativeEPI.strokes.template.setAll({
    strokeWidth: 3,
  });

  seriesCummulativeEPI.bullets.push(function () {
    if (!root) return;
    return am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        stroke: am5.color(0xff0000),
        strokeWidth: 2,
        fill: root.interfaceColors.get('background'),
        radius: 3,
      }),
    });
  });

  seriesCummulativeEPI.data.setAll(data);

  let seriesCummulativeLastYear = chart.series.push(
    am5xy.LineSeries.new(root, {
      name: 'Cummulative Last Year GWP',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'GWP_PREV_YEAR_CUM',
      categoryXField: 'UNDERWRITING_MONTH',
      stroke: am5.color(0x534fc6),
      fill: am5.color(0xffffff),
      tooltip: am5.Tooltip.new(root, {}),
      snapTooltip: true,
    })
  );

  seriesCummulativeLastYear.bullets.push(function () {
    if (!root) return;
    return am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        stroke: am5.color(0x534fc6),
        strokeWidth: 2,
        fill: root.interfaceColors.get('background'),
        radius: 3,
      }),
    });
  });

  seriesCummulativeLastYear.strokes.template.setAll({
    strokeWidth: 3,
  });

  seriesCummulativeLastYear.get('tooltip')?.label.adapters.add('text', function () {
    let customText = '{categoryX}[/]\n';

    chart.series.each(function (item) {
      customText +=
        '[' +
        (item.get('stroke')?.toString() || '') +
        ']●[/] ' +
        item.get('name') +
        ': [b]{' +
        item.get('valueYField') +
        ".formatNumber('#.0a')}[/]\n";
    });

    return customText;
  });

  seriesCummulativeLastYear.data.setAll(data);

  let legend = chart.children.push(
    am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: am5.GridLayout.new(root, {
        maxColumns: 3,
        fixedWidthGrid: true,
      }),
    })
  );
  legend.data.setAll(chart.series.values);
}
</script>
<template>
  <div class="pt-2">
    <h1 class="ml-3">EPI VS GWP</h1>
    <div id="chartdiv" class="w-full" :style="{ height: 'calc(100% - 100px)' }"></div>
  </div>
</template>

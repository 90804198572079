<script setup lang="ts">
import { api } from '@/services/api';
import { usePortfolioStore } from '@/stores/portfolio';
import errorHandler from '@/utilities/errorhandler';
import { onMounted, ref } from 'vue';
import { numberWithCommas } from '@/utilities/numberFormatter';
import EPIFilter from './EPIFilter.vue';
import { useDashboardStore } from '@/stores/dashboard';
import { useGlobalStore } from '@/stores/global';
import EPIChart from './EPIChart.vue';
import moment from 'moment';

const portfolioStore = usePortfolioStore();
const dashboardStore = useDashboardStore();
const globalStore = useGlobalStore();

function resizeFilter() {
  const numericValue = parseInt(filtersWidth.value, 10); // Extract numeric value from the string
  filtersWidth.value = `${350 - numericValue}px`; // Calculate and append 'px' back
}

const ucc = ref([]);
const filtersWidth = ref('300px');

onMounted(async () => {
  await dashboardStore.fetchEPIFilters();
  await getEPIData();
});

function getFilters() {
  const filters = {};

  for (const hierarchy of dashboardStore.epiFiltersHierarchy) {
    filters[hierarchy] = [];
    if (dashboardStore.epiFiltersSelected[hierarchy].some((filter) => filter)) {
      for (const index in dashboardStore.epiFiltersSelected[hierarchy]) {
        if (dashboardStore.epiFiltersSelected[hierarchy][index]) {
          filters[hierarchy].push(dashboardStore.epiFilters[hierarchy][index]);
        }
      }
    } else {
      // Use all filters if none are selected
      for (const index in dashboardStore.epiFiltersSelected[hierarchy]) {
        filters[hierarchy].push(dashboardStore.epiFilters[hierarchy][index]);
      }
    }
  }

  return filters;
}

async function getEPIData() {
  globalStore.isLoading = true;

  getFilters();
  await api
    .post('portfolio/epi-dashboard', {
      bounce_id: portfolioStore.selectedBounceID,
      filters: getFilters(),
    })
    .then((response) => {
      ucc.value = response.data.data.ucc;
      globalStore.isLoading = false;
    })
    .catch((error) => {
      errorHandler(error);
      globalStore.isLoading = false;
    });
}

function getGWP(key, name) {
  const momentMonth = moment(key['UNDERWRITING_MONTH'], 'YYYY/MM/DD');
  const momentCurrentMonth = moment(portfolioStore.current_month, 'YYYY-MM-DD');
  if (momentMonth.isAfter(momentCurrentMonth)) {
    return '';
  }
  return numberWithCommas(key[name], false);
}
</script>
<template>
  <div>
    <div style="width: 1500px" class="bg-gray-200">
      <div v-if="ucc.length > 0">
        <EPIChart
          :data="ucc"
          :style="{
            height: '600px',
            width: '1050px',
            transition: '0.5s ease-out all',
            position: 'sticky',
          }"
          class="bg-white mt-4"
        />
      </div>
      <table class="bg-white shadow mt-10" style="border-spacing: 0">
        <thead>
          <tr>
            <th class="fixWidth header-teal text-black">Underwriting Month</th>
            <th class="fixWidth header-teal text-black">EPI</th>
            <th class="fixWidth header-teal text-black">Cumulative EPI</th>
            <th class="fixWidth header-teal text-black">GWP</th>
            <th class="fixWidth header-teal text-black">Cumulative GWP</th>
            <th class="fixWidth header-teal text-black">Last Year GWP</th>
            <th class="fixWidth header-teal text-black">Cumulative Last year</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="key in ucc" :key="key['UNDERWRITING_MONTH']">
            <td class="relative fixWidth whitespace-nowrap overflow-hidden hover:overflow-visible bg-sybil-light-teal">
              <span class="bg-sybil-light-teal">
                {{ key['UNDERWRITING_MONTH'] }}
              </span>
            </td>
            <td class="fixWidth">{{ numberWithCommas(key['EPI'], false) }}</td>
            <td class="fixWidth">{{ numberWithCommas(key['EPI_CUM'], false) }}</td>
            <td class="fixWidth">{{ getGWP(key, 'GWP') }}</td>
            <td class="fixWidth">{{ getGWP(key, 'GWP_CUM') }}</td>
            <td class="fixWidth">{{ numberWithCommas(key['GWP_PREV_YEAR'], false) }}</td>
            <td class="fixWidth">{{ numberWithCommas(key['GWP_PREV_YEAR_CUM'], false) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="absolute bg-white top-20 right-0 z-50" style="margin-top: -15px">
      <div
        :style="{
          width: filtersWidth,
          transition: '0.5s ease-out all',
        }"
      >
        <EPIFilter
          style="width: inherit"
          :filters-width="filtersWidth"
          @get-epi="getEPIData()"
          @resize-filter="resizeFilter()"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.table-panel {
  margin-left: 2px;
  border-collapse: separate;
}

.fixWidth {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01em solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}

.fixWidth2 {
  width: 900px;
  min-width: 900px;
  max-width: 900px;
}
</style>

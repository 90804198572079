<script setup lang="ts">
import DialogComponent from '@/components/Dialog/DialogComponent.vue';
import { api } from '@/services/api';
import { useGlobalStore } from '@/stores/global';
import errorHandler from '@/utilities/errorhandler';
import { computed, onMounted, ref } from 'vue';

const props = defineProps<{ modelValue: boolean; bounceId: string }>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const uwComments = ref('');
const actComments = ref('');
const claimsComments = ref('');

const globalStore = useGlobalStore();

onMounted(async () => {
  globalStore.setLoading(true);
  await api
    .get(import.meta.env.VITE_API_URL + 'portfolio/bounce-comments', {
      params: {
        bounce_id: props.bounceId,
      },
    })
    .then((res) => {
      globalStore.setLoading(false);
      res.data.data.comments.uw_module_comments
        ? (uwComments.value = res.data.data.comments.uw_module_comments)
        : (uwComments.value = '');
      res.data.data.comments.act_module_comments
        ? (actComments.value = res.data.data.comments.act_module_comments)
        : (actComments.value = '');
      res.data.data.comments.claims_module_comments
        ? (claimsComments.value = res.data.data.comments.claims_module_comments)
        : (claimsComments.value = '');
    })
    .catch((error) => {
      globalStore.setLoading(false);
      console.log(error);
      errorHandler(error);
    });
});

const model = computed({
  get: () => props.modelValue,
  set: (val: boolean) => emit('update:modelValue', val),
});
</script>
<template>
  <DialogComponent v-model="model" :title="'Bounce Comments'">
    <div class="flex flex-col" style="width: 900px">
      <label class="col-form-label">Underwriting module comments:</label>
      <textarea v-model="uwComments" class="bounce-textarea" :disabled="true"></textarea>
      <label class="col-form-label">Actuarial module comments:</label>
      <textarea v-model="actComments" class="bounce-textarea" :disabled="true"></textarea>
      <label class="col-form-label">Other comments:</label>
      <textarea v-model="claimsComments" class="bounce-textarea" :disabled="true"></textarea>
    </div>
  </DialogComponent>
</template>

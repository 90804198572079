<script setup lang="ts">
import { useDashboardStore } from '@/stores/dashboard';
import { usePortfolioStore } from '@/stores/portfolio';
import { safeDivide } from '@/utilities/calculationHelper';
import { numberWithCommas, decimalToPercentage } from '@/utilities/numberFormatter';
import filterValueSorting from '@/utilities/filterValueSorting';
import { Minus } from '@element-plus/icons-vue';
import { onMounted } from 'vue';
import moment from 'moment';

const dashboardStore = useDashboardStore();
const portfolioStore = usePortfolioStore();

function isPolicyCountInTheData(key) {
  return 'exposure.sum.Policy Count' in dashboardStore.riskProfileFilterData[props.idx - 1][key];
}
function convertNameToKey(key, data) {
  return dashboardStore.riskProfileFilterData[props.idx - 1][key]['values_mapping'][data];
}

const props = defineProps<{ idx: number }>();

function removeTables() {
  dashboardStore.riskProfileNumberOfTables -= 1;
  dashboardStore.riskProfileDateSelection.splice(props.idx - 1, 1);
  dashboardStore.riskProfileFilterData.splice(props.idx - 1, 1);
}

onMounted(() => {
  if (
    dashboardStore.riskProfileDateSelection[props.idx - 1][1] == null ||
    dashboardStore.riskProfileDateSelection[props.idx - 1][0] == null
  ) {
    dashboardStore.riskProfileDateSelection[props.idx - 1][0] = moment(
      portfolioStore.all_uw_dates['month'][0],
      'MMM-YYYY'
    ).toDate();
    dashboardStore.riskProfileDateSelection[props.idx - 1][1] = new Date(portfolioStore.current_month);
  }
});

function disabledDate(time) {
  return !(
    time.getTime() >= moment(portfolioStore.all_uw_dates['month'][0], 'MMM-YYYY').toDate() &&
    time.getTime() <= new Date(portfolioStore.current_month)
  );
}

// disabled - date;

function onChangeDate() {
  dashboardStore.fetchAllRiskProfileTables();
}
</script>
<template>
  <div
    v-if="Object.keys(dashboardStore.riskProfileFilterData[idx - 1]).length > 0"
    class="h-[calc(100vh-117px)] relative"
  >
    <div class="min-h-[calc(100vh-117px)] relative px-4" :class="props.idx % 2 === 0 ? 'bg-gray-300' : 'bg-gray-200'">
      <div
        class="flex flex-row sticky top-0 z-30 fixWidth2 px-4 pb-1 pt-2"
        :class="props.idx % 2 === 0 ? 'bg-gray-300' : 'bg-gray-200'"
      >
        <h1 class="mt-1 text-nowrap">Underwriting Months from</h1>
        <div class="ml-3 flex flex-row relative">
          <el-date-picker
            v-model="dashboardStore.riskProfileDateSelection[props.idx - 1][0]"
            type="month"
            placeholder="Starting month"
            size="small"
            style="width: 155px; height: 33px"
            :disabled-date="disabledDate"
            :clearable="false"
            @change="onChangeDate"
          />
          <h1 class="mt-1 ml-2 text-nowrap">to</h1>
          <el-date-picker
            v-model="dashboardStore.riskProfileDateSelection[props.idx - 1][1]"
            type="month"
            placeholder="Ending month"
            size="small"
            style="width: 155px; height: 33px"
            :clearable="false"
            :disabled-date="disabledDate"
            class="ml-3"
            @change="onChangeDate"
          />
        </div>
        <el-button
          v-if="props.idx != 1"
          key="primary"
          class="w-5 ml-2 absolute right-0"
          type="primary"
          @click="removeTables"
        >
          <el-icon><Minus /></el-icon>
        </el-button>
      </div>
      <div
        v-for="key of Object.keys(dashboardStore.riskProfileFilterData[idx - 1]).sort()"
        :id="'exposure-table-' + key"
        :key="'table' + key"
        class="w-fit"
        :class="Object.keys(dashboardStore.riskProfileFilterData[idx - 1]).sort()[0] == key ? 'mt-2' : 'mt-5'"
      >
        <table
          v-if="(key as string) in dashboardStore.riskProfileFilterData[idx - 1]"
          class="bg-white shadow"
          style="border-spacing: 0"
        >
          <thead class="sticky top-11 z-20">
            <tr>
              <th class="fixWidth header-teal text-black">{{ key }}</th>
              <th class="fixWidth header-teal text-black">GWP</th>
              <th class="fixWidth header-teal text-black">GWP % of total</th>
              <template v-if="isPolicyCountInTheData(key)">
                <th class="fixWidth header-teal text-black">Policy Count</th>
                <th class="fixWidth header-teal text-black">Policy Count (%)</th>
                <th class="fixWidth header-teal text-black">Average GWP</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data of filterValueSorting(
                key,
                Object.keys(dashboardStore.riskProfileFilterData[idx - 1][key]['values_mapping'])
              )"
              :key="'dictionary' + data"
            >
              <td
                class="relative fixWidth whitespace-nowrap overflow-hidden hover:overflow-visible bg-sybil-light-teal"
              >
                <span class="bg-sybil-light-teal">
                  {{ data }}
                </span>
              </td>
              <td class="fixWidth">
                {{
                  numberWithCommas(
                    dashboardStore.riskProfileFilterData[idx - 1][key]['GWP_SUM'][convertNameToKey(key, data)],
                    false
                  )
                }}
              </td>
              <td class="fixWidth">
                {{
                  decimalToPercentage(
                    safeDivide(
                      dashboardStore.riskProfileFilterData[idx - 1][key]['GWP_SUM'][convertNameToKey(key, data)],
                      dashboardStore.riskProfileFilterData[idx - 1][key]['total_gwp']
                    ),
                    false
                  )
                }}
              </td>
              <template v-if="isPolicyCountInTheData(key)">
                <td class="fixWidth">
                  {{
                    numberWithCommas(
                      dashboardStore.riskProfileFilterData[idx - 1][key]['exposure.sum.Policy Count'][
                        convertNameToKey(key, data)
                      ],
                      false
                    )
                  }}
                </td>
                <td class="fixWidth">
                  {{
                    decimalToPercentage(
                      safeDivide(
                        dashboardStore.riskProfileFilterData[idx - 1][key]['exposure.sum.Policy Count'][
                          convertNameToKey(key, data)
                        ],
                        dashboardStore.riskProfileFilterData[idx - 1][key]['total_policy_count']
                      ),
                      false
                    )
                  }}
                </td>
                <td class="fixWidth">
                  {{
                    numberWithCommas(
                      safeDivide(
                        dashboardStore.riskProfileFilterData[idx - 1][key]['GWP_SUM'][convertNameToKey(key, data)],
                        dashboardStore.riskProfileFilterData[idx - 1][key]['exposure.sum.Policy Count'][
                          convertNameToKey(key, data)
                        ]
                      ),
                      false
                    )
                  }}
                </td>
              </template>
            </tr>
          </tbody>
          <tfoot class="sticky bottom-0 z-20">
            <tr>
              <td class="fixWidth" style="background-color: #bbe2d3">Total</td>
              <td class="fixWidth" style="background-color: #bbe2d3">
                {{ numberWithCommas(dashboardStore.riskProfileFilterData[idx - 1][key]['total_gwp'], false) }}
              </td>
              <td class="fixWidth" style="background-color: #bbe2d3">100%</td>
              <template v-if="isPolicyCountInTheData(key)">
                <td class="fixWidth" style="background-color: #bbe2d3">
                  {{
                    numberWithCommas(dashboardStore.riskProfileFilterData[idx - 1][key]['total_policy_count'], false)
                  }}
                </td>
                <td class="fixWidth" style="background-color: #bbe2d3">100%</td>
                <td class="fixWidth" style="background-color: #bbe2d3">
                  {{
                    numberWithCommas(
                      safeDivide(
                        dashboardStore.riskProfileFilterData[idx - 1][key]['total_gwp'],
                        dashboardStore.riskProfileFilterData[idx - 1][key]['total_policy_count']
                      ),
                      false
                    )
                  }}
                </td>
              </template>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div
    v-if="
      (Object.keys(dashboardStore.riskProfileFilterData[idx - 1]).length == 0 ||
        !dashboardStore.riskProfileFilterData) &&
      props.idx == 1
    "
    class="w-96 h-[calc(100vh-170px)] mt-16 ml-16 text-7xl font-bold text-gray-300"
  >
    <h1>TO START, SELECT FROM THE MENU ON THE RIGHT</h1>
  </div>
</template>
<style scoped>
.table-panel {
  margin-left: 2px;
  border-collapse: separate;
}

.fixWidth {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.01em solid #f4f4f5;
  font-size: 12px;
  text-align: center;
}

.fixWidth2 {
  width: 900px;
  min-width: 900px;
  max-width: 900px;
}
</style>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useActuarialStore } from '@/stores/actuarial';
import { usePortfolioStore } from '@/stores/portfolio';

const router = useRouter();
const actuarialStore = useActuarialStore();
const portfolioStore = usePortfolioStore();

function selectPage(x: string) {
  router.push({ path: '/' + x });
}
const currentFrontPage = ref('Main Menu');
const isBronze = ref(false);
const currentOption = ref('Table view');

const currentRoute = computed(() => router.currentRoute.value);

function changeChartPage(x: string) {
  router.push({ path: '/dashboard/' + x });
  console.log(currentRoute.value['fullPath']);
  console.log(x);
}

function changeUnderwritingPage(x: string) {
  router.push({ path: '/underwriting/' + x });
}

function changeActuarialPage(x: string) {
  router.push({ path: '/actuarial/' + x });
}

function changeFinancePage(x: string) {
  router.push({ path: '/finance/' + x });
}
</script>

<template>
  <div class="dock w-10 bg-white drop-shadow-lg pt-24">
    <!-- data loaded left menu -->
    <ul
      v-if="
        currentRoute.matched[0].name != 'home' &&
        currentRoute.matched[0].name != 'Finance' &&
        portfolioStore.selectedBounceID != ''
      "
      data-testid="portfolio-sidebar"
    >
      <!-- first items -->
      <el-tooltip content="Main Dashboard" placement="right">
        <li data-testid="go-to-dashboard-module" @click="selectPage('dashboard')">
          <div :className="'my-1 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'">
            <div
              :class="
                'absolute ' +
                (currentRoute.matched[0]?.name == 'Dashboard' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                ' w-10 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
              "
            />
            <img
              :class="
                (currentRoute.matched[0]?.name == 'Dashboard' ? 'png-white' : 'png-white') +
                ' skew-y-12 object-cover  group-hover:rotate-1 group-hover:scale-90'
              "
              alt="Main dashboards"
              src="@/assets/images/md.png"
            />
          </div>
        </li>
      </el-tooltip>
      <!-- second item -->
      <el-tooltip v-if="!isBronze" content="A-priori Module" placement="right">
        <li data-testid="go-to-underwriting-module" @click="selectPage('underwriting')">
          <div :className="'my-1 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'">
            <div
              :class="
                'absolute ' +
                (currentRoute.matched[0]?.name == 'Underwriting' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                ' w-10 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
              "
            />
            <img
              :class="
                (currentRoute.matched[0]?.name == 'Underwriting' ? 'png-white' : 'png-white') +
                ' skew-y-12 object-cover  group-hover:rotate-1 group-hover:scale-90'
              "
              alt="A-priori Module"
              src="@/assets/images/uw.png"
            />
          </div>
        </li>
      </el-tooltip>
      <!-- third item -->
      <el-tooltip v-if="!isBronze" content="IBNR Module" placement="right">
        <li data-testid="go-to-ibnr-module" class="relative" @click="selectPage('actuarial')">
          <div :className="'my-1 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'">
            <div
              :class="
                'absolute ' +
                (currentRoute.matched[0]?.name == 'Actuarial' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                ' w-10 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
              "
            />

            <img
              :class="
                (currentRoute.matched[0]?.name == 'Actuarial' ? 'png-white' : 'png-white') +
                ' skew-y-12 object-cover  group-hover:rotate-1 group-hover:scale-90'
              "
              alt="IBNR Module"
              src="@/assets/images/ac.png"
            />
          </div>
        </li>
      </el-tooltip>
      <el-tooltip v-if="actuarialStore.actuarial_cache_reset_required" content="Run IBNR Allocation" placement="right">
        <el-badge
          value="!"
          class="item scale-150 hover:opacity-90 cursor-pointer"
          @click="actuarialStore.resetActuarialCache()"
        >
          <div class="w-10 h-10" />
        </el-badge>
      </el-tooltip>
      <!-- FINANCE MODULE TABS -->
      <div v-if="currentRoute.matched[0]?.name == 'Actuarial'" class="mt-20">
        <el-tooltip content="Triangulations" placement="right">
          <li @click="changeActuarialPage('triangulations')">
            <div
              :className="'my-1 ml-14 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'"
            >
              <div
                :class="
                  'absolute ' +
                  (currentRoute['fullPath'] == '/actuarial/triangulations' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                  ' w-3 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
                "
              />
              <a
                :class="
                  (currentRoute['fullPath'] == '/actuarial/triangulations' ? 'png-white' : 'png-white') +
                  '   text-sm -rotate-90 ml-5'
                "
                alt="Triangulations"
              />
            </div>
          </li>
        </el-tooltip>
        <el-tooltip content="AvE" placement="right">
          <li @click="changeActuarialPage('results-summary')">
            <div
              :className="'my-1 ml-14 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'"
            >
              <div
                :class="
                  'absolute ' +
                  (currentRoute['fullPath'] == '/actuarial/results-summary' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                  ' w-3 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
                "
              />
              <a
                :class="
                  (currentRoute['fullPath'] == '/actuarial/results-summary' ? 'png-white' : 'png-white') +
                  '   text-sm -rotate-90 ml-5'
                "
                alt="AvE"
              />
            </div>
          </li>
        </el-tooltip>
      </div>
      <!-- UW module tabs -->
      <div v-if="currentRoute.matched[0]?.name == 'Underwriting'" class="mt-20">
        <el-tooltip content="Table view" placement="right">
          <li @click="changeUnderwritingPage('table-view')">
            <div
              :className="'my-1 ml-14 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'"
            >
              <div
                :class="
                  'absolute ' +
                  (currentRoute['fullPath'] == '/underwriting/table-view' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                  ' w-3 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
                "
              />
              <a
                :class="(currentOption == 'Table view' ? 'png-white' : 'png-white') + '   text-sm -rotate-90 ml-5'"
                alt="Table view"
              />
            </div>
          </li>
        </el-tooltip>
        <el-tooltip content="Chart view" placement="right">
          <li @click="changeUnderwritingPage('chart-view')">
            <div
              :className="'my-1 ml-14 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'"
            >
              <div
                :class="
                  'absolute ' +
                  (currentRoute['fullPath'] == '/underwriting/chart-view' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                  ' w-3 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
                "
              />
              <a
                :class="(currentOption == 'Chart view' ? 'png-white' : 'png-white') + '   text-sm -rotate-90 ml-5'"
                alt="Chart view"
              />
            </div>
          </li>
        </el-tooltip>
      </div>
      <!-- Dashboard tabs -->
      <div v-if="currentRoute.matched[0]?.name == 'Dashboard'" class="mt-20">
        <el-tooltip content="Performance view" placement="right">
          <li @click="changeChartPage('performance-view')">
            <div
              :className="'my-1 ml-14 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'"
            >
              <div
                :class="
                  'absolute ' +
                  (currentRoute['fullPath'] == '/dashboard/performance-view' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                  ' w-3 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
                "
              />
              <a
                :class="
                  (currentRoute['fullPath'] == '/dashboard/performance-view' ? 'png-white' : 'png-white') +
                  ' skew-y-12 object-cover  group-hover:rotate-1 group-hover:scale-90 text-3xl font-bold'
                "
                alt="Performance view"
              />
            </div>
          </li>
        </el-tooltip>
        <el-tooltip content="Comparison view" placement="right">
          <li @click="changeChartPage('comparison-view')">
            <div
              :className="'my-1 ml-14 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'"
            >
              <div
                :class="
                  'absolute ' +
                  (currentRoute['fullPath'] == '/dashboard/comparison-view' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                  ' w-3 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
                "
              />
              <a
                :class="
                  (currentRoute['fullPath'] == '/dashboard/comparison-view' ? 'png-white' : 'png-white') +
                  ' skew-y-12 object-cover  group-hover:rotate-1 group-hover:scale-90 text-3xl font-bold'
                "
                alt="Comparison view"
              />
            </div>
          </li>
        </el-tooltip>
        <el-tooltip v-if="portfolioStore.getExposureLength() > 0" content="Risk Profile view" placement="right">
          <li @click="changeChartPage('risk-profile-view')">
            <div
              :className="'my-1 ml-14 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'"
            >
              <div
                :class="
                  'absolute ' +
                  (currentRoute['fullPath'] == '/dashboard/risk-profile-view' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                  ' w-3 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
                "
              />
              <a
                :class="
                  (currentRoute['fullPath'] == '/dashboard/risk-profile-view' ? 'png-white' : 'png-white') +
                  ' skew-y-12 object-cover  group-hover:rotate-1 group-hover:scale-90 text-3xl font-bold'
                "
                alt="Risk Profile View"
              />
            </div>
          </li>
        </el-tooltip>
        <el-tooltip v-if="portfolioStore.isEPI" content="EPI view" placement="right">
          <li @click="changeChartPage('epi-view')">
            <div
              :className="'my-1 ml-14 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'"
            >
              <div
                :class="
                  'absolute ' +
                  (currentRoute['fullPath'] == '/dashboard/epi-view' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                  ' w-3 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
                "
              />
              <a
                :class="
                  (currentRoute['fullPath'] == '/dashboard/epi-view' ? 'png-white' : 'png-white') +
                  ' skew-y-12 object-cover  group-hover:rotate-1 group-hover:scale-90 text-3xl font-bold'
                "
                alt="EPI View"
              />
            </div>
          </li>
        </el-tooltip>
      </div>
      <div class="-rotate-90 fixed bottom-4 left-1 text-sybil-teal text-xl w-10 h-10 whitespace-nowrap brightness-75">
        {{
          (currentRoute.matched[0]?.name
            ?.toString()
            .replace('Actuarial', 'IBNR Module')
            .replace('Underwriting', 'A-priori Module') || '') +
          ' - ' +
          (currentRoute.matched[1]?.name?.toString() || '')
        }}
      </div>
    </ul>
    <ul v-else data-testid="main-menu-sidebar">
      <!-- First items -->
      <el-tooltip content="Main Menu" placement="right">
        <li data-testid="portfolio-selection-option" @click="selectPage('home')">
          <div :className="'my-1 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'">
            <div
              :class="
                'absolute ' +
                (currentRoute.matched[0]?.name == 'home' && currentRoute.matched[1]?.name == 'Portfolio'
                  ? 'bg-sybil-teal'
                  : 'bg-sybil-charcoal') +
                ' w-10 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
              "
            />
            <img
              :class="
                (currentRoute.matched[0]?.name == 'home' && currentRoute.matched[1]?.name == 'Portfolio'
                  ? 'png-white'
                  : 'png-white') + ' skew-y-12 object-cover  group-hover:rotate-1 group-hover:scale-90'
              "
              alt="Main Menu"
              src="@/assets/images/homepage.png"
            />
          </div>
        </li>
      </el-tooltip>
      <!-- Second items -->
      <el-tooltip v-if="!isBronze" content="Finance module" placement="right">
        <li data-testid="finance-option" @click="selectPage('finance')">
          <div :className="'my-1 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'">
            <div
              :class="
                'absolute ' +
                (currentRoute.matched[0]?.name == 'Finance' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                ' w-10 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
              "
            />
            <img
              :class="
                (currentRoute.matched[0]?.name == 'Finance' ? 'png-white' : 'png-white') +
                ' skew-y-12 object-cover  group-hover:rotate-1 group-hover:scale-90'
              "
              alt="Finance module"
              src="@/assets/images/fi.png"
            />
          </div>
        </li>
      </el-tooltip>
      <!-- Third items -->
      <el-tooltip content="Actions module" placement="right">
        <li data-testid="actions-option" @click="selectPage('actions')">
          <div :className="'my-1 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'">
            <div
              :class="
                'absolute ' +
                (currentRoute['fullPath'] == '/actions' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                ' w-10 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
              "
            />
            <img
              :class="
                (currentRoute['fullPath'] == '/actions' ? 'png-white' : 'png-white') +
                ' skew-y-12 object-cover  group-hover:rotate-1 group-hover:scale-90'
              "
              alt="Activity Log"
              src="@/assets/images/activitylog.png"
            />
          </div>
        </li>
      </el-tooltip>
      <!-- Finance Module Tabs -->
      <div v-if="currentRoute.matched[0]?.name == 'Finance'" class="mt-20">
        <el-tooltip content="Finance view" placement="right">
          <li @click="changeFinancePage('finance-selection')">
            <div
              :className="'my-1 ml-14 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'"
            >
              <div
                :class="
                  'absolute ' +
                  (currentRoute['fullPath'] == '/finance/finance-selection' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                  ' w-3 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
                "
              />
              <a
                :class="
                  (currentRoute['fullPath'] == '/finance/finance-selection' ? 'png-white' : 'png-white') +
                  '   text-sm -rotate-90 ml-5'
                "
                alt="Finance View"
              />
            </div>
          </li>
        </el-tooltip>
        <el-tooltip content="Finance Summary" placement="right">
          <li @click="changeFinancePage('finance-summary')">
            <div
              :className="'my-1 ml-14 -skew-y-12 group flex justify-center text-center relative cursor-pointer h-10'"
            >
              <div
                :class="
                  'absolute ' +
                  (currentRoute['fullPath'] == '/finance/finance-summary' ? 'bg-sybil-teal' : 'bg-sybil-charcoal') +
                  ' w-3 h-10 group-hover:bg-sybil-teal group-hover:opacity-80'
                "
              />
              <a
                :class="
                  (currentRoute['fullPath'] == '/finance/finance-summary' ? 'png-white' : 'png-white') +
                  '   text-sm -rotate-90 ml-5'
                "
                alt="Chart view"
              />
            </div>
          </li>
        </el-tooltip>
      </div>
    </ul>
  </div>
</template>

<style scoped>
.dock {
  left: 0;
  top: 0;
  height: 100%;
  position: fixed;
  z-index: 49;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /* align-items: center; */
}

.png-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.png-teal:hover {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}
</style>

import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardAvEStore } from '@/stores/AvEDashboard';
import { api } from '@/services/api';
import { ElMessage } from 'element-plus';
import moment from 'moment';
import { useAuthenticationStore } from '@/stores/auth';
import { targetCCR, targetNLR } from '@/calculations/Claims/TargetCalculation';
import errorHandler from '@/utilities/errorhandler';

type OtherInformationSortType = {
  column: string;
  order: string;
};

type RiskProfileCustomBandsFilterType = {
  [key: string]: boolean[];
};

export const useDashboardStore = defineStore('dashboard', () => {
  const portfolio_store = usePortfolioStore();
  const dashboard_ave_store = useDashboardAvEStore();
  const authenticate_store = useAuthenticationStore();

  // EPI
  const epiFilters = ref({});
  const epiFiltersHierarchy = ref([]);
  const epiFiltersExpand = ref<boolean[]>([]);
  const epiFiltersSelected = ref({});

  async function fetchEPIFilters() {
    global_store.setLoading(true);
    await api
      .get('portfolio/epi-filters', {
        params: {
          bounce_id: portfolio_store.selectedBounceID,
        },
      })
      .then((res) => {
        epiFilters.value = res.data.data.filters;
        epiFiltersHierarchy.value = res.data.data.hierarchies;
        epiFiltersExpand.value = Array(epiFiltersHierarchy.value.length).fill(false);

        epiFiltersSelected.value = {};
        for (const hierarchy in epiFilters.value) {
          epiFiltersSelected.value[hierarchy] = Array(epiFilters.value[hierarchy].length).fill(false);
        }
        global_store.setLoading(false);
      })
      .catch((error) => {
        global_store.setLoading(false);
        errorHandler(error);
      });
  }

  const isConfigBindedYearsModal = ref(false);
  const transitionRenderKey = ref(0);
  const isBindedYears = ref(false);
  const underwriting_loss_ratios = ref('Written');
  const claimsSelectedType = ref('');
  const dateUnit = ref('');
  const isClaimsAttritionalInformationModal = ref<boolean>(false);
  const claimsAttritionalModalData: any = ref([]);
  const selectedDateClaimsOthersInformation = ref();
  const include_projections = ref(true);
  const comp2 = ref(false);
  const otherInformationSort = ref({
    column: '',
    order: '',
  });
  const dashboardForwardLookings = ref<[number, number][] | null>(null);

  const isCreateNewBandsOnDashboardModal = ref<boolean>(false);

  const yearsOfProjections = ref(0);

  // Risk Profile
  const riskProfileFilter = ref<boolean[]>([]);
  const riskProfileCustomBandsFilter = ref<RiskProfileCustomBandsFilterType>({});
  const riskProfileFilterData = ref([{}]);
  const riskProfileDateSelection = ref<(null | Date)[][]>([[null, null]]);
  const selectedRisk: Set<string> = new Set();
  const riskProfileNumberOfTables = ref(1);
  const riskProfileFilterRenderKey = ref(1);
  // const riskProfileCustomBandExpand = ref<RiskProfileCustomBandsFilterType>({});
  // const riskProfileCustomBandFilterSelection = ref<RiskProfileCustomBandsFilterType>({});
  const riskProfileFilterSelection = ref<Record<string, Array<string>>>({});

  function generateMonthlyDates(start, end) {
    let startDate = moment(start).startOf('month'); // Ensure we start at the 1st of the month
    let endDate = moment(end).startOf('month');
    let result: string[] = [];

    while (startDate.isSameOrBefore(endDate)) {
      result.push(startDate.format('YYYY/MM/01')); // Format: YYYY/MM/01
      startDate.add(1, 'month'); // Move to next month
    }

    return result;
  }

  async function fetchAllRiskProfileTables() {
    global_store.setLoading(true);

    const promises: Promise<void>[] = [];

    for (let i = 0; i < riskProfileNumberOfTables.value; i++) {
      for (let hierarchy of selectedRisk.values()) {
        promises.push(fetchRiskProfileTable(hierarchy, i));
      }
    }

    promises.push(fetchAllCustomBands());

    await Promise.all(promises); // Wait for all requests to complete
    global_store.setLoading(false);
  }

  async function fetchAllCustomBands() {
    const promises: Promise<void>[] = [];
    for (const hierarchy in riskProfileCustomBandsFilter.value) {
      for (const bandIdx in riskProfileCustomBandsFilter.value[hierarchy]) {
        if (riskProfileCustomBandsFilter.value[hierarchy][bandIdx] == true) {
          for (let i = 0; i < riskProfileNumberOfTables.value; i++) {
            promises.push(fetchRiskProfileTableForCustomBands(hierarchy, i, bandIdx));
          }
        }
      }
    }
    await Promise.all(promises); // Wait for all requests to complete
  }

  async function fetchRiskProfileTableForCustomBands(hierarchy, idx, customBandIndex) {
    global_store.setLoading(true);
    const selectedCustomBands = portfolio_store.parameters['bands'][hierarchy][customBandIndex];
    const name = hierarchy + '-' + selectedCustomBands['name'];
    console.log(name);

    function valuesMappingForCustomBands(data) {
      const a = {};
      for (const i in data) {
        a[data[i]] = i;
      }
      return a;
    }

    await api
      .post('claims/risk-profile-table', {
        bounce_id: portfolio_store.selectedBounceID,
        selected_hierarchy: hierarchy,
        underwriting_month_filter: generateMonthlyDates(
          riskProfileDateSelection.value[idx][0],
          riskProfileDateSelection.value[idx][1]
        ),
        is_custom_exposure: true,
        custom_exposure: selectedCustomBands,
      })
      .then((response) => {
        const data = JSON.parse(response.data.data.data);
        data['values_mapping'] = valuesMappingForCustomBands(data[hierarchy]);
        riskProfileFilterData.value[idx][name] = data;
        if ('exposure.sum.Policy Count' in riskProfileFilterData.value[idx][name]) {
          riskProfileFilterData.value[idx][name]['total_policy_count'] = Object.values(
            riskProfileFilterData.value[idx][name]['exposure.sum.Policy Count'] as Record<string, number>
          ).reduce((a, b) => a + b, 0);
        }

        riskProfileFilterData.value[idx][name]['total_gwp'] = Object.values(
          riskProfileFilterData.value[idx][name]['GWP_SUM'] as Record<string, number>
        ).reduce((a, b) => a + b, 0);

        global_store.setLoading(false);
      })
      .catch((error) => {
        global_store.setLoading(false);
        console.log(error);
        errorHandler(error);
      });
  }

  async function fetchRiskProfileTable(hierarchy, idx) {
    // function generateFiltersHierarchy() {
    //   const translatedFilter: (string | number)[] = [];
    //   for (const i of riskProfileFilterSelection.value[hierarchy]) {
    //     translatedFilter.push(reverseSearch(portfolio_store.dictionary[hierarchy], i));
    //   }

    //   return translatedFilter;
    // }

    global_store.setLoading(true);
    await api
      .post('claims/risk-profile-table', {
        bounce_id: portfolio_store.selectedBounceID,
        selected_hierarchy: hierarchy,
        underwriting_month_filter: generateMonthlyDates(
          riskProfileDateSelection.value[idx][0],
          riskProfileDateSelection.value[idx][1]
        ),
        // filters_hierarchy: generateFiltersHierarchy(),
      })
      .then((response) => {
        const data = JSON.parse(response.data.data.data);
        data['values_mapping'] = valuesDictionary(data, hierarchy);
        riskProfileFilterData.value[idx][hierarchy] = data;
        if ('exposure.sum.Policy Count' in riskProfileFilterData.value[idx][hierarchy]) {
          riskProfileFilterData.value[idx][hierarchy]['total_policy_count'] = Object.values(
            riskProfileFilterData.value[idx][hierarchy]['exposure.sum.Policy Count'] as Record<string, number>
          ).reduce((a, b) => a + b, 0);
        }

        riskProfileFilterData.value[idx][hierarchy]['total_gwp'] = Object.values(
          riskProfileFilterData.value[idx][hierarchy]['GWP_SUM'] as Record<string, number>
        ).reduce((a, b) => a + b, 0);

        global_store.setLoading(false);
      })
      .catch((error) => {
        global_store.setLoading(false);
        console.log(error);
        errorHandler(error);
      });
  }

  function valuesDictionary(data, hierarchy) {
    const a = {};
    for (const i in Object.keys(data[hierarchy])) {
      a[portfolio_store.dictionary[hierarchy][data[hierarchy][i]]] = i;
    }
    return a;
  }

  // End Risk Profile

  watch(
    () => authenticate_store.user,
    () => {
      DEFAULT_DASHBOARD_COLULMNS_SELECTION.value =
        authenticate_store.user && authenticate_store.user.email == 'david.sweet@sybil.cloud'
          ? [1, 4]
          : [1, 2, 3, 4, 5];
      visibleColumns.value = DEFAULT_DASHBOARD_COLULMNS_SELECTION.value;
    }
  );

  const DEFAULT_DASHBOARD_COLULMNS_SELECTION = ref(
    authenticate_store.user && authenticate_store.user.email == 'david.sweet@sybil.cloud' ? [1, 4] : [1, 2, 3, 4, 5]
  );
  const DASHBOARD_COLUMNS_SELECTION = ['GWP', 'GEP', 'IBNR/ultimate/CCR', 'Commission', 'Model'];
  const visibleColumns = ref<(string | number)[] | undefined>(DEFAULT_DASHBOARD_COLULMNS_SELECTION.value);

  const partitions_hierarchies = ref(null);
  const partitions_hierarchies_bool = ref(null);
  const partitions_hierarchy_radio = ref('Expected Next 12 Months');

  const offMarginGWPGEP = computed(() => {
    let sum = 0;
    if (!visibleColumns.value?.includes(1)) {
      sum += 112;
    }
    if (!visibleColumns.value?.includes(2)) {
      sum += 112;
    }
    return sum;
  });
  const offMarginAprioriCCR = computed(() => {
    let sum = offMarginGWPGEP.value;
    if (!visibleColumns.value?.includes(3)) {
      sum += 224;
    }
    if (!visibleColumns.value?.includes(4)) {
      sum += 112;
    }
    return sum;
  });

  const graphSelectionID = ref(0);

  const report_date = computed(() => portfolio_store.current_month);
  const isClaimsOthersInformationModal = ref<boolean>(false);
  const claimsOthersModalData: any = ref({ data: [], cols: {}, metadata: {} });
  const claimsOthersModalPage: any = ref(1);

  const comparisonViewChartData: any = ref([]);
  const comparisonViewPartitionData: any = ref({
    name: 'All',
    children: [],
  });

  const filtersTracker = ref({});

  const tierData: any = ref([]);
  const gwp_dashboard: any = ref([]);
  const total_gwp = ref(0);
  const total_incurred: any = ref([]);
  const incurred_data_dashboard = ref<string[][]>([]);
  const gwp_dashboard_column = ref({});
  const incurred_data_dashboard_column: any = ref({});
  const chart_data: any = ref([]);
  const isLoaded = ref(false);
  const filters: any = ref({});
  const chunks_grouping: any = ref(null);
  const chunks_grouping_basis: any = ref({});
  const comparisonViewColumns = ref([
    'Expected Next 12 Months GWP',
    'Forward Looking Next 12 Months CCR',
    'Last 12 Underwriting Months CCR',
    'Last 12 Accident Months CCR',
    'All Time Written GWP',
    'All Time CCR',
  ]);
  const chunk_data = ref(null);

  const minMaxXY = ref([0, 100, 0, 100]);

  const perdormanceViewScroll = ref(0);
  const comparisonViewScroll = ref(0);
  const comparison_table_scroll_position = ref(0);
  const comparisonViewColumnsTree = ref([]);
  const graphSelectionRadio = ref(null);
  const filtersExpandedKeys = ref([]);
  const treeSelectionKeys = ref(null);
  const normalised_for_api = computed(() => {
    let res: any = [];

    for (const i in portfolio_store.normaliseSelection) {
      if (portfolio_store.normaliseSelection[i] == true) {
        res.push(i);
      }
    }

    return res;
  });

  const selectedData: any = ref();
  const dateFilters: any = ref({ underwriting: {}, accident: {} });
  const dataFilters: any = ref();
  const unDataFilters: any = ref(null);
  const unDateFilters: any = ref(null);

  const original_monthly_data = ref({
    gwp_dashboard: [],
    incurred_data_dashboard: [],
  });

  const amtFunc = ref(['Paid', 'OS', 'Incurred', 'IBNR', 'Ultimate']);
  const amtLabel = ref(['Paid', 'O/S', 'Incurred', 'IBNR', 'Ultimate']);
  const dashboard_data: any = ref(null);
  const dashboard_data_column: any = ref(null);
  const seasonality_parameters: any = ref(null);
  const large_threshold: any = ref(null);
  const large_method: any = ref(null);
  const UWCommissionColumns: any = ref(null);
  const data_CommissionColumns: any = ref(null);
  const monthly_dashboard_data: any = ref(null);
  const yearly_dashboard_data: any = ref(null);
  const binder_dashboard_data: any = ref(null);
  const quarterly_dashboard_data: any = ref(null);
  const totalData: any = ref(null);

  const isYearSubTotal = ref(false);
  const isYearSubTotalUp = ref(true);
  const isQuarterSubTotal = ref(false);
  const isQuarterSubTotalUp = ref(true);
  const isBinderSubTotal = ref(false);
  const isBinderSubTotalUp = ref(true);
  const isShowingExposure = ref(false);

  const global_store = useGlobalStore();

  setAmtFuncAndLabel();

  const graphConfig = ref({
    isGLR: true,
    isNormalised: true,
  });

  const dashboards = ref({
    uw_acc: 'uw', //accident or underwriting
    mqy: 'quarter', //month_quarter_year
    ratio_amount: 'ratios',
    seasonFactor: false,
    filters: null,
    datefilters: { uw: null, acc: null },
    calendardatefilters: null,
    gwpnwp: 'GWP',
    ccr_nlr: 'CCR',
  });

  const selectedChunkGroup = ref('');
  const selectedTierGroup = ref(null);

  const claimsType: any = computed(() => portfolio_store.parameters.claims_nature);
  const normalise: any = computed(() =>
    claimsType.value.slice(1).filter((x, i) => portfolio_store.normaliseSelection[i])
  );

  function graphSelectionIDIncrement() {
    graphSelectionID.value += 1;
  }

  function getChunk() {
    let group = selectedChunkGroup.value;
    let object_chunk: any = {};
    let is_hierarchy = false;
    if (!chunks_grouping.value) {
      chunks_grouping.value = {};
    }
    if (Object.keys(portfolio_store.dictionary).includes(group)) {
      const keys: any = Object.keys(portfolio_store.dictionary[group]);
      const values: any = Object.values(portfolio_store.dictionary[group]);
      for (let i = 0; i < values.length; i++) {
        const val: any = values[i];
        const key: any = keys[i];

        object_chunk[val] = {};
        object_chunk[val][group] = [key];
      }
      is_hierarchy = true;
    } else {
      object_chunk = chunks_grouping.value[group];
    }

    return object_chunk;
  }

  async function chunk_tables() {
    global_store.setLoading(true);

    let object_chunk = getChunk();

    // portfolio_store.dictionary
    await api
      .post(import.meta.env.VITE_API_URL + 'claims/chunk-table', {
        bounce_id: portfolio_store.selectedBounceID,
        chunk: object_chunk,
        current_month: report_date.value,
        normalised_CCR: normalised_for_api.value,
        report_date: report_date.value,
        selectedColumns: comparisonViewColumns.value,
        claim_category: 0,
        selectedLineSize: portfolio_store.selectedLineSize,
        partitions_hierarchies: partitions_hierarchies.value,
        partitions_hierarchy_radio: partitions_hierarchy_radio.value,
      })
      .then((res) => {
        processChunkData(JSON.parse(res.data.data[0]));
        if (comparisonViewColumnsTree.value.length == 0) {
          comparisonViewColumnsTree.value = res.data.data[1];
        }
        global_store.setLoading(false);
      })
      .catch(() => {
        openErrorMsg('Error!');
        global_store.setLoading(false);
      });
  }

  async function partition_chart() {
    global_store.setLoading(true);
    await api
      .post(import.meta.env.VITE_API_URL + 'claims/partition_chart', {
        bounce_id: portfolio_store.selectedBounceID,
        current_month: report_date.value,
        normalised_CCR: normalised_for_api.value,
        report_date: report_date.value,
        selectedLineSize: portfolio_store.selectedLineSize,
        partitions_hierarchies: partitions_hierarchies.value,
        partitions_hierarchies_bool: partitions_hierarchies_bool.value,
        partitions_hierarchy_radio: partitions_hierarchy_radio.value,
        selectedTierGroup: selectedTierGroup.value,
        portfolio_dictionary: portfolio_store.dictionary,
        tierData: tierData.value,
      })
      .then((res) => {
        comparisonViewPartitionData.value = res.data.data[0];
        global_store.setLoading(false);
      })
      .catch(() => {
        openErrorMsg('Error!');
        global_store.setLoading(false);
      });
  }

  function setSelectedChunkGroup(selected: string) {
    selectedChunkGroup.value = selected;
  }
  function setSelectedTierGroup(selected: string) {
    selectedTierGroup.value = selected;
  }

  function underwritingLossRatiosChange() {
    setAmtFuncAndLabel();
    setChartData();
  }

  function setAmtFuncAndLabel() {
    if (underwriting_loss_ratios.value == 'Written') {
      underwriting_loss_ratios.value = 'Earned';
      amtFunc.value = ['Paid', 'OS', 'Incurred', 'IBNR', 'Ultimate'];
      amtLabel.value = ['Paid', 'O/S', 'Incurred', 'IBNR', 'Ultimate'];
    } else {
      underwriting_loss_ratios.value = 'Written';
      amtFunc.value = ['Paid', 'OS', 'Incurred', 'IBNR', 'Unearned', 'Ultimate'];
      amtLabel.value = ['Paid', 'O/S', 'Incurred', 'IBNR', 'Unearned Expected Loss', 'Ultimate'];
    }
  }

  function getComparisonViewColumnsTree() {
    return comparisonViewColumnsTree.value;
  }

  function getComparisonViewColumns() {
    return comparisonViewColumns.value;
  }

  function setComparisonViewColumns(data: any) {
    comparisonViewColumns.value = data;
  }

  function updateTreeSelectionKeys(o: any) {
    treeSelectionKeys.value = o;
  }

  async function processChunkData(data: any) {
    chunk_data.value = data;
  }

  async function get_chunk() {
    global_store.setLoading(true);
    await api
      .get(import.meta.env.VITE_API_URL + 'claims/chunk', {
        params: {
          bounce_id: portfolio_store.selectedBounceID,
        },
      })
      .then((res) => {
        chunks_grouping.value = res.data.data.data;
        chunks_grouping_basis.value = {};

        for (let i of Object.keys(chunks_grouping.value)) {
          for (let j of Object.keys(chunks_grouping.value[i]).slice(0, 1)) {
            chunks_grouping_basis.value[i] = Object.keys(chunks_grouping.value[i][j])[0];
          }
        }
        global_store.setLoading(false);
      });
  }

  function numberWithRatios(x: number, isValue: boolean) {
    if (isValue) {
      return x;
    } else {
      return (x * 100).toFixed(1) + '%';
    }
  }

  function setMinMaxXY(value: any) {
    minMaxXY.value = value;
  }

  function calculateCCRNLR(data_row: any, isValue: boolean) {
    let ans = 0;
    if (dashboards.value.ccr_nlr == 'CCR') {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                  .reduce((ps: number, s: number) => ps + s, 0) +
                calculateUnearned(data_row)) /
                data_row[dashboard_data_column.value['uws.GWP_SUM']] +
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
          : (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                  .reduce((ps: number, s: number) => ps + s, 0) +
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans = 0);
    } else {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                  .reduce((ps: number, s: number) => ps + s, 0) +
                calculateUnearned(data_row)) /
              (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x.replace('uw_data', 'uws')]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
          : (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              (data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
        : (ans = 0);
    }

    return numberWithRatios(ans, isValue);
  }

  function changeSeas() {
    dashboards.value.seasonFactor = !dashboards.value.seasonFactor;
    setChartData();
  }

  function graphApriori(data_row: any, isValue: boolean) {
    if (!graphConfig.value['isGLR']) {
      return seasAdjAprioriGLR(data_row, isValue);
    } else {
      return seasAdjApriori(data_row, isValue);
    }
  }

  function seasAdjAprioriGLR(data_row: any, isValue: boolean) {
    let ans = 0;
    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
      ? (ans =
          claimsType.value
            .map(
              (x) =>
                data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                (!dashboards.value.seasonFactor &&
                !(underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw')
                  ? 1
                  : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
            )
            .reduce((ps: number, s: number) => ps + s, 0) / data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
      : (ans = 0);

    return numberWithRatios(ans, isValue);
  }

  function seasAdjApriori(data_row: any, isValue: boolean) {
    let ans = 0;
    if (dashboards.value.ccr_nlr == 'CCR') {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? (ans =
            (claimsType.value
              .map(
                (x) =>
                  data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                  (!dashboards.value.seasonFactor ||
                  (underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw')
                    ? 1
                    : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
              )
              .reduce((ps: number, s: number) => ps + s, 0) +
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0)) /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans = 0);
    } else {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? (ans =
            claimsType.value
              .map(
                (x) =>
                  data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                  (!dashboards.value.seasonFactor ||
                  (underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw')
                    ? 1
                    : data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']])
              )
              .reduce((ps: number, s: number) => ps + s, 0) /
            (data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] -
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0)))
        : (ans = 0);
    }

    return numberWithRatios(ans, isValue);
  }

  function calculateUnearned(data_row: any) {
    const val = claimsType.value
      .map((x) => data_row[dashboard_data_column.value[x + '_unearned_apriori']])
      .reduce((ps: number, s: number) => ps + s, 0);
    return val;
  }
  function normalisedCCRNLR(data_row: any, isValue: boolean) {
    let ans = 0;
    if (dashboards.value.ccr_nlr == 'CCR') {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => (normalise.value.includes(x) ? 0 : data_row[dashboard_data_column.value[x + '_ibnr']]))
                  .reduce((ps: number, s: number) => ps + s, 0) +
                calculateUnearned(data_row)) /
                data_row[dashboard_data_column.value['uws.GWP_SUM']] +
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
          : (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) => (normalise.value.includes(x) ? 0 : data_row[dashboard_data_column.value[x + '_ibnr']]))
                  .reduce((ps: number, s: number) => ps + s, 0) +
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans = 0);
    } else {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) =>
                    normalise.value.includes(x)
                      ? 0
                      : data_row[dashboard_data_column.value['claims_data.' + x + '_ibnr']]
                  )
                  .reduce((ps: number, s: number) => ps + s, 0) +
                calculateUnearned(data_row)) /
              (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x.replace('uw_data', 'uws')]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
          : (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                claimsType.value
                  .map((x) =>
                    normalise.value.includes(x)
                      ? 0
                      : data_row[dashboard_data_column.value['claims_data.' + x + '_ibnr']]
                  )
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              (data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
        : (ans = 0);
    }

    return numberWithRatios(ans, isValue);
  }

  function graphCCRNLR(data_row: any, isValue: boolean) {
    if (!graphConfig.value['isGLR']) {
      if (graphConfig.value['isNormalised']) {
        return normalisedGLR(data_row, isValue);
      } else {
        return notNormalisedGLR(data_row, isValue);
      }
    } else {
      if (graphConfig.value['isNormalised']) {
        return normalisedCCRNLR(data_row, isValue);
      } else {
        return calculateCCRNLR(data_row, isValue);
      }
    }
  }

  function graphIncurred(data_row: any, isValue: boolean) {
    if (graphConfig.value['isGLR']) {
      if (graphConfig.value['isNormalised']) {
        return normalisedIncurred(data_row, isValue);
      } else {
        return notNormalisedIncurred(data_row, isValue);
      }
    } else {
      if (graphConfig.value['isNormalised']) {
        return normalisedIncurredGLR(data_row, isValue);
      } else {
        return notNormalisedIncurredGLR(data_row, isValue);
      }
    }
  }

  function notNormalisedGLR(data_row: any, isValue: boolean) {
    let ans = 0;

    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
      ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
        ? (ans =
            (claimsType.value
              .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
              .reduce((ps: number, s: number) => ps + s, 0) +
              claimsType.value
                .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                .reduce((ps: number, s: number) => ps + s, 0) +
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
            data_row[dashboard_data_column.value['uws.GWP_SUM']] /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans =
            (claimsType.value
              .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
              .reduce((ps: number, s: number) => ps + s, 0) +
              claimsType.value
                .map((x) => data_row[dashboard_data_column.value[x + '_ibnr']])
                .reduce((ps: number, s: number) => ps + s, 0)) /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
      : (ans = 0);

    return numberWithRatios(ans, isValue);
  }

  function normalisedGLR(data_row: any, isValue: boolean) {
    let ans = 0;

    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
      ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
        ? (ans =
            (claimsType.value
              .map((x) =>
                normalise.value.includes(x)
                  ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                    data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                  : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
              )
              .reduce((ps: number, s: number) => ps + s, 0) +
              claimsType.value
                .map((x) => (normalise.value.includes(x) ? 0 : data_row[dashboard_data_column.value[x + '_ibnr']]))
                .reduce((ps: number, s: number) => ps + s, 0) +
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
            data_row[dashboard_data_column.value['uws.GWP_SUM']] /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans =
            (claimsType.value
              .map((x) =>
                normalise.value.includes(x)
                  ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                    data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                  : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
              )
              .reduce((ps: number, s: number) => ps + s, 0) +
              claimsType.value
                .map((x) => (normalise.value.includes(x) ? 0 : data_row[dashboard_data_column.value[x + '_ibnr']]))
                .reduce((ps: number, s: number) => ps + s, 0)) /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
      : (ans = 0);

    return numberWithRatios(ans, isValue);
  }

  function normalisedIncurredGLR(data_row: any, isValue: boolean) {
    let ans = 0;

    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
      ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
        ? (ans =
            (claimsType.value
              .map((x) =>
                normalise.value.includes(x)
                  ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                    data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                  : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
              )
              .reduce((ps: number, s: number) => ps + s, 0) +
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
            data_row[dashboard_data_column.value['uws.GWP_SUM']])
        : (ans =
            claimsType.value
              .map((x) =>
                normalise.value.includes(x)
                  ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                    data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                  : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
              )
              .reduce((ps: number, s: number) => ps + s, 0) /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
      : (ans = 0);

    return ans;
  }

  function notNormalisedIncurredGLR(data_row: any, isValue: boolean) {
    let ans = 0;

    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
      ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
        ? (ans =
            (claimsType.value
              .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
              .reduce((ps: number, s: number) => ps + s, 0) +
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
            data_row[dashboard_data_column.value['uws.GWP_SUM']] /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans =
            claimsType.value
              .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
              .reduce((ps: number, s: number) => ps + s, 0) /
            data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
      : (ans = 0);

    return ans;
  }

  function notNormalisedIncurred(data_row: any, isValue: boolean) {
    let ans = 0;

    if (dashboards.value.ccr_nlr == 'CCR') {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
                data_row[dashboard_data_column.value['uws.GWP_SUM']] +
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
          : (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans = 0);
    } else {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
              (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x.replace('uw_data', 'uws')]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
          : (ans =
              claimsType.value
                .map((x) => data_row[dashboard_data_column.value['claims_data.' + x + '_inc']])
                .reduce((ps: number, s: number) => ps + s, 0) /
              (data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
        : (ans = 0);
    }

    return numberWithRatios(ans, isValue);
  }

  function normalisedIncurred(data_row: any, isValue: boolean) {
    let ans = 0;
    if (dashboards.value.ccr_nlr == 'CCR') {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
                data_row[dashboard_data_column.value['uws.GWP_SUM']] +
              data_CommissionColumns.value
                .map((x) => data_row[dashboard_data_column.value[x]])
                .reduce((ps: number, s: number) => ps + s, 0) /
                data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
          : (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)) /
              data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])
        : (ans = 0);
    } else {
      data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] != 0
        ? underwriting_loss_ratios.value == 'Written' && dashboards.value.uw_acc == 'uw'
          ? (ans =
              (claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) +
                (claimsType.value
                  .map((x) => data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']])
                  .reduce((ps: number, s: number) => ps + s, 0) /
                  data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']]) *
                  (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                    data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']])) /
              (data_row[dashboard_data_column.value['uws.GWP_SUM']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x.replace('uw_data', 'uws')]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
          : (ans =
              claimsType.value
                .map((x) =>
                  normalise.value.includes(x)
                    ? data_row[dashboard_data_column.value['uw_data.' + x + '_MODEL']] *
                      data_row[dashboard_data_column.value['uw_data.' + x + '_seasonality']]
                    : data_row[dashboard_data_column.value['claims_data.' + x + '_inc']]
                )
                .reduce((ps: number, s: number) => ps + s, 0) /
              (data_row[dashboard_data_column.value['uw_data.GEP_AMOUNT']] -
                data_CommissionColumns.value
                  .map((x) => data_row[dashboard_data_column.value[x]])
                  .reduce((ps: number, s: number) => ps + s, 0)))
        : (ans = 0);
    }

    return numberWithRatios(ans, isValue);
  }
  function setChartData() {
    function targetData(temp: any) {
      if (
        portfolio_store.parameters &&
        portfolio_store.parameters['target'] &&
        portfolio_store.parameters['target']['target_value']
      ) {
        const paramTarget = portfolio_store.parameters['target'];
        if (dashboards.value.ccr_nlr == 'CCR') {
          temp['target'] = targetCCR(paramTarget['target_value'], paramTarget['target_commission']);
        }

        if (dashboards.value.ccr_nlr == 'NLR') {
          temp['target'] = targetNLR(paramTarget['target_value'], paramTarget['target_commission']);
        }
      }
    }

    setTimeout(() => {
      let obj: any = [];

      if (!portfolio_store.isAve) {
        for (let idx in dashboard_data.value) {
          let temp = {
            gwp: dashboard_data.value[idx][dashboard_data_column.value['uws.GWP_SUM']],
            gep: dashboard_data.value[idx][dashboard_data_column.value['uw_data.GEP_AMOUNT']],
            ccr: <any>graphCCRNLR(dashboard_data.value[idx], true) * 100,
            incurred: <any>graphIncurred(dashboard_data.value[idx], true) * 100,
            apriori: <any>graphApriori(dashboard_data.value[idx], true) * 100,
            date: dashboard_data.value[idx][dashboard_data_column.value['months.MONTH']],
          };
          targetData(temp);
          obj.push(temp);
        }
      } else {
        for (let idx in dashboard_ave_store.dashboard_data) {
          let temp = {
            gwp: dashboard_ave_store.current_dashboard_data[idx][dashboard_data_column.value['uws.GWP_SUM']],
            gep: dashboard_ave_store.current_dashboard_data[idx][dashboard_data_column.value['uw_data.GEP_AMOUNT']],
            ccr: <any>graphCCRNLR(dashboard_ave_store.current_dashboard_data[idx], true) * 100,
            incurred: <any>graphIncurred(dashboard_ave_store.current_dashboard_data[idx], true) * 100,
            apriori: <any>graphApriori(dashboard_ave_store.current_dashboard_data[idx], true) * 100,
            date: dashboard_ave_store.current_dashboard_data[idx][dashboard_data_column.value['months.MONTH']],
            comp_ccr: <any>dashboard_ave_store.normalisedCCRNLR(dashboard_ave_store.dashboard_data[idx], true) * 100,
            comp_incurred:
              <any>dashboard_ave_store.normalisedIncurred(dashboard_ave_store.dashboard_data[idx], true) * 100,
            comp_apriori: <any>graphApriori(dashboard_ave_store.dashboard_data[idx], true) * 100,
          };
          targetData(temp);
          obj.push(temp);
        }
      }
      chart_data.value = obj;
    });
  }

  function setFilters(new_filters: any) {
    filters.value = new_filters;
  }

  function onChoosePeriod(selection: string, i: string, current_mqy: string) {
    const mqy = dashboards.value['mqy'];

    let dates = [];

    let format = 'MMM-YYYY';
    if (current_mqy == 'quarter') {
      format = '[Q]Q-YYYY';
    } else if (current_mqy == 'year') {
      format = 'YYYY';
    }

    const selection_converted: string = moment(selection, 'MMM-YYYY').format(format);

    const array_of_dates = portfolio_store.all_uw_dates['month'].filter((e: string) => {
      return moment(e, 'MMM-YYYY').format(format) == selection_converted;
    });

    if (
      dateFilters.value[i]['month'].reduce((t: any, x: any) => t + (x == 0 ? 0 : 1), 0) ==
      portfolio_store.all_uw_dates['month'].length
    ) {
      dateFilters.value[i]['month'] = dateFilters.value[i]['month'].map((x: any) =>
        array_of_dates.includes(x) ? x : 0
      );
    } else if (dateFilters.value[i]['month'].every((e: any) => !array_of_dates.includes(e))) {
      for (const date of array_of_dates) {
        dateFilters.value[i]['month'][portfolio_store.all_uw_dates['month'].indexOf(date)] = date;
      }
    } else {
      for (const date of array_of_dates) {
        dateFilters.value[i]['month'][portfolio_store.all_uw_dates['month'].indexOf(date)] = 0;
      }
    }

    // if (
    //   dateFilters.value[i][mqy].reduce(
    //     (t: any, x: any) => t + (x == 0 ? 0 : 1),
    //     0
    //   ) == portfolio_store.all_uw_dates[mqy].length
    // ) {
    //   dateFilters.value[i][mqy] = dateFilters.value[i][mqy].map((x: any) =>
    //     x == e.selection ? x : 0
    //   );
    // } else if (dateFilters.value[i][mqy].includes(e.selection)) {
    //   dateFilters.value[i][mqy][dateFilters.value[i][mqy].indexOf(e.selection)] = 0;
    // } else {
    //   dateFilters.value[i][mqy][
    //     portfolio_store.all_uw_dates[mqy].indexOf(e.selection)
    //   ] = e.selection;
    // }

    // if (
    //   dateFilters.value["underwriting"][mqy].reduce(
    //     (t: any, x: any) => t + (x == 0 ? 0 : 1),
    //     0
    //   ) == 0
    // ) {
    //   dateFilters.value["underwriting"][mqy] = JSON.parse(
    //     JSON.stringify(portfolio_store.all_uw_dates[mqy])
    //   );
    // }

    loadDashboard();
  }

  function calculateTotal(data: any, date: string, month: string) {
    let totals = new Array(Object.keys(dashboard_data_column.value).length).fill(0);

    const exposureIndex = {};

    // Do not sum exposure values, find the column index and not sum them
    for (let i = 0; i < portfolio_store.getExposureLength(); i++) {
      const exposure = portfolio_store.parameters['exposure'][i];
      const columnName = 'uw_data.exposure.' + exposure['method'] + '.' + exposure['name'];
      exposureIndex[dashboard_data_column.value[columnName]] = i;
    }

    totals[0] = date;
    totals[1] = month;

    const averageColumns = Object.keys(dashboard_data_column.value).filter((x) => x.includes('uw_data.exposure.avg'));

    let numberOfRows = 0;
    data.forEach((row) => {
      numberOfRows++;
      row.forEach((value, columnIndex) => {
        if (columnIndex in exposureIndex) {
          if (portfolio_store.parameters['exposure'][exposureIndex[columnIndex]]['method'] == 'sum') {
            if (value > 0) {
              totals[columnIndex] = (totals[columnIndex] || 0) + value;
            }
          } else if (portfolio_store.parameters['exposure'][exposureIndex[columnIndex]]['method'] == 'min') {
            if (value > 0) {
              totals[columnIndex] = Math.min(totals[columnIndex] || Number.POSITIVE_INFINITY, value);
            }
          } else if (portfolio_store.parameters['exposure'][exposureIndex[columnIndex]]['method'] == 'max') {
            if (value > 0) {
              totals[columnIndex] = Math.max(totals[columnIndex] || Number.NEGATIVE_INFINITY, value);
            }
          } else if (portfolio_store.parameters['exposure'][exposureIndex[columnIndex]]['method'] == 'avg') {
            totals[columnIndex] = (totals[columnIndex] || 0) + value;
          }
        } else if (typeof value === 'number') {
          totals[columnIndex] = (totals[columnIndex] || 0) + value;
        }
      });
    });

    // for (let i in averageColumns) {
    //   const columnName = averageColumns[i].split('.');
    //   console.log(columnName);
    //   console.log(totals);
    //   // uw_data.exposure.sum.count.Turnover Band
    //   totals[dashboard_data_column.value[averageColumns[i]]] =
    //     totals[dashboard_data_column.value[averageColumns[i]]] /
    //     totals[dashboard_data_column.value['uw_data.exposure.sum.count.' + columnName[3]]];
    // }

    let seasonalityColumns = Object.keys(dashboard_data_column.value).filter((x) => x.includes('_seasonality'));
    for (let i in seasonalityColumns) {
      totals[dashboard_data_column.value[seasonalityColumns[i]]] =
        totals[dashboard_data_column.value[seasonalityColumns[i]]] / numberOfRows;
    }
    return totals;
  }

  function getBinders(datesArray: any) {
    let dateGrouping: any = {};

    let index = 0;

    for (const i in portfolio_store.parameters.binding_years) {
      const data = portfolio_store.parameters.binding_years[i];
      if (parseInt(i) == 0) {
        if (data.type == 'exceptional_period') {
          /**
           * Get all the previous dates and group in accordingly (Exceptional Period)
           */
          while (index < portfolio_store.all_uw_dates['month'].length) {
            if (
              moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY').isBefore(
                moment(data.range[0], 'MMM-YYYY')
              )
            ) {
              const dateToBeAdded = moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY').format('YYYY');
              if (!dateGrouping[dateToBeAdded]) {
                dateGrouping[dateToBeAdded] = [];
              }

              dateGrouping[dateToBeAdded].push(portfolio_store.all_uw_dates['month'][index]);
              index += 1;
            } else {
              break;
            }
          }
        } else {
          /**
           * Get all the previous dates and group in accordingly (Normal Period)
           */
          while (index < portfolio_store.all_uw_dates['month'].length) {
            if (moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY').format('MMM') != data.range[0]) {
              const dateToBeAdded = moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY')
                .subtract(1, 'year')
                .format('YYYY');
              if (!dateGrouping[dateToBeAdded]) {
                dateGrouping[dateToBeAdded] = [];
              }

              dateGrouping[dateToBeAdded].push(portfolio_store.all_uw_dates['month'][index]);
              index += 1;
            } else {
              break;
            }
          }
        }
      }

      /**
       * After first index is done, time to group parameters together
       */

      if (data.type == 'exceptional_period') {
        while (index < portfolio_store.all_uw_dates['month'].length) {
          if (
            moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY').isBetween(
              moment(data.range[0], 'MMM-YYYY'),
              moment(data.range[1], 'MMM-YYYY'),
              undefined,
              '[]'
            )
          ) {
            const dateToBeAdded = data.group;
            if (!dateGrouping[dateToBeAdded]) {
              dateGrouping[dateToBeAdded] = [];
            }

            dateGrouping[dateToBeAdded].push(portfolio_store.all_uw_dates['month'][index]);
            index += 1;
          } else {
            break;
          }
        }
      } else {
        let currYear = moment(portfolio_store.all_uw_dates['month'][index], 'YYYY').format('YYYY');

        while (
          index < portfolio_store.all_uw_dates['month'].length &&
          (parseInt(i) == portfolio_store.parameters.binding_years.length - 1 ||
            (parseInt(i) != portfolio_store.parameters.binding_years.length - 1 &&
              moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY').isBefore(
                moment(portfolio_store.parameters.binding_years[parseInt(i) + 1].range[0], 'MMM-YYYY')
              )))
        ) {
          let currMonth = moment(portfolio_store.all_uw_dates['month'][index], 'MMM').format('MMM');

          if (!dateGrouping[currYear]) {
            dateGrouping[currYear] = [];
          }

          dateGrouping[currYear].push(portfolio_store.all_uw_dates['month'][index]);

          if (currMonth == data['range'][1]) {
            currYear = moment(currYear, 'YYYY').add(1, 'year').format('YYYY');
          }

          index += 1;
        }
      }
    }

    if (Object.keys(dateGrouping).length !== 0) {
      const resultArray = datesArray.map((date) => {
        for (const binder in dateGrouping) {
          if (dateGrouping[binder].includes(date)) {
            return binder;
          }
        }
        return null; // Return null or any default value if date not found
      });
      return resultArray;
    }
    return [];
  }

  function convertToPeriod(period, data) {
    let format = 'YYYY';
    if (period == 'quarter') {
      format = '[Q]Q-YYYY';
    }

    let periods: string[] = [];

    if (period === 'binder') {
      periods = getBinders(data.map((x) => x[0]));
      if (periods.length === 0) {
        for (const i in data) {
          periods.push(moment(data[i][dashboard_data_column.value['months.MONTH']], 'MMM-YYYY').format(format));
        }
      }
    } else {
      for (const i in data) {
        periods.push(moment(data[i][dashboard_data_column.value['months.MONTH']], 'MMM-YYYY').format(format));
      }
    }

    let newData: any = {};

    const sections = [];
    let start = 0;

    for (let i = 1; i <= periods.length; i++) {
      if (i === periods.length || periods[i] !== periods[i - 1]) {
        sections.push({ value: periods[start], start, end: i - 1 });
        start = i;
      }
    }

    sections.forEach((p, i) => {
      const startIndex = p['start'];
      const endIndex = p['end'] + 1;
      let newRowData = data.slice(startIndex, endIndex);
      newData[endIndex - 1] = calculateTotal(newRowData, p['value'], data[endIndex - 1][0]);
    });

    return newData;
  }

  function transformDataToDate() {
    let sliced_dashboard_data = monthly_dashboard_data.value;

    yearly_dashboard_data.value = convertToPeriod('year', sliced_dashboard_data);
    binder_dashboard_data.value = convertToPeriod('binder', sliced_dashboard_data);
    quarterly_dashboard_data.value = convertToPeriod('quarter', sliced_dashboard_data);
    totalData.value = calculateTotal(sliced_dashboard_data, 'Total', 'Total');

    dashboard_data.value = sliced_dashboard_data;
    if (dashboards.value.mqy == 'quarter') {
      dashboard_data.value = quarterly_dashboard_data.value;
    } else if (dashboards.value.mqy == 'year') {
      if (isBindedYears.value) {
        dashboard_data.value = binder_dashboard_data.value;
      } else {
        dashboard_data.value = yearly_dashboard_data.value;
      }
    }
  }

  function onChooseHierarchy(i: any, j: any) {
    const e = { hierarchy: i, name: j };
    if (selectedData.value[e.hierarchy].length == filters.value[e.hierarchy].length) {
      selectedData.value[e.hierarchy] = [e.name];
    } else if (selectedData.value[e.hierarchy].includes(e.name)) {
      selectedData.value[e.hierarchy].splice(selectedData.value[e.hierarchy].indexOf(e.name), 1);
    } else {
      selectedData.value[e.hierarchy].push(e.name);
    }
    if (selectedData.value[e.hierarchy].length == 0) {
      for (let i in filters.value[e.hierarchy]) {
        selectedData.value[e.hierarchy].push(structuredClone(filters.value[e.hierarchy][i]));
      }
    }
    loadDashboard();
  }

  function setTreeFilters(s: any, uws: any, accs: any) {
    selectedData.value = s;

    dateFilters.value['underwriting']['month'] = uws;
    dateFilters.value['accident']['month'] = accs;
    loadDashboard();
  }

  function resetFilters() {
    selectedData.value = JSON.parse(JSON.stringify(filters.value));
    dataFilters.value = filters.value;
    dateFilters.value['underwriting'] = JSON.parse(JSON.stringify(portfolio_store.all_uw_dates));
    dateFilters.value['accident'] = JSON.parse(JSON.stringify(portfolio_store.all_uw_dates));
  }

  function change_mqy() {
    if (dashboards.value.mqy == 'month') {
      dashboards.value.mqy = 'year';
    } else if (dashboards.value.mqy == 'quarter') {
      dashboards.value.mqy = 'month';
    } else {
      dashboards.value.mqy = 'quarter';
    }

    isBindedYears.value = false;

    transformDataToDate();
    if (portfolio_store.isAve) {
      dashboard_ave_store.transformDataToDate();
    }
  }

  function openErrorMsg(msg: string) {
    ElMessage.warning(msg);
  }

  function onSetConfigBindedYearsModal(val: boolean) {
    isConfigBindedYearsModal.value = val;
  }

  function toggleYearSubTotal() {
    isYearSubTotal.value = !isYearSubTotal.value;
  }

  function toggleYearSubTotalUp() {
    isYearSubTotalUp.value = !isYearSubTotalUp.value;
  }

  function toggleQuarterSubTotal() {
    isQuarterSubTotal.value = !isQuarterSubTotal.value;
  }

  function toggleQuarterSubTotalUp() {
    isQuarterSubTotalUp.value = !isQuarterSubTotalUp.value;
  }

  function toggleBinderSubTotal() {
    isBinderSubTotal.value = !isBinderSubTotal.value;
  }

  function toggleBinderSubTotalUp() {
    isBinderSubTotalUp.value = !isBinderSubTotalUp.value;
  }

  async function switch_gwpnwp_amount() {
    if (dashboards.value.gwpnwp == 'GWP') {
      dashboards.value.gwpnwp = 'NWP';
    } else {
      dashboards.value.gwpnwp = 'GWP';
    }
  }

  async function switch_ratio_amount() {
    if (dashboards.value.ratio_amount == 'amount') {
      dashboards.value.ratio_amount = 'ratios';
    } else {
      dashboards.value.ratio_amount = 'amount';
    }
  }

  function transformDataToBindingYears() {
    let dateGrouping: any = {};

    let index = 0;

    for (const i in portfolio_store.parameters.binding_years) {
      const data = portfolio_store.parameters.binding_years[i];
      if (parseInt(i) == 0) {
        if (data.type == 'exceptional_period') {
          /**
           * Get all the previous dates and group in accordingly (Exceptional Period)
           */
          while (index < portfolio_store.all_uw_dates['month'].length) {
            if (
              moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY').isBefore(
                moment(data.range[0], 'MMM-YYYY')
              )
            ) {
              const dateToBeAdded = moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY').format('YYYY');
              if (!dateGrouping[dateToBeAdded]) {
                dateGrouping[dateToBeAdded] = [];
              }

              dateGrouping[dateToBeAdded].push(portfolio_store.all_uw_dates['month'][index]);
              index += 1;
            } else {
              break;
            }
          }
        } else {
          /**
           * Get all the previous dates and group in accordingly (Normal Period)
           */
          while (index < portfolio_store.all_uw_dates['month'].length) {
            if (moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY').format('MMM') != data.range[0]) {
              const dateToBeAdded = moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY')
                .subtract(1, 'year')
                .format('YYYY');
              if (!dateGrouping[dateToBeAdded]) {
                dateGrouping[dateToBeAdded] = [];
              }

              dateGrouping[dateToBeAdded].push(portfolio_store.all_uw_dates['month'][index]);
              index += 1;
            } else {
              break;
            }
          }
        }
      }

      /**
       * After first index is done, time to group parameters together
       */

      if (data.type == 'exceptional_period') {
        while (index < portfolio_store.all_uw_dates['month'].length) {
          if (
            moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY').isBetween(
              moment(data.range[0], 'MMM-YYYY'),
              moment(data.range[1], 'MMM-YYYY'),
              undefined,
              '[]'
            )
          ) {
            const dateToBeAdded = data.group;
            if (!dateGrouping[dateToBeAdded]) {
              dateGrouping[dateToBeAdded] = [];
            }

            dateGrouping[dateToBeAdded].push(portfolio_store.all_uw_dates['month'][index]);
            index += 1;
          } else {
            break;
          }
        }
      } else {
        let currYear = moment(portfolio_store.all_uw_dates['month'][index], 'YYYY').format('YYYY');

        while (
          index < portfolio_store.all_uw_dates['month'].length &&
          (parseInt(i) == portfolio_store.parameters.binding_years.length - 1 ||
            (parseInt(i) != portfolio_store.parameters.binding_years.length - 1 &&
              moment(portfolio_store.all_uw_dates['month'][index], 'MMM-YYYY').isBefore(
                moment(portfolio_store.parameters.binding_years[parseInt(i) + 1].range[0], 'MMM-YYYY')
              )))
        ) {
          let currMonth = moment(portfolio_store.all_uw_dates['month'][index], 'MMM').format('MMM');

          if (!dateGrouping[currYear]) {
            dateGrouping[currYear] = [];
          }

          dateGrouping[currYear].push(portfolio_store.all_uw_dates['month'][index]);

          if (currMonth == data['range'][1]) {
            currYear = moment(currYear, 'YYYY').add(1, 'year').format('YYYY');
          }

          index += 1;
        }
      }
    }

    // GROUP DATE INTO DATA
    let reverse_key = {};
    for (const group of Object.keys(dateGrouping)) {
      for (const date of dateGrouping[group]) {
        reverse_key[date] = group;
      }
    }

    let newDate: string[] = [];
    let newData: any = {};

    for (const i in original_monthly_data.value.gwp_dashboard) {
      const d = original_monthly_data.value.incurred_data_dashboard[i];
      const gwp = original_monthly_data.value.gwp_dashboard[i][0];

      const quarterDate = reverse_key[moment(d[0], 'YYYY/MM/DD').format('MMM-YYYY')];

      if (!newData[quarterDate]) {
        newDate.push(quarterDate);
        newData[quarterDate] = new Array(Object.keys(incurred_data_dashboard_column.value).length).fill(0);
      }
      newData[quarterDate][0] += gwp;

      for (let i = 1; i < incurred_data_dashboard_column.value['TOTAL_COMM']; i++) {
        newData[quarterDate][i] += d[i];
      }

      for (
        let i = incurred_data_dashboard_column.value['TOTAL_COMM'];
        i <= incurred_data_dashboard_column.value['Attritional_LR_TOTAL'];
        i++
      ) {
        newData[quarterDate][i] += d[i] * d[1];
      }
    }

    for (const date of newDate) {
      for (
        let i = incurred_data_dashboard_column.value['TOTAL_COMM'];
        i <= incurred_data_dashboard_column.value['Attritional_LR_TOTAL'];
        i++
      ) {
        newData[date][i] /= newData[date][1] == 0 ? 0 : newData[date][1];
      }
    }

    // transformToOriginalFormat(newDate, newData);
  }

  async function change_uw_acc() {
    if (isBindedYears.value) {
      isBindedYears.value = false;
      dashboards.value.uw_acc = 'acc';
      amtFunc.value = ['Paid', 'OS', 'Incurred', 'IBNR', 'Ultimate'];
      amtLabel.value = ['Paid', 'O/S', 'Incurred', 'IBNR', 'Ultimate'];
      await loadDashboard();
    } else if (dashboards.value.uw_acc == 'uw' && dashboards.value.mqy == 'year') {
      isBindedYears.value = true;
      if (underwriting_loss_ratios.value == 'Written') {
        amtFunc.value = ['Paid', 'OS', 'Incurred', 'IBNR', 'Unearned', 'Ultimate'];
        amtLabel.value = ['Paid', 'O/S', 'Incurred', 'IBNR', 'Unearned Expected Loss', 'Ultimate'];
      }
      await loadDashboard();
    } else if (dashboards.value.uw_acc == 'acc') {
      isBindedYears.value = false;
      dashboards.value.uw_acc = 'uw';

      if (underwriting_loss_ratios.value == 'Written') {
        amtFunc.value = ['Paid', 'OS', 'Incurred', 'IBNR', 'Unearned', 'Ultimate'];
        amtLabel.value = ['Paid', 'O/S', 'Incurred', 'IBNR', 'Unearned Expected Loss', 'Ultimate'];
      }

      await loadDashboard();
    } else {
      isBindedYears.value = false;
      dashboards.value.uw_acc = 'acc';
      amtFunc.value = ['Paid', 'OS', 'Incurred', 'IBNR', 'Ultimate'];
      amtLabel.value = ['Paid', 'O/S', 'Incurred', 'IBNR', 'Ultimate'];
      await loadDashboard();
    }
  }

  async function changeccrnlr() {
    if (dashboards.value.ccr_nlr == 'CCR') {
      dashboards.value.ccr_nlr = 'NLR';
    } else {
      dashboards.value.ccr_nlr = 'CCR';
    }
  }

  function set_filter() {
    let temp_hierarchy: any = {};

    for (const i_key of Object.keys(filters.value)) {
      let temp_arr: any = [];
      for (const value of selectedData.value[i_key]) {
        temp_arr.push(parseInt(getKeyByValue(portfolio_store.dictionary[i_key], value)));
      }

      if (temp_arr.length == 0) {
        temp_arr.push(9999999999);
      }

      temp_hierarchy[i_key] = temp_arr;
    }

    return temp_hierarchy;
  }

  function getKeyByValue(object: any, value: any): string {
    return Object.keys(object).find((key) => object[key] === value) || '';
  }

  async function claimsOthersInformationAPI() {
    const filters_hierarchy = set_filter();
    global_store.setLoading(true);

    const [underwriting_filters, accident_filters] = convertFiltersToApiFormat();

    await api
      .post(import.meta.env.VITE_API_URL + 'claims/claims-information-others', {
        bounce_id: portfolio_store.selectedBounceID,
        accident_underwriting: { uw: 1, acc: 0 }[dashboards.value.uw_acc],
        filters_hierarchy: filters_hierarchy,
        normalised_CCR: normalised_for_api.value,
        report_date: report_date.value,
        selected_row_date: moment(selectedDateClaimsOthersInformation.value, 'MMM-YYYY').format('YYYY/MM/DD'),
        date_unit: dateUnit.value,
        per_page: 10,
        page: claimsOthersModalPage.value,
        selected_type: claimsSelectedType.value,
        sorting: otherInformationSort.value,
        accident_month_filter: accident_filters,
        underwriting_month_filter: underwriting_filters,
        selectedLineSize: portfolio_store.selectedLineSize,
      })
      .then((res) => {
        claimsOthersModalData.value = res.data.data;

        global_store.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        global_store.setLoading(false);
        throw err;
      });
  }

  async function claimsOthersInformation(selectedDate: string, type: string, date_unit: string) {
    selectedDateClaimsOthersInformation.value = selectedDate;
    claimsSelectedType.value = type;
    dateUnit.value = date_unit;
    claimsOthersInformationAPI();
  }

  async function claimsAttritionalInformation(selectedDate: string, date_unit: string, type: string) {
    selectedDateClaimsOthersInformation.value = selectedDate;
    claimsSelectedType.value = type;
    dateUnit.value = date_unit;
    const filters_hierarchy = set_filter();
    global_store.setLoading(true);
    const [underwriting_filters, accident_filters] = convertFiltersToApiFormat();
    await api
      .post(import.meta.env.VITE_API_URL + 'claims/claims-information-attritional', {
        bounce_id: portfolio_store.selectedBounceID,
        accident_underwriting: { uw: 1, acc: 0 }[dashboards.value.uw_acc],
        filters_hierarchy: filters_hierarchy,
        normalised_CCR: normalised_for_api.value,
        report_date: report_date.value,
        selected_row_date: selectedDate == '' ? '' : moment(selectedDate, 'MMM-YYYY').format('YYYY/MM/DD'),
        date_unit: date_unit,
        selected_type: claimsSelectedType.value,
        accident_month_filter: accident_filters,
        underwriting_month_filter: underwriting_filters,
        selectedLineSize: portfolio_store.selectedLineSize,
      })
      .then((res) => {
        claimsAttritionalModalData.value = res.data.data;
        isClaimsAttritionalInformationModal.value = true;
        global_store.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        global_store.setLoading(false);
        throw err;
      });
  }

  function convertFiltersToApiFormat() {
    let underwriting_filters: any = [];
    let accident_filters: any = [];

    if (
      dateFilters.value['underwriting']['month'].reduce((t: any, x: any) => t + (x == 0 ? 0 : 1), 0) !=
      portfolio_store.all_uw_dates['month'].length
    ) {
      underwriting_filters = dateFilters.value['underwriting']['month'].filter((e) => e != 0);
      underwriting_filters = underwriting_filters.map((e) => moment(e, 'MMM-YYYY').format('YYYY/MM/DD'));
    }

    if (
      dateFilters.value['accident']['month'].reduce((t: any, x: any) => t + (x == 0 ? 0 : 1), 0) !=
      portfolio_store.all_uw_dates['month'].length
    ) {
      accident_filters = dateFilters.value['accident']['month'].filter((e) => e != 0);
      accident_filters = accident_filters.map((e) => moment(e, 'MMM-YYYY').format('YYYY/MM/DD'));
    }

    return [underwriting_filters, accident_filters];
  }

  async function loadDashboard() {
    const filters_hierarchy = set_filter();

    global_store.setLoading(true);

    const [underwriting_filters, accident_filters] = convertFiltersToApiFormat();

    await api
      .post(import.meta.env.VITE_API_URL + 'claims/claims-dashboard-table', {
        bounce_id: portfolio_store.selectedBounceID,
        accident_underwriting: { uw: 1, acc: 0 }[dashboards.value.uw_acc],
        accident_month_filter: accident_filters,
        include_projections: include_projections.value,
        underwriting_month_filter: underwriting_filters,
        filters_hierarchy: filters_hierarchy,
        normalised_CCR: normalised_for_api.value,
        report_date: report_date.value,
        years_of_projections: portfolio_store.isAve ? 0 : yearsOfProjections.value,
        claim_category: 0,
        selectedLineSize: portfolio_store.selectedLineSize,
      })
      .then(async (res) => {
        monthly_dashboard_data.value = res.data.data[0].data;
        dashboard_data_column.value = res.data.data[0].column;
        let p_data = JSON.parse(res.data.data[1]);
        seasonality_parameters.value = p_data.SEASONALITY.map((x) => x.split(';').map(Number));
        large_threshold.value = p_data.LARGE_THRESHOLD;
        large_method.value = p_data.LARGE_METHOD;
        UWCommissionColumns.value = Object.keys(dashboard_data_column.value).filter((key) => key.includes('uws.COM'));
        data_CommissionColumns.value = Object.keys(dashboard_data_column.value).filter((key) =>
          key.includes('uw_data.COM')
        );

        unDataFilters.value = res.data.data[0].unfilter;
        unDateFilters.value = res.data.data[0].date_unfilter;
        transformDataToDate();
        // gwp_data.value = res.data.data['gwp']['data']
        // incurred_data.value = res.data.data['incurred_data']['data']

        if (portfolio_store.isAve) {
          await dashboard_ave_store.loadDashboard();
        }

        forwardLookingWithFilter(filters_hierarchy);

        global_store.setLoading(false);
        isLoaded.value = true;
      })
      .catch((err) => {
        console.log(err);
        global_store.setLoading(false);
        throw err;
      });

    global_store.setLoading(false);
  }

  async function forwardLookingWithFilter(filtersHierarchy) {
    await api
      .post(import.meta.env.VITE_API_URL + 'underwriting/forwardlooking', {
        bounce_id: portfolio_store.selectedBounceID,
        report_date: report_date.value,
        parameters_json: portfolio_store.parameters,
        filters_hierarchy: filtersHierarchy,
      })
      .then((res) => {
        dashboardForwardLookings.value = res.data.data;
      })
      .catch((err) => {
        console.log(err);
        global_store.setLoading(false);
        throw err;
      });
  }

  async function loadTierData() {
    await api
      .get(import.meta.env.VITE_API_URL + 'claims/tiering', {
        params: {
          bounce_id: portfolio_store.selectedBounceID,
        },
      })
      .then((res) => {
        // chunkGroups.value = res.data.data;
        tierData.value = res.data.data.data;
      })
      .catch(() => openErrorMsg('Error, please try again!'));
  }

  function onSetClaimsAttritionalInformationModal(val: boolean) {
    isClaimsAttritionalInformationModal.value = val;
  }

  function modifyOtherInformationSort(val: OtherInformationSortType) {
    otherInformationSort.value = val;
    claimsOthersInformationAPI();
  }

  function onSetClaimsOthersInformationModal(val: boolean) {
    isClaimsOthersInformationModal.value = val;
    claimsOthersModalPage.value = 1;
    otherInformationSort.value = {
      column: 'POLICY_NO',
      order: 'ASC',
    };
  }

  function updateClaimsOthersModalPage(page: Number) {
    claimsOthersModalPage.value = page;
    claimsOthersInformationAPI();
  }

  function setFiltersExpandedKeys(value: any) {
    filtersExpandedKeys.value = value;
  }

  function setGraphSelectionRadio(value: any) {
    graphSelectionRadio.value = value;
  }

  function setComparisonViewChartData(data: any) {
    comparisonViewChartData.value = data;
  }

  function setPerdormanceViewScroll(value: number) {
    perdormanceViewScroll.value = value;
  }

  function setComparisonViewScroll(value: number) {
    comparisonViewScroll.value = value;
  }

  function setFiltersTracker(value: any) {
    filtersTracker.value = value;
  }

  function set_comparison_table_scroll_position(value: number) {
    comparison_table_scroll_position.value = value;
  }

  function setComp2(value: boolean) {
    comp2.value = value;
  }

  function setPartitionsHierarchies(value) {
    partitions_hierarchies.value = value;
  }

  function setPartitionsHierarchiesBool(value) {
    partitions_hierarchies_bool.value = value;
  }

  function setPartitionsHierarchyRadio(value) {
    partitions_hierarchy_radio.value = value;
  }

  function setYearsOfProjections(value: number) {
    yearsOfProjections.value = value;
    loadDashboard();
  }
  return {
    isConfigBindedYearsModal,
    onSetConfigBindedYearsModal,
    isBindedYears,
    underwritingLossRatiosChange,
    underwriting_loss_ratios,
    modifyOtherInformationSort,
    otherInformationSort,
    updateClaimsOthersModalPage,
    claimsOthersModalPage,
    claimsOthersModalData,
    isClaimsOthersInformationModal,
    onSetClaimsOthersInformationModal,
    claimsOthersInformation,
    onSetClaimsAttritionalInformationModal,
    claimsAttritionalModalData,
    isClaimsAttritionalInformationModal,
    claimsAttritionalInformation,
    tierData,
    loadTierData,
    normalised_for_api,
    chunk_data,
    comparisonViewColumns,
    dashboards,
    selectedData,
    dataFilters,
    chunk_tables,
    chunks_grouping,
    chunks_grouping_basis,
    dateFilters,
    get_chunk,
    resetFilters,
    onChoosePeriod,
    onChooseHierarchy,
    setFilters,
    filters,
    graphConfig,
    chart_data,
    setChartData,
    change_mqy,
    change_uw_acc,
    switch_gwpnwp_amount,
    loadDashboard,
    switch_ratio_amount,
    changeccrnlr,
    gwp_dashboard,
    incurred_data_dashboard,
    incurred_data_dashboard_column,
    gwp_dashboard_column,
    total_incurred,
    total_gwp,
    dashboard_data,
    dashboard_data_column,
    monthly_dashboard_data,
    yearly_dashboard_data,
    binder_dashboard_data,
    quarterly_dashboard_data,
    totalData,
    UWCommissionColumns,
    data_CommissionColumns,
    toggleYearSubTotal,
    toggleYearSubTotalUp,
    toggleQuarterSubTotal,
    toggleQuarterSubTotalUp,
    toggleBinderSubTotal,
    toggleBinderSubTotalUp,
    isQuarterSubTotal,
    isQuarterSubTotalUp,
    isBinderSubTotal,
    isBinderSubTotalUp,
    isYearSubTotal,
    isYearSubTotalUp,
    unDataFilters,
    unDateFilters,
    setTreeFilters,
    updateTreeSelectionKeys,
    treeSelectionKeys,
    report_date,
    claimsSelectedType,
    amtFunc,
    amtLabel,
    selectedChunkGroup,
    setSelectedChunkGroup,
    selectedTierGroup,
    setSelectedTierGroup,
    calculateCCRNLR,
    seasAdjApriori,
    normalisedCCRNLR,
    numberWithRatios,
    filtersExpandedKeys,
    graphSelectionRadio,
    setGraphSelectionRadio,
    comparisonViewColumnsTree,
    getComparisonViewColumnsTree,
    getComparisonViewColumns,
    setComparisonViewColumns,
    graphSelectionIDIncrement,
    graphSelectionID,
    changeSeas,
    seasonality_parameters,
    large_method,
    large_threshold,
    comparisonViewChartData,
    setComparisonViewChartData,
    minMaxXY,
    setMinMaxXY,
    perdormanceViewScroll,
    setPerdormanceViewScroll,
    comparisonViewScroll,
    setComparisonViewScroll,
    filtersTracker,
    setFiltersTracker,
    comparison_table_scroll_position,
    set_comparison_table_scroll_position,
    set_filter,
    convertFiltersToApiFormat,
    convertToPeriod,
    calculateTotal,
    transformDataToDate,
    normalise,
    selectedDateClaimsOthersInformation,
    dateUnit,
    DASHBOARD_COLUMNS_SELECTION,
    visibleColumns,
    offMarginGWPGEP,
    offMarginAprioriCCR,
    DEFAULT_DASHBOARD_COLULMNS_SELECTION,
    include_projections,
    comp2,
    setComp2,
    partitions_hierarchies,
    partitions_hierarchies_bool,
    setPartitionsHierarchies,
    setPartitionsHierarchiesBool,
    setPartitionsHierarchyRadio,
    partitions_hierarchy_radio,
    partition_chart,
    comparisonViewPartitionData,
    getChunk,
    yearsOfProjections,
    setYearsOfProjections,
    transitionRenderKey,
    isShowingExposure,
    dashboardForwardLookings,
    riskProfileFilter,
    riskProfileFilterData,
    riskProfileNumberOfTables,
    riskProfileDateSelection,
    riskProfileCustomBandsFilter,
    selectedRisk,
    fetchAllRiskProfileTables,
    riskProfileFilterSelection,
    fetchRiskProfileTableForCustomBands,
    fetchAllCustomBands,
    riskProfileFilterRenderKey,
    isCreateNewBandsOnDashboardModal,
    fetchEPIFilters,
    epiFilters,
    epiFiltersHierarchy,
    epiFiltersExpand,
    epiFiltersSelected,
  };
});

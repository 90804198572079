<script setup lang="ts">
import DialogComponent from '@/components/Dialog/DialogComponent.vue';
import { useDashboardStore } from '@/stores/dashboard';
import { usePortfolioStore } from '@/stores/portfolio';
import { onMounted, ref, watch } from 'vue';

const dashboardStore = useDashboardStore();
const portfolioStore = usePortfolioStore();

const bands = ref<{ band: string; total: boolean; average: boolean; min: boolean; max: boolean }[]>([]);

watch(
  () => dashboardStore.isCreateNewBandsOnDashboardModal,
  (value) => {
    if (value) {
      createDefault();
    }
  }
);

onMounted(() => {
  createDefault();
});

function createDefault() {
  const slicedExposure = portfolioStore.parameters['exposure_order'].slice(1);

  for (const band of slicedExposure) {
    bands.value.push({
      band: band,
      total: false,
      average: false,
      min: false,
      max: false,
    });
  }

  for (const ex of portfolioStore.parameters['exposure'].slice(1)) {
    if (ex.method === 'sum') {
      bands.value[slicedExposure.findIndex((x) => x === ex.name)].total = true;
    }
    if (ex.method === 'avg') {
      bands.value[slicedExposure.findIndex((x) => x === ex.name)].average = true;
    }
    if (ex.method === 'min') {
      bands.value[slicedExposure.findIndex((x) => x === ex.name)].min = true;
    }
    if (ex.method === 'max') {
      bands.value[slicedExposure.findIndex((x) => x === ex.name)].max = true;
    }
  }
}

async function onSelectSave() {
  let oldParameters = structuredClone(portfolioStore.parameters);

  const exposure = [{ method: 'sum', name: 'Policy Count' }];
  for (const bandIdx in portfolioStore.parameters['exposure_order'].slice(1)) {
    const bandObj = bands.value[bandIdx];
    if (bandObj.total) {
      exposure.push({ method: 'sum', name: bandObj.band });
    }
    if (bandObj.average) {
      exposure.push({ method: 'avg', name: bandObj.band });
    }
    if (bandObj.min) {
      exposure.push({ method: 'min', name: bandObj.band });
    }
    if (bandObj.max) {
      exposure.push({ method: 'max', name: bandObj.band });
    }
  }

  portfolioStore.parameters['exposure'] = exposure;

  await portfolioStore.saveParameters(oldParameters, 'Edited Dashboard Bands');
  await dashboardStore.loadDashboard();
}
</script>
<template>
  <DialogComponent v-model="dashboardStore.isCreateNewBandsOnDashboardModal" title="Add New Bands" width="500px">
    <div class="grid grid-cols-5 place-items-center gap-5">
      <span>Measure</span>
      <span>Total</span>
      <span>Average</span>
      <span>Min.</span>
      <span>Max.</span>
      <template v-for="(band, idx) in portfolioStore.parameters['exposure_order'].slice(1)" :key="band">
        <span>{{ band }}</span>
        <el-checkbox v-model="bands[idx].total" />
        <el-checkbox v-model="bands[idx].average" />
        <el-checkbox v-model="bands[idx].min" />
        <el-checkbox v-model="bands[idx].max" style="margin-right: 30px" />
      </template>
    </div>
    <div class="mt-12"></div>
    <el-button type="primary" class="w-14 absolute right-6 bottom-4" @click="onSelectSave">Save</el-button>
  </DialogComponent>
</template>
<style scoped>
.td {
  padding: 10px;
}
</style>
